import React, { useEffect, useState, useRef } from 'react'
import { Outlet, Link } from "react-router-dom";
import TopHeader from './TopHeader';
import Footer from './Footer';
import { MenuContext, TitleContext, PathContext } from "../Context";
import { useContext } from "react";
import PreviousNext from './PreviousNext';
import ExcerciseMenu from './ExcerciseMenu';

const Excercise = () => {
    const addspaceElement = useRef();
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const [score, setScore] = useState(0);
    const [showScore, setShowScore] = useState(false);
    const pathName = useContext(PathContext);
    const pathname = pathName.pathName;
    const menuElement = useRef([]);
    const menu = useContext(MenuContext);
    const [questions, setQuestion] = useState([]);
    const subject = window.location.pathname.split('/')[2];
    const subjectName = subject.split("-") // Split string into parts: ["node", "js"]
        .map((part, index) =>
            index === 0
                ? part.charAt(0).toUpperCase() + part.slice(1).toLowerCase() // Capitalize first part: "Node"
                : part.charAt(0).toUpperCase() + part.slice(1) // Keep second part with "Js"
        )
        .join("");
    const title = useContext(TitleContext);


    const subjects = {
        'node-js' : () => import("./Assignments/NodeJS"),
        'mongo-db' : () => import("./Assignments/MongoDB"),
        'angular': () => import("./Assignments/Angular"),
        'react-js': () => import("./Assignments/ReactJS"),
        'javascript': () => import("./Assignments/Javascript"),
        'html': () => import("./Assignments/HTML"),
        'css': () => import("./Assignments/CSS"),
        'mysql': () => import("./Assignments/Mysql"),
        'php': () => import("./Assignments/PHP"),
        'jquery': () => import("./Assignments/JQuery")
    };

    const loadQuestions = async (subject) => {
        if (subjects[subject]) {
          const module = await subjects[subject]();
          return module.default;
        } else {
          return []
        }
    };

    const handleOptionClick = (index) => {
        setSelectedOption(index);
    };

    const handleNextClick = () => {
        if (selectedOption === questions[currentQuestion].answer) {
            setScore(score + 1);
        }
        if (currentQuestion + 1 < questions.length) {
            setCurrentQuestion(currentQuestion + 1);
            setSelectedOption(null); // Reset selected option for the next question
        } else {
            setShowScore(true);
        }
    };

    const resetQuiz = () => {
        setCurrentQuestion(0);
        setSelectedOption(null);
        setScore(0);
        setShowScore(false);
    };

    const setPathName = (path) => {
        if(path)
            pathName.setPathName(path);
    }

    useEffect(() => {
        setPathName(window.location.pathname)
        title.setPageTitle(subjectName + " Quiz | Aspirant's Home");
        const getRandomQuestions = (questions, count = 25) => {
            if (questions.length <= count) return questions;
            return questions.sort(() => 0.5 - Math.random()).slice(0, count);
        };
        const fetchQuestions = async () => {
            try {
                const loadedQuestions = await loadQuestions(subject);
                setQuestion(getRandomQuestions(loadedQuestions));
            } catch (error) {
                console.error("Failed to load questions:", error);
            }
        };
      
        fetchQuestions();

    }, [pathname, subject])
    

    return (
        <>
            <TopHeader />
            <div className='container-fluid'>
                <div className='row' style={{ background: '#ddd' }}>
                    <div ref={menuElement} className={menu.isMenu == true ? 'col-md-3 col-lg-2 menuColumnMobile menuColumn p-0' : ' menuColumn p-0'} >
                        <ExcerciseMenu pathname={pathname} setPathName={setPathName} />
                    </div>
                    <div className='col-12 middlePart'>
                        <div className='headerAdSpace' ref={addspaceElement}>
                            <img className='img-fluid' src='https://aspirantshome.com/slide1.png' />
                        </div>
                        <div className='contentPart row sticky-top'>
                            <div className='col-12 col-md-12 mb-4 contentSection'>
                                <div className='container'>
                                    <h3 className='mb-3'>
                                        {subjectName} Assignment
                                    </h3>   
                                </div>
                            </div>
                            <div className='footerAdSpace'>
                                <img className='img-fluid' src='https://aspirantshome.com/slide4.png' />
                            </div>
                        </div>
                    </div>
                    <div className='col-12  rightPart'>
                        <img className='img-fluid' src='https://aspirantshome.com/slide2.png' />
                        <img className='mt-5 img-fluid' src='https://aspirantshome.com/slide3.png' />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='container-fluid footerPart'>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Excercise;
