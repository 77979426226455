import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/asynchronous";

export default function Asynchronous() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Asynchronous in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/callbacks',
            'next': '/javascript/promises'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Asynchronous</h3>
            <div className='mt-4 mb-5'>

            </div>
        </section>
    )
}