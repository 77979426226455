import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/async-await";

export default function AsyncAwait() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Async/Await in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/promises',
            'next': '/javascript/selecting-elements'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Async/Await</h3>
            <div className='mt-4 mb-5'>
                <p>
                    <strong>Async/Await</strong> is a simpler way to handle <strong>Promises</strong> in JavaScript. It makes asynchronous code look and behave like synchronous code, which makes it easier to understand and work with.
                </p>


                <h5 className='mt-5 mb-3'><span className='background-grey'>async</span> Keyword</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Used to define a function that always returns a promise.</li>
                    <li>Inside an <span className='background-grey'>async</span> function, you can use the <span className='background-grey'>await</span> keyword.</li>
                </ul>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">async</span> <span class="color-blue">function</span> <span class="color-red">myFunction</span>() {</p>
                        <p class="ml-30">    <span class="color-pink">return</span> <span class="color-green">"Hello, World!"</span>;</p>
                        <p>}</p>
                        <br />
                        <p><span class="color-red">myFunction</span>().<span class="color-red">then</span>(result => <span class="color-pink">console</span>.<span class="color-red">log</span>(result)); <span class="color-grey">// Output: Hello, World!</span></p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'><span className='background-grey'>await</span> Keyword</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Used to pause the execution of an <span className='background-grey'>async</span> function until a promise is resolved.</li>
                    <li>Can only be used inside an <span className='background-grey'>async</span> function.</li>
                </ul>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">async</span> <span class="color-blue">function</span> <span class="color-red">myFunction</span>() {</p>
                        <p class="ml-30">    <span class="color-blue">let</span> promise = <span class="color-blue">new</span> <span class="color-red">Promise</span>((resolve, reject) => {</p>
                        <p class="ml-60">        <span class="color-red">setTimeout</span>(() => <span class="color-red">resolve</span>(<span class="color-green">"Promise resolved!"</span>), <span class="color-pink">2000</span>);</p>
                        <p class="ml-30">    });</p>
                        <br />
                        <p class="ml-30">    <span class="color-blue">let</span> result = <span class="color-pink">await</span> promise; <span class="color-grey">// Waits for the promise to resolve</span></p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(result); <span class="color-grey">// Output: Promise resolved!</span></p>
                        <p>}</p>
                        <br />
                        <p><span class="color-red">myFunction</span>();</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Why Use Async/Await?</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Simpler Syntax</strong>: It makes asynchronous code look like synchronous code.</li>
                    <li><strong>Better Readability</strong>: Reduces the need for chaining <span className='background-grey'>.then()</span> and <span className='background-grey'>.catch()</span>.</li>
                    <li><strong>Error Handling</strong>: Uses <span className='background-grey'>try...catch</span> for cleaner error management.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Async/Await Example</h5>
                <h6 className='mt-3'>Fetching Data Example</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">async</span> <span class="color-blue">function</span> <span class="color-red">fetchData</span>() {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Fetching data..."</span>);</p>
                        <br />
                        <p class="ml-30">    <span class="color-blue">let</span> promise = <span class="color-blue">new</span> <span class="color-red">Promise</span>((resolve, reject) => {</p>
                        <p class="ml-60">        <span class="color-red">setTimeout</span>(() => <span class="color-red">resolve</span>(<span class="color-green">"Data received!"</span>), <span class="color-pink">3000</span>);</p>
                        <p class="ml-30">    });</p>
                        <br />
                        <p class="ml-30">    <span class="color-blue">let</span> result = <span class="color-pink">await</span> promise; <span class="color-grey">// Waits for the promise to resolve</span></p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(result);</p>
                        <p>}</p>
                        <br>
                        <p><span class="color-red">fetchData</span>();</p>
                        `
                    }}></div>
                </div>
                <h6>Output</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>Fetching data...</p>
                        <p>(Data received after <span class="color-pink">3</span> seconds)</p>
                        <p>Data received!</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Using <span className='background-grey'>try...catch</span> with Async/Await</h5>
                <p>To handle errors in an <span className='background-grey'>async</span> function, use <span className='background-grey'>try...catch</span>.</p>
                <h6 className='mt-3'>Example</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">async</span> <span class="color-blue">function</span> <span class="color-red">fetchData</span>() {</p>
                        <p class="ml-30">    <span class="color-blue">try</span> {</p>
                        <p class="ml-60">        <span class="color-blue">let</span> promise = <span class="color-blue">new</span> <span class="color-red">Promise</span>((resolve, reject) => {</p>
                        <p class="ml-90">            <span class="color-red">setTimeout</span>(() => <span class="color-red">reject</span>(<span class="color-green">"Error: Data not available!"</span>), <span class="color-pink">2000</span>);</p>
                        <p class="ml-60">        });</p>
                        <br />
                        <p class="ml-60">        <span class="color-blue">let</span> result = <span class="color-pink">await</span> promise; <span class="color-grey">// Waits for the promise to resolve or reject</span></p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(result);</p>
                        <p class="ml-30">    } <span class="color-blue">catch</span> (error) {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(error); <span class="color-grey">// Handles the error</span></p>
                        <p class="ml-30">    }</p>
                        <p>}</p>
                        <br />
                        <p><span class="color-red">fetchData</span>();</p>
                        `
                    }}></div>
                </div>
                <h6>Output</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>Error: Data not available!</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Chaining Async Functions</h5>
                <p>You can chain multiple <span className='background-grey'>await</span> calls inside an <span className='background-grey'>async</span> function.</p>
                <h6 className='mt-3'>Example</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">async</span> <span class="color-blue">function</span> <span class="color-red">processTasks</span>() {</p>
                        <p class="ml-30">    <span class="color-blue">let</span> step1 = <span class="color-pink">await</span> <span class="color-blue">new</span> <span class="color-red">Promise</span>(resolve => <span class="color-red">setTimeout</span>(() => <span class="color-red">resolve</span>(<span class="color-green">"Step 1 done!"</span>), <span class="color-pink">1000</span>));</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(step1);</p>
                        <br />
                        <p class="ml-30">    <span class="color-blue">let</span> step2 = <span class="color-pink">await</span> <span class="color-blue">new</span> <span class="color-red">Promise</span>(resolve => <span class="color-red">setTimeout</span>(() => <span class="color-red">resolve</span>(<span class="color-green">"Step 2 done!"</span>), <span class="color-pink">2000</span>));</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(step2);</p>
                        <br />
                        <p class="ml-30">    <span class="color-blue">let</span> step3 = <span class="color-pink">await</span> <span class="color-blue">new</span> <span class="color-red">Promise</span>(resolve => <span class="color-red">setTimeout</span>(() => <span class="color-red">resolve</span>(<span class="color-green">"Step 3 done!"</span>), <span class="color-pink">1000</span>));</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(step3);</p>
                        <p>}</p>
                        <br />
                        <p><span class="color-red">processTasks</span>();</p>
                        `
                    }}></div>
                </div>
                <h6>Output</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>Step 1 done! (after <span class="color-pink">1</span> second)</p>
                        <p>Step 2 done! (after <span class="color-pink">2</span> seconds)</p>
                        <p>Step 3 done! (after <span class="color-pink">1</span> second)</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Rules for Async/Await</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Use <span className='background-grey'>await</span> Only Inside <span className='background-grey'>async</span> Functions:</strong>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">async</span> <span class="color-blue">function</span> <span class="color-red">example</span>() {</p>
                                <p class="ml-30">    <span class="color-blue">let</span> result = <span class="color-pink">await</span> Promise.<span class="color-red">resolve</span>(<span class="color-green">"Hello"</span>);</p>
                                <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(result);</p>
                                <p>}</p>
                                <br />
                                <p><span class="color-red">example</span>();</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Async Functions Always Return a Promise:</strong>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">async</span> <span class="color-blue">function</span> <span class="color-red">myFunction</span>() {</p>
                                <p class="ml-30">    <span class="color-pink">return</span> <span class="color-green">"Result"</span>;</p>
                                <p>}</p>
                                <br />
                                <p><span class="color-red">myFunction</span>().<span class="color-red">then</span>(result => <span class="color-pink">console</span>.<span class="color-red">log</span>(result)); <span class="color-grey">// Output: Result</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Handle Errors Using</strong> <span className='background-grey'>try...catch</span>: Always use <span className='background-grey'>try...catch</span> for error handling in <span className='background-grey'>async</span> functions.
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>Advantages of Async/Await</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Improves Readability</strong>: Makes code easier to write and understand compared to promise chaining.</li>
                    <li><strong>Handles Errors Cleanly</strong>: Use <span className='background-grey'>try...catch</span> for error handling instead of <span className='background-grey'>.catch()</span> in promises.</li>
                    <li><strong>Supports Sequential Execution</strong>: Tasks that depend on each other can be written sequentially.</li>
                </ul>


            </div>
        </section>
    )
}