import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/string-functions/are-there-any-performance-benchmarks-for-the-replace-function-in-mysql";

export default function FAQ1() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - What is the difference between REPLACE and other string functions in MySQL?");
        title.setKeyWords("mysql replace");
        const urls = {
            'previous': '/mysql/string-functions/replace'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>What is the difference between REPLACE and other string functions in MySQL?</h3>
            <div className='mt-4 mb-5'>

            </div>
        </section>
    )
}