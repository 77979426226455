import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/string-search";

export default function StringSearch() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("String Search in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/string-methods',
            'next': '/javascript/string-templates'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>String Search</h3>
            <div className='mt-4 mb-5'>
                <p>
                    String searching is a way to look for specific characters or words within a string. JavaScript provides several methods to search for text in strings.
                </p>

                <h5 className='mt-5 mb-3'>String Search Methods</h5>
                <p>Here are the most commonly used string search methods in JavaScript:</p>

                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <span className='background-grey'>indexOf()</span>
                        <p>It finds the position of the <strong>first occurrence</strong> of a specified text in a string. It returns the index (position) of the text, or <span className='background-grey'>-1</span> if not found.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> text = <span class="color-green">"Hello, World!"</span>;</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(text.<span class="color-red">indexOf</span>(<span class="color-green">"World"</span>)); <span class="color-grey">// Output: 7</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(text.<span class="color-red">indexOf</span>(<span class="color-green">"JavaScript"</span>)); <span class="color-grey">// Output: -1 (not found)</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>lastIndexOf()</span>
                        <p>It finds the position of the <strong>last occurrence</strong> of a specified text in a string. It returns the index of the last occurrence, or <span className='background-grey'>-1</span> if not found.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">let</span> text = <span class="color-green">"Hello, World! Hello!"</span>;</p>
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(text.<span class="color-red">lastIndexOf</span>(<span class="color-green">"Hello"</span>)); <span class="color-grey">// Output: 14</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>search()</span>
                        <p>It searches for a specified value using a <strong>regular expression</strong> and returns the position of the match and returns the index of the first match or <span className='background-grey'>-1</span> if not found.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> text = <span class="color-green">"Hello, World!"</span>;</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(text.<span class="color-red">search</span>(<span class="color-green">"World"</span>)); <span class="color-grey">// Output: 7</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(text.<span class="color-red">search</span>(<span class="color-blue">/world/i</span>)); <span class="color-grey">// Output: 7 (case-insensitive)</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>includes()</span>
                        <p>It checks if a string contains a specified text and returns <span className='background-grey'>true</span> if found, otherwise <span className='background-grey'>false</span>.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> text = <span class="color-green">"Hello, World!"</span>;</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(text.<span class="color-red">includes</span>(<span class="color-green">"World"</span>)); <span class="color-grey">// Output: true</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(text.<span class="color-red">includes</span>(<span class="color-green">"JavaScript"</span>)); <span class="color-grey">// Output: false</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>startsWith()</span>
                        <p>It checks if a string <strong>starts</strong> with a specified text. It returns <span className='background-grey'>true</span> if it starts with the text, otherwise <span className='background-grey'>false</span>.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> text = <span class="color-green">"Hello, World!"</span>;</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(text.<span class="color-red">startsWith</span>(<span class="color-green">"Hello"</span>)); <span class="color-grey">// Output: true</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(text.<span class="color-red">startsWith</span>(<span class="color-green">"World"</span>)); <span class="color-grey">// Output: false</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>endsWith()</span>
                        <p>It checks if a string <strong>ends</strong> with a specified text. It returns <span className='background-grey'>true</span> if it ends with the text, otherwise <span className='background-grey'>false</span>.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> text = <span class="color-green">"Hello, World!"</span>;</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(text.<span class="color-red">endsWith</span>(<span class="color-green">"World!"</span>)); <span class="color-grey">// Output: true</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(text.<span class="color-red">endsWith</span>(<span class="color-green">"Hello"</span>)); <span class="color-grey">// Output: false</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>



            </div>
        </section>
    )
}