import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mongo-db/create-collection";

export default function MongoCreateCollection() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Create Collection in MongoDB | Full Guide Step By Step");
        title.setKeyWords("create collection in mongodb, command to create collection in mongodb, create a collection in mongodb, create database and collection in mongodb, create and drop collection in mongodb, mongodb create collection example, mongodb get collection definition, create collection and insert data in mongodb, create collections mongodb, create collection example in mongodb, create collection in mongodb command");
        title.setPageDescription("Creating a collection in MongoDB can be done either explicitly using the `createCollection()` method or implicitly by inserting a document into a collection that does not yet exist.");
        const urls = {
            'previous': '/mongo-db/drop-database',
            'next': '/mongo-db/drop-collection'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>MongoDB - Create Collection</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Collections are a key component of MongoDB, serving as containers for documents. Unlike traditional databases where you define tables and their schemas beforehand, MongoDB collections are schema-flexible and are created dynamically. This blog explains how to create a collection in MongoDB, the different methods available, and best practices to follow.
                </p>

                <h5 className='mt-5 mb-4'>What Is a Collection in MongoDB?</h5>
                <p>
                    In MongoDB, a collection is a grouping of documents, similar to a table in relational databases. Each document in a collection is a JSON-like object, and the structure of these documents can vary.
                </p>
                <p>
                    Collections are created within a database, and you can perform various operations on them, such as inserting, updating, querying, or deleting documents.
                </p>

                <h5 className='mt-5 mb-4'>How to Create a Collection</h5>
                <p>MongoDB allows you to create collections explicitly or implicitly.</p>


                <h6 className='mt-4'>1. Explicit Collection Creation</h6>
                <p>
                    Using the <strong>`createCollection()`</strong> method, you can specify various options for the collection.
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.<span class="color-red">createCollection</span>(name, options)</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, <strong>`name`</strong> is the name of the collection to create and <strong>`options`</strong> are optional which are the configuration options for that collection.
                </p>

                <p>For example, if you want to create a collection named <strong>`mycollection`</strong>:</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>use mydatabase</p>
                            <p>db.<span class="color-red">createCollection</span>(<span class="color-green">"mycollection"</span>)</p>
                        `
                    }}></div>
                </div>
                <p>With options:</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.<span class="color-red">createCollection</span>(<span class="color-green">"mycollection"</span>, { <span class="color-pink">capped</span>: <span class="color-blue">true</span>, <span class="color-pink">size</span>: <span class="color-pink">10000</span>, <span class="color-pink">max</span>: <span class="color-pink">1000</span> })</p>
                        `
                    }}></div>
                </div>



                <h6 className='mt-5'>2. Implicit Collection Creation</h6>
                <p>
                    MongoDB will automatically create a collection if you try to insert a document into a collection that doesn't exist.
                </p>
                <p>
                    For example, If you want to insert a document into mycollection (this will create the collection if it doesn't exist):
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>use mydatabase</p>
                            <p>db.mycollection.<span class="color-red">insertOne</span>({ <span class="color-pink">name</span>: <span class="color-green">"John Doe"</span>, <span class="color-pink">age</span>: <span class="color-pink">30</span> })</p>
                        `
                    }}></div>
                </div>
                <p>
                    To verify the collections in your database, you can use the <strong>`show collections`</strong> command:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&gt; show collections</p>
                            <p>mycollection</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-4'>Using Options in db.createCollection()</h5>
                <p>MongoDB provides several options to customize the collection.</p>

                <h6 className='mt-3'>1. Capped Collections</h6>
                <p>A capped collection is a fixed-size collection that maintains insertion order and automatically removes the oldest entries when the limit is reached.</p>
                <strong>Example:</strong>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>db.<span class="color-red">createCollection</span>(<span class="color-green">"cappedCollection"</span>, {</p>
                        <p class="ml-30">    capped: <span class="color-blue">true</span>,</p>
                        <p class="ml-30">    size: <span class="color-pink">1024</span></p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>This creates a capped collection with a maximum size of 1024 bytes.</p>

                <h6 className='mt-3'>2. Validation Rules</h6>
                <p>You can enforce document validation rules when creating a collection.</p>
                <strong>Example:</strong>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>db.<span class="color-red">createCollection</span>(<span class="color-green">"validatedCollection"</span>, {</p>
                        <p class="ml-30">    validator: {</p>
                        <p class="ml-60">        $jsonSchema: {</p>
                        <p class="ml-90">            bsonType: <span class="color-green">"object"</span>,</p>
                        <p class="ml-90">            required: [<span class="color-green">"name"</span>, <span class="color-green">"age"</span>],</p>
                        <p class="ml-90">            properties: {</p>
                        <p class="ml-120">                name: {</p>
                        <p class="ml-150">                    bsonType: <span class="color-green">"string"</span>,</p>
                        <p class="ml-150">                    description: <span class="color-green">"must be a string"</span></p>
                        <p class="ml-120">                },</p>
                        <p class="ml-120">                age: {</p>
                        <p class="ml-150">                    bsonType: <span class="color-green">"int"</span>,
                        <p class="ml-150">                    minimum: <span class="color-pink">0</span>,
                        <p class="ml-150">                    description: <span class="color-green">"must be a positive integer"</span>
                        <p class="ml-120">                }</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        }</p>
                        <p class="ml-30">    }</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>This collection ensures that all documents contain a <span className='background-grey'>name</span> and <span className='background-grey'>age</span> field, with specific types and constraints.</p>


                <h5 className='mt-5 mb-4'>Check Existing Collections</h5>
                <p>To list all collections in the current database, use:</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.<span class="color-red">getCollectionNames</span>();</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-4'>Best Practices for Creating Collections</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Naming Conventions:</strong>
                        <ul style={{listStyle: 'disc'}}>
                            <li>Use lowercase letters and avoid special characters.</li>
                            <li>Use underscores (_) to separate words (e.g., user_data).</li>
                        </ul>
                    </li>

                    <li>
                        <strong>Indexing:</strong>
                        <ul style={{listStyle: 'disc'}}>
                            <li>Plan and create indexes on fields frequently queried to improve performance.</li>
                        </ul>
                    </li>

                    <li>
                        <strong>Validation Rules:</strong>
                        <ul style={{listStyle: 'disc'}}>
                            <li>Apply validation rules to enforce consistency in your data.</li>
                        </ul>
                    </li>

                    <li>
                        <strong>Avoid Overuse of Explicit Creation:</strong>
                        <ul style={{listStyle: 'disc'}}>
                            <li>Let collections be created implicitly unless specific options or configurations are needed.</li>
                        </ul>
                    </li>


                </ul>

                
                <h5 className='mt-5 mb-4'>FAQs About Creating Collections</h5>

                <h6 className='mt-3'>1. Is it necessary to explicitly create a collection in MongoDB?</h6>
                <p>No, MongoDB will automatically create a collection when you insert the first document.</p>

                <h6 className='mt-3'>2. Can I create a collection without a database?</h6>
                <p>No, a collection must belong to a database. If the database doesn’t exist, it will be created automatically when the collection is created.</p>

                <h6 className='mt-3'>3. How do I delete a collection?</h6>
                <p>Use the <span className='background-grey'>drop()</span> method to delete a collection:</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.myCollection.<span class="color-red">drop</span>();</p>
                        `
                    }}></div>
                </div>

                <h6 className='mt-3'>4. What happens if I try to create a collection that already exists?</h6>
                <p>MongoDB will throw an error. You cannot create a collection with the same name in the same database.</p>

                <h6 className='mt-3'>5. Can I rename a collection?</h6>
                <p>Yes, use the <span className='background-grey'>renameCollection()</span> method to rename a collection:</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>db.myCollection.<span class="color-red">renameCollection</span>(<span class="color-green">"newName"</span>);</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-4'>Conclusion</h5>
                <p>
                    Creating a collection in MongoDB is straightforward, whether you choose to do so implicitly or explicitly. By understanding the different methods and options available, you can create collections that meet your application’s specific requirements. Always follow best practices to ensure your database remains efficient and well-organized.
                </p>

            </div>
        </section>
    )
}