import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/strings";

export default function Strings() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Strings in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/date-set-methods',
            'next': '/javascript/string-methods'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Strings</h3>
            <div className='mt-4 mb-5'>
                <p>
                    A <strong>string</strong> in JavaScript is a sequence of characters used to represent text. Strings can contain letters, numbers, symbols, and even spaces. They are one of the most commonly used data types in programming.
                </p>

                <h5 className='mt-5 mb-3'>How to Create a String</h5>
                <p>In JavaScript, you can create strings using:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Single Quotes (' ')</strong>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">let</span> singleQuoteString = <span class="color-green">'Hello, World!'</span>;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Double Quotes (" ")</strong>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">let</span> doubleQuoteString = <span class="color-green">"Hello, World!"</span>;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Template Literals</strong>
                        <p>It is introduced in ES6, allows embedding variables and expressions.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> name = <span class="color-green">"John"</span>;</p>
                                <p><span class="color-blue">let</span> greeting = <span class="color-green">&#96;Hello, $&#123;name&#125;!&#96;</span>;</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(greeting); <span class="color-grey">// Output: Hello, John!</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>Properties of Strings</h5>

                <h6 className='mt-3'>Length Property</h6>
                <p>It returns the number of characters in the string, including spaces.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> text = <span class="color-green">"JavaScript"</span>;</p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(text.length); <span class="color-grey">// Output: 10</span></p>
                        `
                    }}></div>
                </div>


            </div>
        </section>
    )
}