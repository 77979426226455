import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/number-methods";

export default function NumberMethods() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Number Methods in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/numbers',
            'next': '/javascript/number-properties'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Number Methods</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Number methods in JavaScript are built-in functions that help perform various operations and formatting on numbers. These methods work with the <strong>Number</strong> data type and make it easier to manipulate numerical values.
                </p>

                <h5 className='mt-5 mb-3'>Commonly Used Number Methods</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <span className='background-grey'>toString()</span>
                        <p>It converts a number to a string.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> num = <span class="color-pink">123</span>;</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(num.<span class="color-red">toString</span>()); <span class="color-grey">// Output: "123" (as a string)</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>toFixed()</span>
                        <p>It rounds a number to a specified number of decimal places and returns it as a string. It has a basic syntax - <span className='background-grey'>number.toFixed(digits)</span>, where <span className='background-grey'>digits</span> is the number of decimal places to keep.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> num = <span class="color-pink">3.14159</span>;</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(num.<span class="color-red">toFixed</span>(<span class="color-pink">2</span>)); <span class="color-grey">// Output: "3.14"</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>toPrecision()</span>
                        <p>It formats a number to a specified length, including the number of significant digits. It has a basic syntax - <span className='background-grey'>number.toPrecision(precision)</span>, where <span className='background-grey'>precision</span> is total number of significant digits.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> num = <span class="color-pink">123.456</span>;</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(num.<span class="color-red">toPrecision</span>(<span class="color-pink">4</span>)); <span class="color-grey">// Output: "123.5"</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>toExponential()</span>
                        <p>It converts a number to exponential (scientific) notation. It has a basic syntax - <span className='background-grey'>number.toExponential(fractionDigits)</span> where, <span className='background-grey'>fractionDigits</span> is the number of digits after the decimal point.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> num = <span class="color-pink">12345</span>;</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(num.<span class="color-red">toExponential</span>(<span class="color-pink">2</span>)); <span class="color-grey">// Output: "1.23e+4"</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>Number.isInteger()</span>
                        <p>It checks if a value is an integer.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(Number.<span class="color-red">isInteger</span>(<span class="color-pink">5</span>));    <span class="color-grey">// Output: true</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(Number.<span class="color-red">isInteger</span>(<span class="color-pink">5.5</span>));  <span class="color-grey">// Output: false</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>Number.isNaN()</span>
                        <p>It checks if a value is <strong>NaN (Not-a-Number)</strong>.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(Number.<span class="color-red">isNaN</span>(<span class="color-pink">10</span> / <span class="color-green">"abc"</span>)); <span class="color-grey">// Output: true</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(Number.<span class="color-red">isNaN</span>(<span class="color-pink">10</span>));         <span class="color-grey">// Output: false</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>Number.parseInt()</span>
                        <p>It converts a string to an integer (whole number).</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">let</span> str = <span class="color-green">"123"</span>;</p>
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(Number.<span class="color-red">parseInt</span>(str)); <span class="color-grey">// Output: 123</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>Number.parseFloat()</span>
                        <p>It converts a string to a floating-point number.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">let</span> str = <span class="color-green">"123.45"</span>;</p>
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(Number.<span class="color-red">parseFloat</span>(str)); <span class="color-grey">// Output: 123.45</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>Number.isFinite()</span>
                        <p>It checks if a value is a finite number.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(Number.<span class="color-red">isFinite</span>(<span class="color-pink">10</span>));        <span class="color-grey">// Output: true</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(Number.<span class="color-red">isFinite</span>(<span class="color-blue">Infinity</span>));  <span class="color-grey">// Output: false</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>valueOf()</span>
                        <p>It returns the primitive value of a <strong>Number</strong> object.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> num = <span class="color-blue">new</span> <span class="color-red">Number</span>(<span class="color-pink">123</span>);</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(num.<span class="color-red">valueOf</span>()); <span class="color-grey">// Output: 123</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


            </div>
        </section>
    )
}