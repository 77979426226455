import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/string-functions/how-can-i-replace-substrings-in-multiple-columns-at-once-in-a-single-query";

export default function FAQ3() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - How can I replace substrings in multiple columns at once in a single query?");
        title.setKeyWords("mysql replace");
        const urls = {
            'previous': '/mysql/string-functions/replace'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>How can I replace substrings in multiple columns at once in a single query?</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In MySQL, you can replace substrings in multiple columns within a single query by using the <span className='background-grey'>REPLACE</span> <strong>function</strong> for each column. This can be done in both <span className='background-grey'>SELECT</span> and <span className='background-grey'>UPDATE</span> queries.
                </p>

                <h5 className='mt-5 mb-3'>In SELECT Query</h5>
                <p>If you want to view the changes without modifying the actual data, use <span className='background-grey'>REPLACE</span> for each column in a <span className='background-grey'>SELECT</span> query.</p>

                <h6 className='mt-3'>Example:</h6>
                <p>Suppose you have a table <span className='background-grey'>products</span> with the following data:</p>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                            <th style={{ border: '1px dashed #ccc', backgroundColor: '#e1f1ff' }}>name</th>
                            <th style={{ border: '1px dashed #ccc', backgroundColor: '#e1f1ff' }}>description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>1</td>
                            <td style={{ border: '1px dashed #ccc' }}>apple juice</td>
                            <td style={{ border: '1px dashed #ccc' }}>Fresh apple juice</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>2</td>
                            <td style={{ border: '1px dashed #ccc' }}>orange soda</td>
                            <td style={{ border: '1px dashed #ccc' }}>Fizzy orange soda</td>
                        </tr>
                    </tbody>
                </table>
                <p>You want to replace the word "apple" with "banana" in the <span className='background-grey'>name</span> and <span className='background-grey'>description</span> columns.</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">SELECT</span> </p>
                        <p class="ml-30">    <span class="color-red">REPLACE</span>(name, <span class="color-green">'apple'</span>, <span class="color-green">'banana'</span>) <span class="color-blue">AS</span> updated_name,</p>
                        <p class="ml-30">    <span class="color-red">REPLACE</span>(description, <span class="color-green">'apple'</span>, <span class="color-green">'banana'</span>) <span class="color-blue">AS</span> updated_description</p>
                        <p><span class="color-blue">FROM</span> products;</p>
                        `
                    }}></div>
                </div>
                <h6 className='mt-3'>Result:</h6>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>updated_name</th>
                            <th style={{ border: '1px dashed #ccc', backgroundColor: '#e1f1ff' }}>updated_description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>banana juice</td>
                            <td style={{ border: '1px dashed #ccc' }}>Fresh banana juice</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>orange soda</td>
                            <td style={{ border: '1px dashed #ccc' }}>Fizzy orange soda</td>
                        </tr>
                    </tbody>
                </table>


                <h5 className='mt-5 mb-3'>In UPDATE Query</h5>
                <p>If you want to permanently update the data in the table, use the <span className='background-grey'>REPLACE</span> function in an <span className='background-grey'>UPDATE</span> statement for each column you wish to modify.</p>

                <h6 className='mt-3'>Example:</h6>
                <p>To permanently update the <span className='background-grey'>name</span> and <span className='background-grey'>description</span> columns to replace "apple" with "banana":</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">UPDATE</span> products</p>
                        <p><span class="color-blue">SET</span> </p>
                        <p class="ml-30">    name = <span class="color-red">REPLACE</span>(name, <span class="color-green">'apple'</span>, <span class="color-green">'banana'</span>),</p>
                        <p class="ml-30">    description = <span class="color-red">REPLACE</span>(description, <span class="color-green">'apple'</span>, <span class="color-green">'banana'</span>);</p>
                        `
                    }}></div>
                </div>
                <h6 className='mt-3'>Result:</h6>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>updated_name</th>
                            <th style={{ border: '1px dashed #ccc', backgroundColor: '#e1f1ff' }}>updated_description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>banana juice</td>
                            <td style={{ border: '1px dashed #ccc' }}>Fresh banana juice</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>orange soda</td>
                            <td style={{ border: '1px dashed #ccc' }}>Fizzy orange soda</td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
    )
}