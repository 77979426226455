import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/show-users";

export default function MysqlShowUser() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Show User");
        const urls = {
            'previous': '/mysql/drop-users',
            'next': '/mysql/change-password'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Show User</h3>
            <div className='mt-4 mb-5'>
                <p>
                    When managing a MySQL database, keeping track of user accounts and their associated privileges is crucial for maintaining security and organization. While MySQL does not have a direct <span className='background-grey'>SHOW USERS</span> command, there are ways to view user accounts efficiently. This blog post explores how to list users in MySQL and check their details.
                </p>

                
                <h5 className='mt-5 mb-4'>How to List Users in MySQL</h5>
                <p>MySQL stores user account information in the <span className='background-grey'>mysql</span> database, specifically in the <span className='background-grey'>user</span> table. To view all users, you can query this table directly.</p>

                <h6 className='mt-3'>Command to List Users</h6>
                <p>Use the following SQL command to list all users:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SELECT</span> User, Host <span class="color-blue">FROM</span> mysql.<span class="color-pink">user</span>;</p>
                        `
                    }}></div>
                </div>
                <p>This query retrieves the usernames and their corresponding host values (e.g., <span className='background-grey'>localhost</span>, %).</p>
                <h6 className='mt-3'>Output:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>+------------------+-----------+</p>
                        <p style="width:250px">| User             <span style="margin-left:100px">|</span> Host      <span class="float-end">|</span></p>
                        <p>+------------------+-----------+</p>
                        <p style="width:250px">| root             <span style="margin-left:100px">|</span> localhost <span class="float-end">|</span></p>
                        <p style="width:250px">| admin            <span style="margin-left:85px">|</span> %         <span class="float-end">|</span></p>
                        <p style="width:250px">| app_user         <span style="margin-left:60px">|</span> 127.0.0.1 <span class="float-end">|</span></p>
                        <p style="width:250px">| guest            <span style="margin-left:85px">|</span> localhost <span class="float-end">|</span></p>
                        <p>+------------------+-----------+</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-4'>Understanding User and Host</h5>
                <h6 className='mt-3'>1. User</h6>
                <p>The <span className='background-grey'>User</span> column lists the account usernames in the MySQL database.</p>
                <h6 className='mt-3'>2. Host</h6>
                <p>The <span className='background-grey'>Host</span> column specifies where the user can connect from. Common values include:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>localhost</span>: The user can connect only from the local machine.</li>
                    <li><span className='background-grey'>%</span>: The user can connect from any host.</li>
                    <li>Specific IP addresses (e.g., <span className='background-grey'>192.168.1.10</span>).</li>
                </ul>


                <h5 className='mt-5 mb-4'>How to Show the Current User</h5>
                <p>To find out which user is currently connected to the MySQL database, use the following command:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SELECT</span> <span class="color-red">USER</span>();</p>
                        `
                    }}></div>
                </div>
                <p>This displays the current user and host they are connected from.</p>
                <h6 className='mt-3'>Output:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>+----------------+</p>
                        <p style="width:150px">| USER()         <span class="float-end">|</span></p>
                        <p>+----------------+</p>
                        <p style="width:150px">| root@localhost <span class="float-end">|</span></p>
                        <p>+----------------+</p>
                        `
                    }}></div>
                </div>
                <p>Alternatively, you can use:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SELECT</span> <span class="color-red">CURRENT_USER</span>();</p>
                        `
                    }}></div>
                </div>
                <p>The difference is that <span className='background-grey'>CURRENT_USER()</span> shows the authenticated user account after privilege checking, while <span className='background-grey'>USER()</span> displays the login user.</p>
                <h6 className='mt-3'>Output:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>+----------------+</p>
                        <p style="width:150px">| CURRENT_USER() <span class="float-end">|</span></p>
                        <p>+----------------+</p>
                        <p style="width:150px">| root@localhost <span class="float-end">|</span></p>
                        <p>+----------------+</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-4'>FAQs About SHOW USERS in MySQL</h5>
                <h6 className='mt-3'>1. Is there a direct SHOW USERS command in MySQL?</h6>
                <p>No, MySQL does not have a <span className='background-grey'>SHOW USERS</span> command. You need to query the <span className='background-grey'>mysql.user</span> table to list users.</p>
                <h6 className='mt-3'>2. How do I list only active users?</h6>
                <p>The <span className='background-grey'>mysql.user</span> table does not track activity. However, you can check active connections using:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p class="color-blue">SHOW PROCESSLIST;</p>
                        `
                    }}></div>
                </div>

                <h6 className='mt-3'>3. Can I rename a user in MySQL?</h6>
                <p>Yes, use the <span className='background-grey'>RENAME USER</span> command:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">RENAME USER</span> <span class="color-green">'old_user'</span>@<span class="color-green">'localhost'</span> <span class="color-blue">TO</span> <span class="color-green">'new_user'</span>@<span class="color-green">'localhost'</span>;</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Conclusion</h5>
                <p>Although MySQL lacks a direct <span className='background-grey'>SHOW USERS</span> command, querying the <span className='background-grey'>mysql.user</span> table provides all the information needed to list users. By understanding how to list and check user details, you can maintain better control over your database's security and access.</p>


            </div>
        </section>
    )
}