import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/making-http-requests";

export default function HttpRequests() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Making HTTP Requests in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/submitting-forms',
            'next': '/javascript/handling-responses'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Making HTTP Requests</h3>
            <div className='mt-4 mb-5'>

                <p>
                    If you're just starting with JavaScript, one of the essential concepts to learn is how to make HTTP requests. <strong>HTTP requests</strong> are used to <strong>interact with servers</strong>, <strong>retrieve data</strong>, or <strong>send data</strong>—for example, fetching data from an API or submitting a form.
                </p>

                <p>JavaScript provides several ways to make HTTP requests:</p>

                <h5 className='mt-5 mb-3'>Making HTTP Requests with XMLHttpRequest</h5>
                <p><span className='background-grey'>XMLHttpRequest</span> is the classic way to make HTTP requests. While it works, it is less convenient than modern approaches.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">const</span> xhr = <span class="color-blue">new</span> <span class="color-red">XMLHttpRequest</span>();</p>
                        <p>xhr.<span class="color-red">open</span>(<span class="color-green">"GET"</span>, <span class="color-green">"https://jsonplaceholder.typicode.com/posts"</span>, <span class="color-blue">true</span>);</p>
                        <br />
                        <p>xhr.<span class="color-pink">onload</span> = <span class="color-red">function</span> () {</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (xhr.status <span class="color-pink">===</span> <span class="color-pink">200</span>) {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Data:"</span>, JSON.<span class="color-red">parse</span>(xhr.<span class="color-pink">responseText</span>));</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">error</span>(<span class="color-green">"Error:"</span>, xhr.<span class="color-pink">statusText</span>);</p>
                        <p class="ml-30">    }</p>
                        <p>};</p>
                        <br />
                        <p>xhr.<span class="color-pink">onerror</span> = <span class="color-red">function</span> () {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">error</span>(<span class="color-green">"Request failed."</span>);</p>
                        <p>};</p>
                        <br />
                        <p>xhr.<span class="color-red">send</span>();</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Using the Fetch API</h5>
                <p>The Fetch API is a modern, promise-based way to make HTTP requests. It’s simpler and cleaner than <span className='background-grey'>XMLHttpRequest</span>.</p>

                <h6 className='mt-3'>Example: Fetching Data with Fetch API</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">fetch</span>(<span class="color-green">"https://jsonplaceholder.typicode.com/posts"</span>)</p>
                        <p>.<span class="color-red">then</span>(response => {</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (!response.ok) {</p>
                        <p class="ml-60">        <span class="color-blue">throw new</span> <span class="color-red">Error</span>(<span class="color-green">"Network response was not ok "</span> + response.<span class="color-pink">statusText</span>);</p>
                        <p class="ml-30">    }</p>
                        <p class="ml-30">    <span class="color-pink">return</span> response.<span class="color-red">json</span>();</p>
                        <p>})</p>
                        <p>.<span class="color-red">then</span>(data => <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Data:"</span>, data))</p>
                        <p>.<span class="color-red">catch</span>(error => <span class="color-pink">console</span>.<span class="color-red">error</span>(<span class="color-green">"Error:"</span>, error));</p>
                        `
                    }}></div>
                </div>

                <h6 className='mt-3'>Example: Sending Data with Fetch API</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">fetch</span>(<span class="color-green">"https://jsonplaceholder.typicode.com/posts"</span>, {</p>
                        <p class="ml-30">    <span class="color-pink">method</span>: <span class="color-green">"POST"</span>,</p>
                        <p class="ml-30">    <span class="color-pink">headers</span>: {</p>
                        <p class="ml-60">        <span class="color-green">"Content-Type"</span>: <span class="color-green">"application/json"</span></p>
                        <p class="ml-30">    },</p>
                        <p class="ml-30">    <span class="color-pink">body</span>: JSON.<span class="color-red">stringify</span>({</p>
                        <p class="ml-60">        <span class="color-pink">title</span>: <span class="color-green">"My New Post"</span>,</p>
                        <p class="ml-60">        <span class="color-pink">body</span>: <span class="color-green">"This is the content of the post."</span>,</p>
                        <p class="ml-60">        <span class="color-pink">userId</span>: <span class="color-pink">1</span></p>
                        <p class="ml-30">    })</p>
                        <p>})</p>
                        <p>.<span class="color-red">then</span>(response => response.<span class="color-red">json</span>())</p>
                        <p>.<span class="color-red">then</span>(data => <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Created:"</span>, data))</p>
                        <p>.<span class="color-red">catch</span>(error => <span class="color-pink">console</span>.<span class="color-red">error</span>(<span class="color-green">"Error:"</span>, error));</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Using Axios</h5>
                <p>Axios is a third-party library that simplifies HTTP requests. It offers advanced features like automatic JSON handling and better error handling.</p>

                <h6 className='mt-3'>Example: Fetching Data with Axios</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>axios.<span class="color-red">get</span>(<span class="color-green">"https://jsonplaceholder.typicode.com/posts"</span>)</p>
                        <p>.<span class="color-red">then</span>(response => <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Data:"</span>, response.<span class="color-pink">data</span>))</p>
                        <p>.<span class="color-red">catch</span>(error => <span class="color-pink">console</span>.<span class="color-red">error</span>(<span class="color-green">"Error:"</span>, error));</p>
                        `
                    }}></div>
                </div>

                <h6 className='mt-3'>Example: Sending Data with Axios</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>axios.<span class="color-red">post</span>(<span class="color-green">"https://jsonplaceholder.typicode.com/posts"</span>, {</p>
                        <p class="ml-30">    <span class="color-pink">title</span>: <span class="color-green">"My New Post"</span>,</p>
                        <p class="ml-30">    <span class="color-pink">body</span>: <span class="color-green">"This is the content of the post."</span>,</p>
                        <p class="ml-30">    <span class="color-pink">userId</span>: <span class="color-pink">1</span></p>
                        <p>})</p>
                        <p>.<span class="color-red">then</span>(response => <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Created:"</span>, response.<span class="color-pink">data</span>))</p>
                        <p>.<span class="color-red">catch</span>(error => <span class="color-pink">console</span>.<span class="color-red">error</span>(<span class="color-green">"Error:"</span>, error));</p>
                        `
                    }}></div>
                </div>



            </div>
        </section>
    )
}