import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/string-methods";

export default function StringMethods() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("String Methods in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/strings',
            'next': '/javascript/string-search'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>String Methods</h3>
            <div className='mt-4 mb-5'>
                <p>
                    JavaScript provides many built-in methods to work with strings. Here are some important ones:
                </p>

                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <span className='background-grey'>toUpperCase()</span> and <span className='background-grey'>toLowerCase()</span>
                        <p>It converts the string to uppercase or lowercase.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> text = <span class="color-green">"Hello"</span>;</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(text.<span class="color-red">toUpperCase</span>()); <span class="color-grey">// Output: HELLO</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(text.<span class="color-red">toLowerCase</span>()); <span class="color-grey">// Output: hello</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>charAt()</span>
                        <p>It returns the character at a specified index.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> text = <span class="color-green">"JavaScript"</span>;</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(text.<span class="color-red">charAt</span>(<span class="color-pink">0</span>)); <span class="color-grey">// Output: J</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(text.<span class="color-red">charAt</span>(<span class="color-pink">4</span>)); <span class="color-grey">// Output: S</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>indexOf()</span>
                        <p>It returns the position of the first occurrence of a substring.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> text = <span class="color-green">"Hello, World!"</span>;</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(text.<span class="color-red">indexOf</span>(<span class="color-green">"World"</span>)); <span class="color-grey">// Output: 7</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>slice()</span>
                        <p>It extracts a part of the string and returns it as a new string.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> text = <span class="color-green">"JavaScript"</span>;</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(text.<span class="color-red">slice</span>(<span class="color-pink">0</span>, <span class="color-pink">4</span>)); <span class="color-grey">// Output: Java</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>substring()</span>
                        <p>It is similar to <span className='background-grey'>slice()</span> but doesn’t accept negative indices.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> text = <span class="color-green">"JavaScript"</span>;</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(text.<span class="color-red">substring</span>(<span class="color-pink">4</span>, <span class="color-pink">10</span>)); <span class="color-grey">// Output: Script</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>replace()</span>
                        <p>It replaces a substring with another string.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> text = <span class="color-green">"Hello, World!"</span>;</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(text.<span class="color-red">replace</span>(<span class="color-green">"World"</span>, <span class="color-green">"JavaScript"</span>)); <span class="color-grey">// Output: Hello, JavaScript!</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>trim()</span>
                        <p>It removes whitespace from both ends of the string.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">let</span> text = <span class="color-green">"  Hello, World!  "</span>;</p>
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(text.<span class="color-red">trim</span>()); <span class="color-grey">// Output: Hello, World!</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>split()</span>
                        <p>It splits the string into an array of substrings based on a delimiter.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">let</span> text = <span class="color-green">"apple,banana,orange"</span>;</p>
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(text.<span class="color-red">split</span>(<span class="color-green">","</span>)); <span class="color-grey">// Output: ['apple', 'banana', 'orange']</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>concat()</span>
                        <p>It combines two or more strings into one.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> firstName = <span class="color-green">"John"</span>;</p>
                                <p><span class="color-blue">let</span> lastName = <span class="color-green">"Doe"</span>;</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(firstName.<span class="color-red">concat</span>(<span class="color-green">" "</span>, lastName)); <span class="color-grey">// Output: John Doe</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>




            </div>
        </section>
    )
}