import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/js-location";

export default function LocationObject() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Window Location Object in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/js-screen',
            'next': '/javascript/js-history'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Window Location Object</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className='background-grey'>location</span> object in JavaScript is a property of the <span className='background-grey'>window</span> object. It represents the URL of the current web page and provides methods and properties to access and manipulate the URL. You can use the <span className='background-grey'>location</span> object to navigate to new pages, reload the current page, or extract parts of the URL.
                </p>


                <h5 className='mt-5 mb-3'>Key Properties of the <span className='background-grey'>location</span> Object</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li className='operator mt-4'>
                        <h4><span className='background-grey'>location.href</span></h4>
                        <p>It returns the full URL of the current page. For Example:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Current URL: "</span> + location.href);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-4'>
                        <h4><span className='background-grey'>location.protocol</span></h4>
                        <p>It returns the protocol used in the URL (e.g., <span className='background-grey'>http:</span>, <span className='background-grey'>https:</span>).</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Protocol: "</span> + location.protocol);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-4'>
                        <h4><span className='background-grey'>location.hostname</span></h4>
                        <p className='mb-0'>It returns the domain name of the web page (e.g., <span className='background-grey'>www.example.com</span>).</p>
                        <p className='mt-0'>For example:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Hostname: "</span> + location.hostname);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-4'>
                        <h4><span className='background-grey'>location.port</span></h4>
                        <p>It returns the port number used in the URL (e.g., <span className='background-grey'>80</span> for HTTP or <span className='background-grey'>443</span> for HTTPS).</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Port: "</span> + location.port);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-4'>
                        <h4><span className='background-grey'>location.pathname</span></h4>
                        <p>It returns the path of the URL (e.g., <span className='background-grey'>/about</span>).</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Pathname: "</span> + location.pathname);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-4'>
                        <h4><span className='background-grey'>location.search</span></h4>
                        <p>It returns the query string in the URL, including the <span className='background-grey'>?</span> (e.g., <span className='background-grey'>?id=123</span>).</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Query String: "</span> + location.search);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-4'>
                        <h4><span className='background-grey'>location.hash</span></h4>
                        <p>It return the fragment identifier in the URL, including the <span className='background-grey'>#</span> (e.g., <span className='background-grey'>#section1</span>).</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Hash: "</span> + location.hash);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-4'>
                        <h4><span className='background-grey'>location.origin</span></h4>
                        <p>It return the origin of the URL, which includes the protocol, hostname, and port.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Origin: "</span> + location.origin);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>Key Methods of the <span className='background-grey'>location</span> Object</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li className='operator mt-5'>
                        <h4><span className='background-grey'>location.assign()</span></h4>
                        <p>It loads a new document at the specified URL.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>location.<span class="color-red">assign</span>(<span class="color-green">"https://www.example.com"</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h4><span className='background-grey'>location.replace()</span></h4>
                        <p>It replaces the current document with a new one without saving the current page in the history.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>location.<span class="color-red">replace</span>(<span class="color-green">"https://www.example.com"</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h4><span className='background-grey'>location.reload()</span></h4>
                        <p>It reloads the current page. You can force a reload from the server by passing <span className='background-grey'>true</span>.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>location.<span class="color-red">reload</span>(); <span class="color-grey">// Normal reload</span></p>
                                <p>location.<span class="color-red">reload</span>(<span class="color-blue">true</span>); <span class="color-grey">// Force reload from the server</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

            </div>
        </section>
    )
}