import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/js-2017";

export default function Js2017() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("ECMAScript 2017 | Aspirant's Home");
        const urls = {
            'previous': '/javascript/js-2016',
            'next': '/javascript/js-2018'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>New Features in ECMAScript 2017</h3>
            <div className='mt-4 mb-5'>
                <p>These are the new features in ECMAScript 2017:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><a href="#string-padding">JavaScript <strong>String padding</strong></a></li>
                    <li><a href="#object-entries">JavaScript <strong>Object entries()</strong></a></li>
                    <li><a href="#object-values">JavaScript <strong>Object values()</strong></a></li>
                    <li><a href="#async-await">JavaScript <strong>async</strong> and <strong>await</strong></a></li>
                    <li><a href="#trailing-commas"><strong>Trailing Commas</strong> in Functions</a></li>
                    <li>JavaScript <strong>Object.getOwnPropertyDescriptors</strong></li>
                </ul>

                <div id="string-padding">
                    <h5 className='mt-5 mb-3'>JavaScript String Padding</h5>
                    <p>String padding adds characters to the beginning or end of a string to make it a certain length. There are two methods:</p>
                    <ul style={{ listStyle: 'decimal' }}>
                        <li><span className='background-grey'>padStart(targetLength, padString)</span>: Adds padding at the start.</li>
                        <li><span className='background-grey'>padEnd(targetLength, padString)</span>: Adds padding at the end.</li>
                    </ul>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-green">'123'</span>.<span class="color-red">padStart</span>(<span class="color-pink">5</span>, <span class="color-green">'0'</span>); <span class="color-grey">// Output : '00123'</span></p>
                            <p><span class="color-green">'123'</span>.<span class="color-red">padEnd</span>(<span class="color-pink">5</span>, <span class="color-green">'0'</span>); <span class="color-grey">// Output : '12300'</span></p>
                        `
                        }}></div>
                    </div>
                </div>

                <div id="object-entries">
                    <h5 className='mt-5 mb-3'>JavaScript Object Entries</h5>
                    <p>The <span className='background-grey'>Object.entries()</span> method returns an array of a given object's own enumerable string-keyed property <span className='background-grey'>[key, value]</span> pairs.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> person = { <span class="color-pink">name</span>: <span class="color-green">'Alice'</span>, <span class="color-pink">age</span>: <span class="color-pink">25</span> };</p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(Object.<span class="color-red">entries</span>(person));</p>
                            <p class="color-grey">// Output: [['name', 'Alice'], ['age', 25]]</p>
                            `
                        }}></div>
                    </div>
                    <p><span className='background-grey'>Object.entries()</span> makes it simple to use objects in loops:</p>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> fruits = {Bananas:<span class="color-pink">300</span>, Oranges:<span class="color-pink">200</span>, Apples:<span class="color-pink">500</span>};</p>
                            <br />
                            <p><span class="color-blue">let</span> text = <span class="color-green">""</span>;</p>
                            <p><span class="color-blue">for</span> (<span class="color-blue">let</span> [fruit, value] <span class="color-pink">of</span> Object.<span class="color-red">entries</span>(fruits)) {</p>
                            <p class="ml-30">    text += fruit + <span class="color-green">": "</span> + value + <span class="color-green">"<br>"</span>;</p>
                            <p>}</p>
                            `
                        }}></div>
                    </div>
                </div>
                
                <div id="object-values">
                    <h5 className='mt-5 mb-3'>JavaScript Object Values</h5>
                    <p>The <span className='background-grey'>Object.values()</span> method returns an array of a given object's <strong>own enumerable property values</strong>.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> person = { <span class="color-pink">name</span>: <span class="color-green">'Alice'</span>, <span class="color-pink">age</span>: <span class="color-pink">25</span> };</p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(Object.<span class="color-red">values</span>(person));</p>
                            <p class="color-grey">// Output: ['Alice', 25]</p>
                            `
                        }}></div>
                    </div>
                </div>

                <div id="async-await">
                    <h5 className='mt-5 mb-3'>JavaScript Async & Await Functions</h5>
                    <p><span className='background-grey'>async</span> functions are a way to work with asynchronous code in JavaScript. They make it easier to write and manage promises and asynchronous operations, improving readability.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-pink">async</span> <span class="color-blue">function</span> <span class="color-red">fetchData</span>() {</p>
                            <p class="ml-30">    <span class="color-blue">let</span> data = <span class="color-blue">await</span> <span class="color-red">fetch</span>(<span class="color-green">'https://jsonplaceholder.typicode.com/posts'</span>);</p>
                            <p class="ml-30">    <span class="color-blue">let</span> json = <span class="color-blue">await</span> data.<span class="color-red">json</span>();</p>
                            <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(json);</p>
                            <p>}</p>
                            <br />  
                            <p><span class="color-red">fetchData</span>();</p>
                            `
                        }}></div>
                    </div>
                    <p>The <span className='background-grey'>async</span> function automatically returns a promise that resolves to the function's return value.</p>
                    <p><span className='background-grey'>await</span> pauses the function execution until the <span className='background-grey'>fetch</span> request is complete, then the response is processed.</p>
                </div>

                <div id="trailing-commas">
                    <h5 className='mt-5 mb-3'>JavaScript Trailing Commas In Functions</h5>
                    <p>Trailing commas in <strong>function parameters</strong> allow you to add a comma after the last parameter in the function declaration or call. This feature improves code readability and simplifies editing, especially when adding or removing parameters.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">function</span> <span class="color-red">greet</span>(name, age,) {</p>
                            <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">&#96;Hello $&#123;name&#125;, you are $&#123;age&#125; years old.&#96;</span>);</p>
                            <p>}</p>
                            <br />  
                            <p><span class="color-red">greet</span>(<span class="color-green">'Alice'</span>, <span class="color-pink">25</span>,);</p>
                            <p class="color-grey">// Output: Hello Alice, you are 25 years old.</p>
                            `
                        }}></div>
                    </div>
                </div>
            </div>
        </section>
    )
}