import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/handling-responses";

export default function HandlingResponse() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Handling Responses in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/making-http-requests',
            'next': '/javascript/working-with-json'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Handling Responses</h3>
            <div className='mt-4 mb-5'>
                <p>
                    When making HTTP requests in JavaScript, it's important to understand how to handle responses effectively. This ensures your application processes data correctly and manages errors gracefully.
                </p>

                <h5 className='mt-5 mb-3'>What Is a Response?</h5>
                <p>A response is the data sent back by the server after your application makes an HTTP request. It contains:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>Status Code</strong>: Indicates the success or failure of the request (e.g., 200 for success, 404 for not found).</li>
                    <li><strong>Headers</strong>: Provide metadata about the response (e.g., content type).</li>
                    <li><strong>Body</strong>: Contains the actual data (e.g., JSON, plain text, or HTML).</li>
                </ul>

                <h5 className='mt-5 mb-3'>Response Status</h5>
                <p>The status code helps determine whether the request was successful:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>200-299</strong>: Success (e.g., 200 OK, 201 Created).</li>
                    <li><strong>400-499</strong>: Client errors (e.g., 404 Not Found, 401 Unauthorized).</li>
                    <li><strong>500-599</strong>: Server errors (e.g., 500 Internal Server Error).</li>
                </ul>


                <h5 className='mt-5 mb-3'>Handling Responses with Fetch API</h5>
                <p>The Fetch API provides a modern and straightforward way to make HTTP requests and handle responses.</p>

                <h6 className='mt-3'>Steps to Handle Responses:</h6>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Check the Status</strong>: Use <span className='background-grey'>response.ok</span> or manually verify the response.status.</li>
                    <li><strong>Parse the Body</strong>: Use methods like <span className='background-grey'>response.json()</span> for JSON, <span className='background-grey'>response.text()</span> for text, or <span className='background-grey'>response.blob()</span> for binary data.</li>
                </ul>

                <h6>Example:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">fetch</span>(<span class="color-green">"https://jsonplaceholder.typicode.com/posts"</span>)</p>
                        <p>.<span class="color-red">then</span>(response => {</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (response.<span class="color-pink">ok</span>) {</p>
                        <p class="ml-60">        <span class="color-pink">return</span> response.<span class="color-red">json</span>(); <span class="color-grey">// Parse JSON</span></p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">throw new</span> <span class="color-red">Error</span>(<span class="color-green">&#96;HTTP Error: $&#123;response.status&#125;&#96;</span>);</p>
                        <p class="ml-30">    }</p>
                        <p>})</p>
                        <p>.<span class="color-red">then</span>(data => <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Fetched Data:"</span>, data))</p>
                        <p>.<span class="color-red">catch</span>(error => <span class="color-pink">console</span>.<span class="color-red">error</span>(<span class="color-green">"Fetch Error:"</span>, error));</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Handling Responses with XMLHttpRequest</h5>
                <p>The <span className='background-grey'>XMLHttpRequest</span> object is an older method to handle HTTP requests. While it's not as clean as Fetch, it is still used in some legacy systems.</p>

                <h6 className='mt-3'>Steps to Handle Responses:</h6>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Monitor Ready States</strong>: Check if <span className='background-grey'>readyState === 4</span> (request completed).</li>
                    <li><strong>Verify Status</strong>: Ensure <span className='background-grey'>status === 200</span> (successful response).</li>
                    <li><strong>Parse the Response</strong>: Use <span className='background-grey'>JSON.parse(xhr.responseText)</span> for JSON or access <span className='background-grey'>xhr.responseText</span> for text.</li>
                </ul>

                <h6>Example:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">const</span> xhr = <span class="color-blue">new</span> <span class="color-red">XMLHttpRequest</span>();</p>
                        <p>xhr.<span class="color-red">open</span>(<span class="color-green">"GET"</span>, <span class="color-green">"https://jsonplaceholder.typicode.com/posts"</span>, <span class="color-blue">true</span>);</p>
                        <p>xhr.<span class="color-pink">onreadystatechange</span> = <span class="color-red">function</span> () {</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (xhr.<span class="color-pink">readyState</span> === <span class="color-pink">4</span>) { <span class="color-grey">// Request completed</span></p>
                        <p class="ml-60">        <span class="color-blue">if</span> (xhr.<span class="color-pink">status</span> === <span class="color-pink">200</span>) { <span class="color-grey">// Success</span></p>
                        <p class="ml-90">            <span class="color-blue">const</span> data = JSON.<span class="color-red">parse</span>(xhr.<span class="color-pink">responseText</span>);
                        <p class="ml-90">            <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"XHR Data:"</span>, data);</p>
                        <p class="ml-60">        } <span class="color-blue">else</span> {</p>
                        <p class="ml-90">            <span class="color-pink">console</span>.<span class="color-red">error</span>(<span class="color-green">&#96;XHR Error: $&#123;xhr.status&#125;&#96;</span>);</p>
                        <p class="ml-60">        }</p>
                        <p class="ml-30">    }</p>
                        <p>};</p>
                        <p>xhr.<span class="color-red">send</span>();</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Handling Responses with Axios</h5>
                <p>Axios is a promise-based HTTP client that simplifies response handling. It automatically parses JSON and provides response metadata like <span className='background-grey'>status</span> and <span className='background-grey'>headers</span>.</p>

                <h6 className='mt-3'>Steps to Handle Responses:</h6>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Access Data</strong>: Use <span className='background-grey'>response.data</span> for parsed data.</li>
                    <li><strong>Check Status</strong>: Use <span className='background-grey'>response.status</span> to ensure the request succeeded.</li>
                    <li><strong>Handle Errors</strong>: Use <span className='background-grey'>.catch()</span> to manage errors.</li>
                </ul>

                <h6>Example:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>axios.<span class="color-red">get</span>(<span class="color-green">"https://jsonplaceholder.typicode.com/posts"</span>)</p>
                        <p>.<span class="color-red">then</span>(response => {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Axios Data:"</span>, response.<span class="color-pink">data</span>); <span class="color-grey">// Parsed JSON data</span></p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Axios Status:"</span>, response.<span class="color-pink">status</span>); <span class="color-grey">// Status code</span></p>
                        <p>})</p>
                        <p>.<span class="color-red">catch</span>(error => {</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (error.<span class="color-pink">response</span>) {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">error</span>(<span class="color-green">"Axios Error Status:"</span>, error.response.status);</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">error</span>(<span class="color-green">"Axios Error Data:"</span>, error.response.data);</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">error</span>(<span class="color-green">"Axios Error Message:"</span>, error.message);</p>
                        <p class="ml-30">    }</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>


            </div>
        </section>
    )
}