import React, { useEffect, useState, useRef } from 'react'
import { Outlet, Link } from "react-router-dom";

const ExcerciseMenu = ({pathname, setPathName}) => {

    return (
        <>
            <div className='d-flex align-items-center headerMenu headerMainMenu sticky-top mb-4' style={{ 'height': '80px' }}>
                <div>
                    <h2>Assignment</h2>
                </div>
            </div>
            <nav>
                <ul>
                    <li className={pathname == '/excercise/node-js' ? 'activeMenu' : ''}>
                        <Link to="/excercise/node-js" onClick={() => { setPathName('/excercise/node-js'); } }>NodeJs</Link>
                    </li>
                    <li className={pathname == '/excercise/mongo-db' ? 'activeMenu' : ''}>
                        <Link to="/excercise/mongo-db" onClick={() => { setPathName('/excercise/mongo-db'); } }>MongoDB</Link>
                    </li>
                    <li className={pathname == '/excercise/angular' ? 'activeMenu' : ''}>
                        <Link to="/excercise/angular" onClick={() => { setPathName('/excercise/angular'); } }>Angular</Link>
                    </li>
                    <li className={pathname == '/excercise/react-js' ? 'activeMenu' : ''}>
                        <Link to="/excercise/react-js" onClick={() => { setPathName('/excercise/react-js'); } }>ReactJS</Link>
                    </li>
                    <li className={pathname == '/excercise/javascript' ? 'activeMenu' : ''}>
                        <Link to="/excercise/javascript" onClick={() => { setPathName('/excercise/javascript'); } }>Javascript</Link>
                    </li>
                    <li className={pathname == '/excercise/html' ? 'activeMenu' : ''}>
                        <Link to="/excercise/html" onClick={() => { setPathName('/excercise/html'); } }>HTML</Link>
                    </li>
                    <li className={pathname == '/excercise/css' ? 'activeMenu' : ''}>
                        <Link to="/excercise/css" onClick={() => { setPathName('/excercise/css'); } }>CSS</Link>
                    </li>
                    <li className={pathname == '/excercise/mysql' ? 'activeMenu' : ''}>
                        <Link to="/excercise/mysql" onClick={() => { setPathName('/excercise/mysql'); } }>Mysql</Link>
                    </li>
                    <li className={pathname == '/excercise/php' ? 'activeMenu' : ''}>
                        <Link to="/excercise/php" onClick={() => { setPathName('/excercise/php'); } }>PHP</Link>
                    </li>
                    <li className={pathname == '/excercise/jquery' ? 'activeMenu' : ''}>
                        <Link to="/excercise/jquery" onClick={() => { setPathName('/excercise/jquery'); } }>JQuery</Link>
                    </li>
                    {/* <li className={pathname == '/quiz/next-js' ? 'activeMenu' : ''}>
                        <Link to="/quiz/next-js" onClick={() => { setPathName('/quiz/next-js'); } }>Next Js</Link>
                    </li> */}
                        
                </ul>
            </nav>
        </>
    );
};

export default ExcerciseMenu;