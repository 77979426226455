import React, { useState, useRef, useEffect } from 'react';

const highlightHtmlTags = (html) => {
    return html.replace(/(<\/?)([a-zA-Z0-9-]+)([^>]*?)(\/?>)/gi, (match, open, tag, attributes, close) => { 
        let highlighted = `<span class="html-tag">${ open == '</' ? '&lt;&#47;' : open}<span class="html-tag-name">${tag}</span></span>`;
      
        if (attributes) {
            highlighted += attributes.replace(
                /([a-zA-Z0-9-]+)=("[^"]*"|'[^']*')/gi,
                '<span class="html-attribute">$1</span>=<span class="html-attribute-value">$2</span>'
            );
        }
  
        highlighted += `<span class="html-tag">${close}</span>`;
        return highlighted;
    });
};
  

// The CodeEditor component allows users to input HTML, CSS, and JS
const CodeEditor = ({ value, setWrittenHtml, onClick, fileName, runCallback }) => {
    const contentEditableRef = useRef(null);
    const [code, setCode] = useState(value); // Local state for loaded code
    const placeholderText = "Write your code here...";

    

    const handleInput = () => {
        const content = contentEditableRef.current.innerText; // Get the raw HTML content
        setWrittenHtml(content);  // Update the preview content with raw HTML
    };

    useEffect(() => {
        if (contentEditableRef.current) {
            contentEditableRef.current.innerHTML = value; // Sync editor content with the parent value
            onClick()
        }
    }, [value]); // Trigger when the value prop changes

    return (
        <div style={{ marginTop: '20px' }}>
            <div class="aspirant-bar " style={{ 'overflow': 'auto' }}>
                <h3 style={{ marginBottom: '30px' }}>TryIt
                    <span className='float-end'>
                        <a href="https://aspirantshome.com/" target="_blank" title="Aspirant's Home" class="btn btn-success runButton"><i class="fa-solid fa-house"></i></a>
                        <a href="javascript:void(0);" class="btn btn-success runButton" onClick={onClick}>Run ❯</a>
                    </span>
                </h3>
            </div>

            <div
                contentEditable
                style={{
                    width: '100%',
                    height: 'calc(100vh - 300px)',
                    border: '1px solid rgb(149 141 141)',
                    padding: '10px',
                    whiteSpace: 'pre-line',
                    fontFamily: 'monospace',
                    overflow: 'scroll',
                    textAlign: 'left', // Ensure text is left-aligned
                    boxShadow: '-8px 8px 0px rgb(133 125 173)',
                    background: '#FFF'
                }}
                ref={contentEditableRef}
                onInput={handleInput}  // Update input when content changes
                data-placeholder={placeholderText}
            >  
            </div>
        </div>
    );
};

// The Preview component renders the combined output of HTML, CSS, and JS
const Preview = ({ html, css, js }) => {
    const [iframeLoaded, setIframeLoaded] = useState(false);

    // Combine HTML, CSS, and JS into a single document for the iframe
    const generatePreview = () => {
        return `
            <!DOCTYPE html>
            <html lang="en">
                <head>
                    <meta charset="UTF-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                </head>
                <body>
                    ${html}
                </body>
            </html>
        `;
    };

    return (
        <div style={{ marginTop: '20px' }}>
            <h3 style={{marginBottom: '30px'}}>Output</h3>
            <iframe
                title="preview"
                style={{
                    width: '100%',
                    height: 'calc(100vh - 300px)',
                    border: '1px solid rgb(149 141 141)',
                    boxShadow: '-8px 8px 0px rgb(133 125 173)',
                    background: '#FFF'
                }}
                srcDoc={generatePreview()}
                onLoad={() => setIframeLoaded(true)}
            />
            {!iframeLoaded && <div>Loading preview...</div>}
        </div>
    );
};

const OnlineEditor = () => {
    
    const [html, setHtml] = useState("");
    const [previewHtml, setPreviewHtml] = useState('');
    const [writtenHtml, setWrittenHtml] = useState('');
    const queryParameter = window.location.search;
    const params = new URLSearchParams(queryParameter);
    const fileName = params.get('filename');
    

    // This function will update the preview when the "Run" button is clicked
    const handleRunClick = () => {

        setPreviewHtml(writtenHtml);  // Set the preview content to the current html content
        const highlightedHtml = highlightHtmlTags(writtenHtml);
        setHtml(highlightedHtml)
    };

    // Fetch the external file only when fileName changes
    useEffect(() => {
        const fetchCode = async () => {
            try {
                const module = await import(`./Examples/${fileName}.js`);
                setHtml(module.default.trim()); // Set the initial value of the editor
                setWrittenHtml(module.default.trim()); // Set the raw HTML for preview
                //handleRunClick()
            } catch (err) {
                console.error('Error loading file:', err);
            }
        };

        if (fileName) fetchCode();
    }, [fileName]);


    return (
        <div style={{ padding: '20px', background: '#e5e3e3' }}>
            <div className='row'>
                <div className='offset-2 col-8'>
                    <div className='headerAdSpace'>
                        <img className='img-fluid' src='https://aspirantshome.com/slide1.png' />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-6'>
                    {/* HTML, CSS, and JS Editors */}
                    <CodeEditor value={html} setWrittenHtml={setWrittenHtml} onClick={handleRunClick} />
                </div>
                <div className='col-6'>
                    {/* Preview Area */}
                    <Preview html={previewHtml} />
                </div>
            </div>
        </div>
    );
};

export default OnlineEditor;
