import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/form-validation";

export default function FormValidation() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Form Validation in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/dialog-boxes',
            'next': '/javascript/submitting-forms'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Form Validation</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Form validation is the process of checking whether the data entered by a user in a form is correct and complete before sending it to the server. Think of it as a friendly checkpoint to ensure everything is in order. JavaScript allows us to validate forms directly in the browser, providing instant feedback to the user.
                </p>

                <h5 className='mt-5 mb-3'>How JavaScript Handles Form Validation</h5>
                <p>JavaScript can:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Check if fields are empty.</li>
                    <li>Verify if the entered data matches a specific pattern (like an email format).</li>
                    <li>Ensure input meets specific conditions (like minimum length for a password).</li>
                </ul>

                <h5 className='mt-5 mb-3'>Some Example of Form Validation in JavaScript</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li className='operator mt-5'>
                        <h4>Check if a Field is Empty</h4>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;form <span class="color-pink">id</span>=<span class="color-green">"simpleForm"</span>&gt;</p>
                                <p class="ml-30">    &lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">id</span>=<span class="color-green">"username"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Enter username"</span>&gt;</p>
                                <p class="ml-30">    &lt;button <span class="color-pink">type</span>=<span class="color-green">"submit"</span>&gt;Submit&lt;/button&gt;</p>
                                <p class="ml-30">    &lt;p <span class="color-pink">id</span>=<span class="color-green">"error"</span> <span class="color-pink">style</span>=<span class="color-green">"color: red;"</span>&gt;&lt;/p&gt;</p>
                                <p>&lt;/form&gt;</p>
                                <br />
                                <p>&lt;script&gt;</p>
                                <p class="ml-30">    document.<span class="color-red">getElementById</span>(<span class="color-green">"simpleForm"</span>).<span class="color-red">addEventListener</span>(<span class="color-green">"submit"</span>, <span class="color-red">function</span> (e) {</p>
                                <p class="ml-60">        e.<span class="color-red">preventDefault</span>();</p>
                                <p class="ml-60">        <span class="color-blue">const</span> username = document.<span class="color-red">getElementById</span>(<span class="color-green">"username"</span>).value.<span class="color-red">trim</span>();</p>
                                <p class="ml-60">        <span class="color-blue">const</span> error = document.<span class="color-red">getElementById</span>(<span class="color-green">"error"</span>);</p>
                                <br />        
                                <p class="ml-60">        <span class="color-blue">if</span> (username === <span class="color-green">""</span>) {</p>
                                <p class="ml-90">            error.textContent = <span class="color-green">"Username cannot be empty!"</span>;</p>
                                <p class="ml-60">        } <span class="color-blue">else</span> {</p>
                                <p class="ml-90">            error.textContent = <span class="color-green">""</span>;</p>
                                <p class="ml-90">            <span class="color-red">alert</span>(<span class="color-green">"Form submitted!"</span>);</p>
                                <p class="ml-60">        }</p>
                                <p class="ml-30">    });</p>
                                <p>&lt;/script&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h4>Validate Email Format</h4>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;form <span class="color-pink">id</span>=<span class="color-green">"emailForm"</span>&gt;</p>
                                <p class="ml-30">    &lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">id</span>=<span class="color-green">"email"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Enter email"</span>&gt;</p>
                                <p class="ml-30">    &lt;button <span class="color-pink">type</span>=<span class="color-green">"submit"</span>&gt;Submit&lt;/button&gt;</p>
                                <p class="ml-30">    &lt;p <span class="color-pink">id</span>=<span class="color-green">"emailError"</span> <span class="color-pink">style</span>=<span class="color-green">"color: red;"</span>&gt;&lt;/p&gt;</p>
                                <p>&lt;/form&gt;</p>
                                <br />
                                <p>&lt;script&gt;</p>
                                <p class="ml-30">    document.<span class="color-red">getElementById</span>(<span class="color-green">"emailForm"</span>).<span class="color-red">addEventListener</span>(<span class="color-green">"submit"</span>, <span class="color-red">function</span> (e) {</p>
                                <p class="ml-60">        e.<span class="color-red">preventDefault</span>();</p>
                                <p class="ml-60">        <span class="color-blue">const</span> email = document.<span class="color-red">getElementById</span>(<span class="color-green">"email"</span>).value.<span class="color-red">trim</span>();</p>
                                <p class="ml-60">        <span class="color-blue">const</span> emailError = document.<span class="color-pink">getElementById</span>(<span class="color-green">"emailError"</span>);</p>
                                <p class="ml-60">        <span class="color-blue">const</span> emailPattern = <span class="color-green">/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/</span>;</p>
                                <br />
                                <p class="ml-60">        <span class="color-blue">if</span> (<span class="color-pink">!</span>emailPattern.<span class="color-red">test</span>(email)) {</p>
                                <p class="ml-90">            emailError.<span class="color-pink">textContent</span> = <span class="color-green">"Invalid email address!"</span>;</p>
                                <p class="ml-60">        } <span class="color-blue">else</span> {</p>
                                <p class="ml-90">            emailError.<span class="color-pink">textContent</span> = <span class="color-green">""</span>;</p>
                                <p class="ml-90">            <span class="color-red">alert</span>(<span class="color-green">"Email is valid!"</span>);</p>
                                <p class="ml-60">        }</p>
                                <p class="ml-30">    });</p>
                                <p>&lt;/script&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h4>Password Length Validation</h4>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;form <span class="color-pink">id</span>=<span class="color-green">"passwordForm"</span>&gt;</p>
                                <p class="ml-30">    &lt;input <span class="color-pink">type</span>=<span class="color-green">"password"</span> <span class="color-pink">id</span>=<span class="color-green">"password"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Enter password"</span>&gt;</p>
                                <p class="ml-30">    &lt;button <span class="color-pink">type</span>=<span class="color-green">"submit"</span>&gt;Submit&lt;/button&gt;</p>
                                <p class="ml-30">    &lt;p <span class="color-pink">id</span>=<span class="color-green">"passwordError"</span> <span class="color-pink">style</span>=<span class="color-green">"color: red;"</span>&gt;&lt;/p&gt;</p>
                                <p>&lt;/form&gt;</p>
                                <br />
                                <p>&lt;script&gt;</p>
                                <p class="ml-30">    document.<span class="color-red">getElementById</span>(<span class="color-green">"passwordForm"</span>).<span class="color-red">addEventListener</span>(<span class="color-green">"submit"</span>, <span class="color-red">function</span> (e) {</p>
                                <p class="ml-60">        e.<span class="color-red">preventDefault</span>();</p>
                                <p class="ml-60">        <span class="color-blue">const</span> password = document.<span class="color-red">getElementById</span>(<span class="color-green">"password"</span>).value;</p>
                                <p class="ml-60">        <span class="color-blue">const</span> passwordError = document.<span class="color-red">getElementById</span>(<span class="color-green">"passwordError"</span>);</p>
                                <br />
                                <p class="ml-60">        <span class="color-blue">if</span> (password.length < <span class="color-pink">6</span>) {</p>
                                <p class="ml-90">            passwordError.<span class="color-pink">textContent</span> = <span class="color-green">"Password must be at least 6 characters long!"</span>;</p>
                                <p class="ml-60">        } <span class="color-blue">else</span> {</p>
                                <p class="ml-90">            passwordError.<span class="color-pink">textContent</span> = <span class="color-green">""</span>;</p>
                                <p class="ml-90">            <span class="color-red">alert</span>(<span class="color-green">"Password is valid!"</span>);</p>
                                <p class="ml-60">        }</p>
                                <p class="ml-30">    });</p>
                                <p>&lt;/script&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h4>Checkbox Validation</h4>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;form <span class="color-pink">id</span>=<span class="color-green">"checkboxForm"</span>&gt;</p>
                                <p class="ml-30">    &lt;input <span class="color-pink">type</span>=<span class="color-green">"checkbox"</span> <span class="color-pink">id</span>=<span class="color-green">"agree"</span>&gt; I agree to the terms and conditions</p>
                                <p class="ml-30">    &lt;button <span class="color-pink">type</span>=<span class="color-green">"submit"</span>&gt;Submit&lt;/button&gt;</p>
                                <p class="ml-30">    &lt;p <span class="color-pink">id</span>=<span class="color-green">"checkboxError"</span> <span class="color-pink">style</span>=<span class="color-green">"color: red;"</span>&gt;&lt;/p&gt;</p>
                                <p>&lt;/form&gt;</p>
                                <br />
                                <p>&lt;script&gt;</p>
                                <p class="ml-30">    document.<span class="color-red">getElementById</span>(<span class="color-green">"checkboxForm"</span>).<span class="color-red">addEventListener</span>(<span class="color-green">"submit"</span>, <span class="color-blue">function</span> (e) {</p>
                                <p class="ml-60">        e.<span class="color-red">preventDefault</span>();</p>
                                <p class="ml-60">        <span class="color-pink">const</span> agree = document.<span class="color-red">getElementById</span>(<span class="color-green">"agree"</span>).checked;</p>
                                <p class="ml-60">        <span class="color-blue">const</span> error = document.<span class="color-red">getElementById</span>(<span class="color-green">"checkboxError"</span>);</p>
                                <br />
                                <p class="ml-60">        <span class="color-blue">if</span> (<span class="color-pink">!</span>agree) {</p>
                                <p class="ml-90">            error.<span class="color-pink">textContent</span> = <span class="color-green">"You must agree to the terms and conditions!"</span>;</p>
                                <p class="ml-60">        } <span class="color-blue">else</span> {</p>
                                <p class="ml-90">            error.<span class="color-pink">textContent</span> = <span class="color-green">""</span>;</p>
                                <p class="ml-90">            <span class="color-red">alert</span>(<span class="color-green">"Thank you for agreeing!"</span>);</p>
                                <p class="ml-60">        }</p>
                                <p class="ml-30">    });</p>
                                <p>&lt;/script&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


            </div>
        </section>
    )
}