import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/css-files";


export default function CSSFiles() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Styling - Using Vanilla CSS");
        const urls = {
            'previous': '/react-js/styling',
            'next':'/react-js/inline-styles'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>React Js - Styling Using Vanilla CSS</h3>
            <p>
                When building user interfaces with React, one of the simplest and most versatile ways to style your components is by using vanilla CSS. While modern CSS-in-JS libraries like Styled-Components or Tailwind CSS offer exciting features, vanilla CSS remains a reliable, familiar option for styling web applications. This article explores how to integrate and use vanilla CSS effectively in your React project.
            </p>

            <h5 className='mt-5 mb-3'>What is Vanilla CSS?</h5>
            <p>
                Vanilla CSS refers to standard CSS files written without any pre-processors, frameworks, or CSS-in-JS tools. It’s the purest form of CSS used to define styles for your HTML elements. In the context of React, vanilla CSS can be used to style individual components or the entire application.
            </p>

            <h5 className='mt-5 mb-4'>Setting Up Vanilla CSS in React</h5>

            <h6 className='mt-3'>1. Adding a CSS File</h6>
            <ul style={{ listStyle: 'decimal' }}>
                <li>Create a .css file (e.g., App.css).</li>
                <li>Write your CSS rules inside this file:
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p>.container {</p>
                            <p class="ml-30">    background-color: <span class="color-pink">#f0f0f0;</span></p>
                            <p class="ml-30">    padding: <span class="color-pink">20px;</span></p>
                            <p class="ml-30">    border-radius: <span class="color-pink">5px;</span></p>
                            <p>}</p>
                            <br />
                            <p>.heading {</p>
                            <p class="ml-30">    font-size: <span class="color-pink">24px;</span></p>
                            <p class="ml-30">    color: <span class="color-pink">#333;</span></p>
                            <p>}</p>
                            `
                        }}></div>
                    </div>
                </li>
                <li>Import the CSS file into your React component:
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-pink">import</span> <span class="color-green">'./App.css'</span>;</p>
                            <br />
                            <p><span class="color-blue">function</span> <span class="color-red">App</span>() {</p>
                            <p class="ml-30">    <span class="color-blue">return</span> (</p>
                            <p class="ml-60">        &lt;div <span class="color-pink">className</span>=<span class="color-green">"container"</span>&gt;</p>
                            <p class="ml-90">            &lt;h1 <span class="color-pink">className</span>=<span class="color-green">"heading"</span>&gt;Welcome to React&lt;/h1&gt;</p>
                            <p class="ml-60">        &lt;/div&gt;</p>
                            <p class="ml-30">    );</p>
                            <p>}</p>
                            <br />
                            <p><span class="color-blue">export default</span> <span class="color-red">App</span>;</p>
                            `
                        }}></div>
                    </div>
                </li>
            </ul>

            <h6 className='mt-3'>2. Organizing CSS Files</h6>
            <p>For larger projects, organize CSS files to align with your component structure:</p>
            <ul style={{ listStyle: 'disc' }}>
                <li>Create separate CSS files for each component.</li>
                <li>Import the specific CSS file into the corresponding component.
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-pink">import</span> <span class="color-green">'./Button.css'</span>;</p>
                            `
                        }}></div>
                    </div>
                </li>
            </ul>


            <h5 className='mt-5 mb-3'>Advantages of Using Vanilla CSS in React</h5>
            <ul style={{ listStyle: 'decimal' }}>
                <li><strong>Simplicity</strong>: Vanilla CSS requires no additional libraries or configuration.</li>
                <li><strong>Performance</strong>: Browser-native CSS parsing is optimized for performance.</li>
                <li><strong>Flexibility</strong>: Easy to apply global or component-specific styles.</li>
                <li><strong>Familiarity</strong>: Developers familiar with web development know how to use CSS.</li>
            </ul>

            <h5 className='mt-5 mb-4'>Best Practices for Using Vanilla CSS in React</h5>

            <h6 className='mt-3'>1. Use Class Names Over Inline Styles</h6>
            <p>While React supports inline styles, they lack features like pseudo-classes and media queries. Use class names for flexibility:</p>
            <div className='codePalateBox mt-2 mb-4'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `
                    <p>.button {</p>
                    <p class="ml-30">    padding: <span class="color-pink">10px 20px;</span></p>
                    <p class="ml-30">    background-color: blue;</p>
                    <p class="ml-30">    color: white;</p>
                    <p class="ml-30">    border: none;</p>
                    <p class="ml-30">    border-radius: <span class="color-pink">4px;</span></p>
                    <p class="ml-30">    cursor: pointer;</p>
                    <p>}</p>
                    <br />
                    <p>.button<span class="color-pink">:hover</span> {</p>
                    <p class="ml-30">    background-color: darkblue;</p>
                    <p>}</p>
                    <br />
                    <p><span class="color-blue">function</span> <span class="color-red">Button</span>() {</p>
                    <p class="ml-30">    <span class="color-blue">return</span> &lt;button <span class="color-pink">className</span>=<span class="color-green">"button"</span>&gt;Click Me&lt;/button&gt;;</p>
                    <p>}</p>
                    `
                }}></div>
            </div>

            <h6 className='mt-3'>2. Leverage CSS Modules for Scoped Styles</h6>
            <p>To avoid class name conflicts, use CSS Modules, which scope styles to individual components. To enable CSS Modules:</p>
            <ul style={{ listStyle: 'decimal' }}>
                <li>Rename the file to ComponentName.module.css.</li>
                <li>Import styles as an object in your component:
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">import</span> styles <span class="color-pink">from</span> <span class="color-green">'./Button.module.css'</span>;</p>
                            <br />
                            <p><span class="color-blue">function</span> <span class="color-red">Button</span>() {</p>
                            <p class="ml-30">    <span class="color-blue">return</span> &lt;button <span class="color-pink">className</span>={styles<span class="color-pink">.button</span>}&gt;Click Me&lt;/button&gt;;</p>
                            <p>}</p>
                            `
                        }}></div>
                    </div>
                </li>
                <li>Define styles in Button.module.css:
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p>.button {</p>
                            <p class="ml-30">    padding: <span class="color-pink">10px 20px;</span></p>
                            <p class="ml-30">    background-color: blue;</p>
                            <p class="ml-30">    color: white;</p>
                            <p>}</p>
                            `
                        }}></div>
                    </div>
                </li>
            </ul>

            <h6 className='mt-3'>3. Maintain a Consistent Naming Convention</h6>
            <p>Adopt a naming convention such as BEM (Block Element Modifier) for consistency:</p>
            <div className='codePalateBox mt-2 mb-4'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `
                    <p>.card {</p>
                    <p class="ml-30">    border: <span class="color-pink">1px</span> solid <span class="color-pink">#ddd</span>;</p>
                    <p class="ml-30">    padding: <span class="color-pink">16px</span>;</p>
                    <p>}</p>
                    <br />
                    <p>.card__title {</p>
                    <p class="ml-30">    font-size: <span class="color-pink">18px</span>;</p>
                    <p class="ml-30">    margin-bottom: <span class="color-pink">8px</span>;</p>
                    <p>}</p>
                    `
                }}></div>
            </div>


            <h6 className='mt-3'>4. Use Variables for Reusability</h6>
            <p>Define CSS variables for consistent styling across the app:</p>
            <div className='codePalateBox mt-2 mb-4'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `
                    <p>:root {</p>
                    <p class="ml-30">    --primary-color: <span class="color-pink">#007bff</span>;</p>
                    <p class="ml-30">    --font-size-large: <span class="color-pink">18px</span>;</p>
                    <p>}</p>
                    <br />
                    <p>.button {</p>
                    <p class="ml-30">    background-color: var(<span class="color-blue">--primary-color</span>);</p>
                    <p class="ml-30">    font-size: var(<span class="color-blue">--font-size-large</span>);</p>
                    <p>}</p>
                    `
                }}></div>
            </div>


            <h5 className='mt-5 mb-4'>Common Challenges and Solutions</h5>

            <h6 className='mt-3'>1. Global Styles Overriding Component Styles</h6>
            <p className='mb-0'>Global styles in vanilla CSS can unintentionally override component-specific styles. To mitigate this:</p>
            <ul style={{ listStyle: 'disc' }}>
                <li>Use scoped styles with CSS Modules.</li>
                <li>Adopt a strict naming convention.</li>
            </ul>

            <h6 className='mt-3'>2. Managing Styles in Large Projects</h6>
            <p>Break styles into smaller files and organize them by feature or component. For example:</p>
            <div className='codePalateBox mt-2 mb-4'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `
                    <p>/src</p>
                    <p class="ml-30">/components</p>
                    <p class="ml-60">  /Button</p>
                    <p class="ml-90">    Button.jsx</p>
                    <p class="ml-90">    Button.css</p>
                    <p class="ml-60">  /Card</p>
                    <p class="ml-90">    Card.jsx</p>
                    <p class="ml-90">    Card.css</p>
                    `
                }}></div>
            </div>

            <h5 className='mt-5 mb-4'>FAQs About Styling with Vanilla CSS in React</h5>
            <h6 className='mt-3'>1. Can I use CSS Variables with React?</h6>
            <p>Yes, CSS variables (custom properties) can be defined in a global stylesheet or inline using the <span className='background-grey'>style</span> attribute. They’re great for theming and reusable values</p>

            <h6 className='mt-3'>2. How do I apply conditional styles?</h6>
            <p>Use JavaScript to dynamically assign class names based on conditions:</p>
            <div className='codePalateBox mt-2 mb-4'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `
                    <p><span class="color-blue">function</span> <span class="color-red">Button</span>({ isPrimary }) {</p>
                    <p class="ml-30">    <span class="color-blue">const</span> className = isPrimary ? <span class="color-green">'button primary'</span> : <span class="color-green">'button secondary'</span>;</p>
                    <p class="ml-30">    <span class="color-blue">return</span> &lt;button <span class="color-pink">className</span>={className}&gt;Click Me&lt;/button&gt;;</p>
                    <p>}</p>
                    `
                }}></div>
            </div>

            <h6 className='mt-3'>3. Is CSS-in-JS better than vanilla CSS?</h6>
            <p>It depends on the project. CSS-in-JS offers scoped styles and dynamic theming but adds overhead. Vanilla CSS is lightweight and sufficient for many projects.</p>

            <h6 className='mt-3'>4. Can I use pseudo-classes and media queries in vanilla CSS with React?</h6>
            <p>Yes, vanilla CSS fully supports pseudo-classes and media queries. Define them in your CSS files:</p>
            <div className='codePalateBox mt-2 mb-4'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `
                    <p>.button<span class="color-pink">:hover</span> {</p>
                    <p class="ml-30">    background-color: darkblue;</p>
                    <p>}</p>
                    <br />
                    <p><span class="color-pink">@media</span> (max-width: <span class="color-pink">768px</span>) {</p>
                    <p class="ml-30">    .container {</p>
                    <p class="ml-60">        padding: <span class="color-pink">10px</span>;</p>
                    <p class="ml-30">    }</p>
                    <p>}</p>
                    `
                }}></div>
            </div>

            <h6 className='mt-3'>5. How do I debug CSS in React applications?</h6>
            <p>Use browser developer tools to inspect elements and identify applied styles. React components’ DOM structure is easily accessible in these tools.</p>

            <h5 className='mt-5 mb-4'>Conclusion</h5>
            <p>
                Vanilla CSS remains a robust and straightforward way to style React applications. By organizing CSS files effectively, adhering to best practices, and leveraging techniques like CSS Modules and variables, you can build scalable and maintainable styles for your project. Whether you’re a beginner or a seasoned developer, mastering vanilla CSS in React ensures a solid foundation for styling your applications.
            </p>

        </section>
    )
}