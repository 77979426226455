import React, { useEffect, useState, useRef } from 'react'
import { Outlet, Link } from "react-router-dom";
import TopHeader from './TopHeader';
import Footer from './Footer';
import { MenuContext, TitleContext, PathContext } from "../Context";
import { useContext } from "react";
import QuizMenu from './QuizMenu';

const Quizzes = () => {

    const addspaceElement = useRef();
    const menuElement = useRef([]);
    const menu = useContext(MenuContext);
    const pathName = useContext(PathContext);
    const pathname = pathName.pathName;
    const title = useContext(TitleContext);

    const setPathName = (path) => {
        if(path)
            pathName.setPathName(path);
    }

    useEffect(() => {
        setPathName(window.location.pathname)
        title.setPageTitle( "Quizzes | Aspirant's Home");
    }, [pathname])

    return (
        <>
            <TopHeader />
            <div className='container-fluid'>
                <div className='row' style={{ background: '#ddd' }}>
                    <div ref={menuElement} className={menu.isMenu == true ? 'col-md-3 col-lg-2 menuColumnMobile menuColumn p-0' : ' menuColumn p-0'} >
                        <QuizMenu pathname={pathname} setPathName={setPathName} />
                    </div>
                    <div className='col-12 middlePart'>
                        <div className='headerAdSpace' ref={addspaceElement}>
                            <img className='img-fluid' src='https://aspirantshome.com/slide1.png' />
                        </div>
                        <div className='contentPart row sticky-top'>
                            <div className='col-12 col-md-12 contentSection'>
                                <div className='container mb-5'>
                                    <h3>Quizzes</h3>
                                    <p>Quizzes are a fun and interactive way to test your knowledge and enhance your understanding of key concepts. Whether you're preparing for exams, improving your skills, or simply challenging yourself, our quizzes are designed to make learning engaging and effective.</p>
                                    <p>Challenge your knowledge with AspirantsHome's Quizzes.</p>

                                    <div className='row'>
                                        <div className='col-6 eachSubject left' style={{background:'#a2bccf'}}>
                                            <h5>NodeJS</h5>
                                            <p>25 Questions covering the basics</p>
                                            <a href='/quiz/node-js' className='btn btn-primary'>Start the Quiz</a>
                                        </div>
                                        <div className='col-6 eachSubject right' style={{background:'rgb(158 204 183)'}}>
                                            <h5>MongoDB</h5>
                                            <p>25 Questions covering the basics</p>
                                            <a href='/quiz/mongo-db' className='btn btn-primary'>Start the Quiz</a>
                                        </div>
                                    </div>
                                    <div className='row mt-5'>
                                        <div className='col-6 eachSubject left' style={{background:'rgb(203 204 158)'}}>
                                            <h5>Angular</h5>
                                            <p>25 Questions covering the basics</p>
                                            <a href='/quiz/angular' className='btn btn-primary'>Start the Quiz</a>
                                        </div>
                                        <div className='col-6 eachSubject right' style={{background:'rgb(169 158 204)'}}>
                                            <h5>ReactJS</h5>
                                            <p>25 Questions covering the basics</p>
                                            <a href='/quiz/react-js' className='btn btn-primary'>Start the Quiz</a>
                                        </div>
                                    </div>
                                    <div className='row mt-5'>
                                        <div className='col-6 eachSubject left' style={{background:'rgb(204 158 183)'}}>
                                            <h5>JavaScript</h5>
                                            <p>25 Questions covering the basics</p>
                                            <a href='/quiz/javascript' className='btn btn-primary'>Start the Quiz</a>
                                        </div>
                                        <div className='col-6 eachSubject right' style={{background:'rgb(155 135 135)'}}>
                                            <h5>HTML</h5>
                                            <p>25 Questions covering the basics</p>
                                            <a href='/quiz/html' className='btn btn-primary'>Start the Quiz</a>
                                        </div>
                                    </div>
                                    <div className='row mt-5'>
                                        <div className='col-6 eachSubject left' style={{background:'rgb(89 141 218)'}}>
                                            <h5>CSS</h5>
                                            <p>25 Questions covering the basics</p>
                                            <a href='/quiz/css' className='btn btn-primary'>Start the Quiz</a>
                                        </div>
                                        <div className='col-6 eachSubject right' style={{background:'rgb(204 158 158)'}}>
                                            <h5>Mysql</h5>
                                            <p>25 Questions covering the basics</p>
                                            <a href='/quiz/mysql' className='btn btn-primary'>Start the Quiz</a>
                                        </div>
                                    </div>
                                    <div className='row mt-5 mb-5'>
                                        <div className='col-6 eachSubject left' style={{background:'rgb(147 153 92)'}}>
                                            <h5>PHP</h5>
                                            <p>25 Questions covering the basics</p>
                                            <a href='/quiz/php' className='btn btn-primary'>Start the Quiz</a>
                                        </div>
                                        <div className='col-6 eachSubject right' style={{background:'rgb(179 99 99)'}}>
                                            <h5>jQuery</h5>
                                            <p>25 Questions covering the basics</p>
                                            <a href='/quiz/jquery' className='btn btn-primary'>Start the Quiz</a>
                                        </div>
                                    </div>

                                    <div className='footerAdSpace'>
                                        <img className='img-fluid' src='https://aspirantshome.com/slide4.png' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12  rightPart'>
                        <img className='img-fluid' src='https://aspirantshome.com/slide2.png' />
                        <img className='mt-5 img-fluid' src='https://aspirantshome.com/slide3.png' />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='container-fluid footerPart'>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Quizzes;
