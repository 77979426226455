import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/working-with-json";

export default function WorkingWithJSON() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("JSON in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/handling-responses',
            'next': '/javascript/js-2009'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>What is JSON?</h3>
            <div className='mt-4 mb-5'>
                <ul style={{ listStyle: 'disc' }}>
                    <li>JSON stands for <strong>JavaScript Object Notation</strong>.</li>
                    <li>It is a lightweight data format used for exchanging information between a client (like a web browser) and a server.</li>
                    <li>JSON is easy to read and write for humans and easy to parse and generate for machines.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Key Characteristics of JSON</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Text Format</strong>: JSON is plain text, making it simple to share across different systems.</li>
                    <li><strong>Based on JavaScript Objects</strong>: JSON is inspired by JavaScript object syntax but can be used with most programming languages.</li>
                    <li><strong>Structured Data</strong>: It organizes data in a clear, hierarchical structure.</li>
                    <li><strong>File Extension</strong>: JSON files typically use the <span className='background-grey'>.json</span> extension.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Basic JSON Syntax</h5>
                <p>JSON is made up of:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>Key-Value Pairs</strong>: Enclosed in curly braces <span className='background-grey'>&#123; &#125;</span>.</li>
                    <li><strong>Arrays</strong>: Lists of values enclosed in square brackets <span className='background-grey'>[ ]</span>.</li>
                </ul>

                <h6 className='mt-3'>Example of a JSON Object</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>{</p>
                        <p class="ml-30">    <span class="color-green">"name"</span>: <span class="color-green">"John Doe"</span>,</p>
                        <p class="ml-30">    <span class="color-green">"age"</span>: <span class="color-pink">25</span>,</p>
                        <p class="ml-30">    <span class="color-green">"isStudent"</span>: <span class="color-blue">true</span>,</p>
                        <p class="ml-30">    <span class="color-green">"skills"</span>: [<span class="color-green">"JavaScript"</span>, <span class="color-green">"React"</span>, <span class="color-green">"Node.js"</span>],</p>
                        <p class="ml-30">    <span class="color-green">"address"</span>: {</p>
                        <p class="ml-60">        <span class="color-green">"city"</span>: <span class="color-green">"New York"</span>,</p>
                        <p class="ml-60">        <span class="color-green">"zipCode"</span>: <span class="color-pink">10001</span></p>
                        <p class="ml-30">    }</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Rules of JSON Syntax</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Keys</strong> must be strings enclosed in double quotes (").</li>
                    <li>
                        <strong>Values</strong> can be
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Strings: <span className='background-grey'>"John Doe"</span></li>
                            <li>Numbers: <span className='background-grey'>25</span></li>
                            <li>Booleans: <span className='background-grey'>true</span> or <span className='background-grey'>false</span></li>
                            <li>Arrays: <span className='background-grey'>["JavaScript", "React"]</span></li>
                            <li>Objects: <span className='background-grey'>&#123; "city": "New York" &#125;</span></li>
                            <li><span className='background-grey'>null</span></li>
                        </ul>
                    </li>
                    <li><strong>No Trailing Commas</strong>: Avoid commas after the last key-value pair or item in an array.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Why Use JSON?</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>Data Exchange</strong>: JSON is widely used in APIs to send and receive data.</li>
                    <li><strong>Readability</strong>: Easy for developers to understand.</li>
                    <li><strong>Cross-Platform</strong>: Works with most programming languages and frameworks.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Working with JSON in JavaScript</h5>

                <h6 className='mt-4'>Converting JSON to a JavaScript Object</h6>
                <p>You can parse JSON strings into JavaScript objects using <span className='background-grey'>JSON.parse()</span>.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">const</span> jsonString = <span class="color-green">'{"name": "John Doe", "age": 25}'</span>;</p>
                        <p><span class="color-blue">const</span> obj = JSON.<span class="color-red">parse</span>(jsonString);</p>
                        <br />
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(obj.<span class="color-pink">name</span>); <span class="color-grey">// Output: John Doe</span></p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(obj.<span class="color-pink">age</span>);  <span class="color-grey">// Output: 25</span></p>
                        `
                    }}></div>
                </div>


                <h6 className='mt-4'>Converting a JavaScript Object to JSON</h6>
                <p>Use <span className='background-grey'>JSON.stringify()</span> to convert JavaScript objects into JSON strings.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">const</span> person = {</p>
                        <p class="ml-30">    <span class="color-pink">name</span>: <span class="color-green">"John Doe"</span>,</p>
                        <p class="ml-30">    <span class="color-pink">age</span>: <span class="color-pink">25</span>,</p>
                        <p class="ml-30">    <span class="color-pink">isStudent</span>: <span class="color-blue">true</span></p>
                        <p>};</p>
                        <br />
                        <p><span class="color-blue">const</span> jsonString = JSON.<span class="color-red">stringify</span>(person);</p>
                        <br />
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(jsonString);</p>
                        <p class="color-grey">// Output: {"name":"John Doe","age":25,"isStudent":true}</p>
                        `
                    }}></div>
                </div>



            </div>
        </section>
    )
}