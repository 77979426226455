import React from 'react'
import * as moment from 'moment'

export default function Footer() {
    return (
        <>
            <div className='row footerSection'>
                <div className='col-sm-12 mb-5 col-lg-6'>
                    <div className="div">
                        <h3>
                            <a href='https://aspirantshome.com'>
                                <img className='img-fluid' src="https://aspirantshome.com/logo-white.png" />
                            </a>
                        </h3>
                        <span>Aspirant's Home is an innovative educational and career development platform designed to empower individuals in achieving their academic and professional aspirations.</span><br />
                        <a style={{ 'color':'inherit'}} href='https://aspirantshome.com/terms-of-service'>Terms of Use</a>&nbsp;&nbsp;&nbsp;<a style={{ 'color':'inherit'}} href='https://aspirantshome.com/about-us'>About Us</a>&nbsp;&nbsp;&nbsp;<a style={{ 'color':'inherit'}} href='https://aspirantshome.com/privacy-policy'>Privacy Policy</a>
                    </div>
                    <div className='socialPart mt-3'>
                        <span>
                            <a style={{ 'color':'inherit'}} target='_blank' href='https://www.facebook.com/people/Aspirants-Home/61560419296735/'>
                                <i className="fa-brands fa-facebook"></i>
                            </a>
                        </span>
                        <span>
                            <a style={{ 'color': 'inherit' }} target='_blank' href='https://x.com/AspirantHome'>
                                <i className="fa-brands fa-x-twitter"></i>
                            </a>
                        </span>
                        {/* <span><i className="fa-brands fa-linkedin"></i></span>
                        <span><i className="fa-brands fa-instagram"></i></span>
                        <span><i className="fa-brands fa-youtube"></i></span> */}
                    </div>
                </div>
                <div className='col-sm-12 col-lg-6'>
                    <div className='row footerMenu'>
                        <div className='col-xs-12 col-sm-6'>
                            <h4>Top Tutorials</h4>
                            <ul>
                                <li> <a className='no-decoration' href="/mongo-db/introduction">MongoDB Tutorial</a></li>
                                <li> <a className='no-decoration' href="/react-js/introduction">ReactJS Tutorial</a></li>
                                <li> <a className='no-decoration' href="/node-js/introduction">NodeJS Tutorial</a></li>
                                <li> <a className='no-decoration' href="/mysql/introduction">MySql Tutorial</a></li>
                            </ul>
                        </div>
                        <div className='col-xs-12 col-sm-6'>
                            <h4>Trending Topics</h4>
                            <ul>
                                <li> <a className='no-decoration' href="/react-js/introduction">ReactJS Tutorial</a></li>
                                <li> <a className='no-decoration' href="/node-js/introduction">NodeJS Tutorial</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='copyright mb-2'>
                <span>© Copyright {moment(new Date()).format('YYYY')}.<br className='hiddenInLarge'/> All Rights Reserved.</span>
            </div>
        </>
    )
}
