import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/selecting-elements";

export default function SelectingElement() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Selecting Elements in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/async-await',
            'next': '/javascript/changing-content-and-styles'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Selecting Elements</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In JavaScript, <strong>selecting elements</strong> means identifying and accessing elements on a web page so you can manipulate them. For example, you might want to change the text of a paragraph, style a button, or hide an image.
                </p>
                <p>
                    JavaScript provides several methods to select elements in the DOM (Document Object Model).
                </p>

                <h5 className='mt-5 mb-3'>Ways to Select Elements in JavaScript</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <span className='background-grey'>getElementById()</span>
                        <p>It selects an element by its ID and returns <strong>one element</strong>.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;p <span class="color-pink">id</span>=<span class="color-green">"myParagraph"</span>&gt;Hello, World!&lt;/p&gt;</p>
                                <br />
                                <p>&lt;script&gt;</p>
                                <p class="ml-30">    <span class="color-blue">let</span> element = document.<span class="color-red">getElementById</span>(<span class="color-green">"myParagraph"</span>);</p>
                                <p class="ml-30">    <span class="color-pink">console</spoan>.<span class="color-red">log</span>(element.textContent); <span class="color-grey">// Output: Hello, World!</span></p>
                                <p>&lt;/script&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>getElementsByClassName()</span>
                        <p>It selects elements by their <strong>class name</strong> and returns a collection (like an array) of all matching elements.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"text"</span>&gt;First paragraph&lt;/p&gt;</p>
                                <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"text"</span>&gt;Second paragraph&lt;/p&gt;</p>
                                <br />
                                <p>&lt;script&gt;</p>
                                <p class="ml-30">    <span class="color-blue">let</span> elements = document.<span class="color-red">getElementsByClassName</span>(<span class="color-green">"text"</span>);</p>
                                <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(elements[<span class="color-pink">0</span>].textContent); <span class="color-grey">// Output: First paragraph</span></p>
                                <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(elements[<span class="color-pink">1</span>].textContent); <span class="color-grey">// Output: Second paragraph</span></p>
                                <p>&lt;/script&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>getElementsByTagName()</span>
                        <p>It selects elements by their tag name (e.g., <span className='background-grey'>p</span>, <span className='background-grey'>div</span>, <span className='background-grey'>span</span>). It returns a collection of all matching elements.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;div&gt;Hello&lt;/div&gt;</p>
                                <p>&lt;div&gt;World&lt;/div&gt;</p>
                                <br />
                                <p>&lt;script&gt;</p>
                                <p class="ml-30">    <span class="color-blue">let</span> elements = document.<span class="color-red">getElementsByTagName</span>(<span class="color-green">"div"</span>);</p>
                                <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(elements[<span class="color-pink">0</span>].textContent); <span class="color-grey">// Output: Hello</span></p>
                                <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(elements[<span class="color-pink">1</span>].textContent); <span class="color-grey">// Output: World</span></p>
                                <p>&lt;/script&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>querySelector()</span>
                        <p>It selects the <strong>first element</strong> that matches a CSS selector. Use <strong>CSS selectors</strong> like <span className='background-grey'>#id</span>, <span className='background-grey'>.class</span>, or <span className='background-grey'>tagname</span>.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"text"</span>&gt;First paragraph&lt;/p&gt;</p>
                                <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"text"</span>&gt;Second paragraph&lt;/p&gt;</p>
                                <br />
                                <p>&lt;script&gt;</p>
                                <p class="ml-30">    <span class="color-blue">let</span> element = document.<span class="color-red">querySelector</span>(<span class="color-green">".text"</span>);</p>
                                <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(element.textContent); <span class="color-grey">// Output: First paragraph</span></p>
                                <p>&lt;/script&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>querySelectorAll()</span>
                        <p>It selects <strong>all elements</strong> that match a CSS selector. It returns a <strong>NodeList</strong> (similar to an array) of elements.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"text"</span>&gt;First paragraph&lt;/p&gt;</p>
                                <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"text"</span>&gt;Second paragraph&lt;/p&gt;</p>
                                <br />
                                <p>&lt;script&gt;</p>
                                <p class="ml-30">    <span class="color-blue">let</span> elements = document.<span class="color-red">querySelectorAll</span>(<span class="color-green">".text"</span>);</p>
                                <p class="ml-30">    elements.<span class="color-red">forEach</span>(element => <span class="color-pink">console</span>.<span class="color-red">log</span>(element.textContent));</p>
                                <p class="ml-30 color-grey">    // Output:</p>
                                <p class="ml-30 color-grey">    // First paragraph</p>
                                <p class="ml-30 color-grey">    // Second paragraph</p>
                                <p>&lt;/script&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>Some Examples Of DOM</h5>

                <h6 className='mt-3'>Example 1: Changing the Text of an Element:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;p <span class="color-pink">id</span>=<span class="color-green">"greeting"</span>&gt;Hello!&lt;/p&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">    <span class="color-blue">let</span> element = document.<span class="color-red">getElementById</span>(<span class="color-green">"greeting"</span>);</p>
                        <p class="ml-30">    element.textContent = <span class="color-green">"Hi there!"</span>;</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>

                <h6 className='mt-3'>Example 2: Changing the Style of Elements:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"highlight"</span>&gt;This is highlighted text.&lt;/p&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">    <span class="color-blue">let</span> elements = document.<span class="color-red">getElementsByClassName</span>(<span class="color-green">"highlight"</span>);</p>
                        <p class="ml-30">    <span class="color-blue">for</span> (<span class="color-blue">let</span> element <span class="color-blue">of</span> elements) {</p>
                        <p class="ml-60">        element.style.color = <span class="color-green">"red"</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>


                <h6 className='mt-3'>Example 3: Hiding an Element:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"hideMe"</span>&gt;Hide this text&lt;/div&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">    <span class="color-blue">let</span> element = document.<span class="color-red">getElementById</span>(<span class="color-green">"hideMe"</span>);</p>
                        <p class="ml-30">    element.style.display = <span class="color-green">"none"</span>; <span class="color-grey">// Hides the element</span></p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>




            </div>
        </section>
    )
}