import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/js-2019";

export default function Js2019() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("ECMAScript 2019 | Aspirant's Home");
        const urls = {
            'previous': '/javascript/js-2018',
            'next': '/javascript/js-2020'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>New Features in ECMAScript 2019</h3>
            <div className='mt-4 mb-5'>
                <p>These are the new features in ECMAScript 2018:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><a href="#trim-start">String.trimStart()</a></li>
                    <li><a href="#trim-end">String.trimEnd()</a></li>
                    <li><a href="#from-entries">Object.fromEntries</a></li>
                    <li><a href="#catch-binding">Optional catch binding</a></li>
                    <li><a href="#array-flat">Array.flat()</a></li>
                    <li><a href="#array-flatmap">Array.flatMap()</a></li>
                    <li><a href="#array-sort">Revised Array.Sort()</a></li>
                    <li><a href="#json-stringify">Revised JSON.stringify()</a></li>
                    <li>Separator symbols allowed in string litterals</li>
                    <li><a href="#to-string">Revised Function.toString()</a></li>
                </ul>

                <div id="trim-start">
                    <h5 className='mt-5 mb-3'>JavaScript String trimStart()</h5>
                    <p>The <span className='background-grey'>trimStart()</span> method removes whitespace from the beginning of a string. It does not modify the original string but returns a new string with leading whitespace removed.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> str = <span class="color-green">'   Hello, World!'</span>;</p>
                            <p><span class="color-blue">const</span> trimmed = str.<span class="color-red">trimStart</span>();</p>
                            <br />
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(trimmed); <span class="color-grey">// 'Hello, World!'</span></p>
                            `
                        }}></div>
                    </div>
                </div>

                <div id="trim-end">
                    <h5 className='mt-5 mb-3'>JavaScript String trimEnd()</h5>
                    <p>The <span className='background-grey'>trimEnd()</span> method removes whitespace characters from the end of a string. It does not modify the original string but returns a new string with trailing whitespace removed.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> str = <span class="color-green">'Hello, World!   '</span>;</p>
                            <p><span class="color-blue">const</span> trimmed = str.<span class="color-red">trimEnd</span>();</p>
                            <br />
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(trimmed); <span class="color-green">// 'Hello, World!'</span></p>
                            `
                        }}></div>
                    </div>
                </div>

                <div id="from-entries">
                    <h5 className='mt-5 mb-3'>JavaScript Object fromEntries()</h5>
                    <p>The <span className='background-grey'>Object.fromEntries()</span> method transforms a list of <strong>key-value pairs</strong> (like arrays) into an object. It is the reverse of <span className='background-grey'>Object.entries()</span>, which converts an object into key-value pairs.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> entries = [[<span class="color-green">'name'</span>, <span class="color-green">'Alice'</span>], [<span class="color-green">'age'</span>, <span class="color-pink">25</span>], [<span class="color-green">'city'</span>, <span class="color-green">'New York'</span>]];</p>
                            <p><span class="color-blue">const</span> obj = Object.<span class="color-red">fromEntries</span>(entries);</p>
                            <br />
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(obj);</p>
                            <p class="color-grey">// Output: { name: 'Alice', age: 25, city: 'New York' }</p>
                            `
                        }}></div>
                    </div>
                </div>

                <div id="catch-binding">
                    <h5 className='mt-5 mb-3'>Optional catch Binding</h5>
                    <p>In older versions of JavaScript, the catch block required a parameter, even if the error was not used:</p>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">try</span> {</p>
                            <p class="ml-30 color-grey">    // Code that might throw an error</p>
                            <p class="ml-30">    <span class="color-blue">throw new</span> <span class="color-red">Error</span>(<span class="color-green">'Something went wrong'</span>);</p>
                            <p>} <span class="color-blue">catch</span> (err) {</p>
                            <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'An error occurred'</span>); <span class="color-grey">// You had to declare &#96;err&#96; even if unused</span></p>
                            <p>}</p>
                            `
                        }}></div>
                    </div>
                    <p>Starting in <strong>ES2019</strong>, you can <strong>omit the <span className='background-grey'>err</span> parameter</strong> entirely:</p>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">try</span> {</p>
                            <p class="ml-30 color-grey">    // Code that might throw an error</p>
                            <p class="ml-30">    <span class="color-blue">throw new</span> <span class="color-red">Error</span>(<span class="color-green">'Something went wrong'</span>);</p>
                            <p>} catch {</p>
                            <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'An error occurred'</span>); <span class="color-grey">// No need to declare &#96;err&#96;</span></p>
                            <p>}</p>
                            `
                        }}></div>
                    </div>
                </div>

                <div id="array-flat">
                    <h5 className='mt-5 mb-3'>JavaScript Array flat()</h5>
                    <p>The <span className='background-grey'>flat()</span> method creates a new array by <strong>flattening nested arrays</strong> up to a specified depth. It reduces the structure of a multi-dimensional array into a single-dimensional array.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> arr = [<span class="color-pink">1</span>, <span class="color-pink">2</span>, [<span class="color-pink">3</span>, <span class="color-pink">4</span>, [<span class="color-pink">5</span>, <span class="color-pink">6</span>]]];</p>
                            <p><span class="color-blue">const</span> flatArr = arr.<span class="color-red">flat</span>();</p>
                            <br />
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(flatArr); </p>
                            <p class="color-grey">// Output: [1, 2, 3, 4, [5, 6]]</p>
                            <br />
                            <p class="color-grey">// Flatten up to 2 levels deep</p>
                            <p><span class="color-blue">const</span> flatArr = arr.<span class="color-red">flat</span>(<span class="color-pink">2</span>);</p>
                            <br />
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(flatArr); </p>
                            <p class="color-grey">// Output: [1, 2, 3, 4, 5, 6]</p>
                            `
                        }}></div>
                    </div>
                </div>

                <div id="array-flatmap">
                    <h5 className='mt-5 mb-3'>JavaScript Array flatMap()</h5>
                    <p>The <span className='background-grey'>flatMap()</span> method first <strong>maps each element</strong> of an array using a callback function and then <strong>flattens the result</strong> into a new array. It combines <span className='background-grey'>map()</span> and <span className='background-grey'>flat()</span> (with depth <span className='background-grey'>1</span>) into a single method.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> arr = [<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>];</p>
                            <p><span class="color-blue">const</span> result = arr.<span class="color-red">flatMap</span>(x => [x, x * <span class="color-pink">2</span>]);</p>
                            <br />
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(result);</p>
                            <p class="color-grey">// Output: [1, 2, 2, 4, 3, 6]</p>
                            `
                        }}></div>
                    </div>
                </div>

                <div id="array-sort">
                    <h5 className='mt-5 mb-3'>Stable Array sort()</h5>
                    <p>In <strong>ES2019 (ECMAScript 2019)</strong>, the behavior of the <span className='background-grey'>Array.prototype.sort()</span> method was revised to make it more <strong>stable</strong>.</p>
                    <p>Let's take an example:</p>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> arr = [</p>
                            <p class="ml-30">    { <span class="color-pink">value</span>: <span class="color-pink">10</span>, <span class="color-pink">name</span>: <span class="color-green">'A'</span> },</p>
                            <p class="ml-30">    { <span class="color-pink">value</span>: <span class="color-pink">10</span>, <span class="color-pink">name</span>: <span class="color-green">'B'</span> },</p>
                            <p class="ml-30">    { <span class="color-pink">value</span>: <span class="color-pink">5</span>, <span class="color-pink">name</span>: <span class="color-green">'C'</span> }</p>
                            <p>];</p>
                            <br /> 
                            <p>arr.<span class="color-red">sort</span>((a, b) => a.<span class="color-pink">value</span> - b.<span class="color-pink">value</span>);</p>
                            <br />  
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(arr);</p>
                            <p class="color-grey">// Output (before ES2019 - Unstable):</p>
                            <p class="color-grey">// [{ value: 5, name: 'C' }, { value: 10, name: 'B' }, { value: 10, name: 'A' }]</p>
                            <br />
                            <p class="color-grey">// Output (ES2019 - Stable):</p>
                            <p class="color-grey">// [{ value: 5, name: 'C' }, { value: 10, name: 'A' }, { value: 10, name: 'B' }]</p>
                            `
                        }}></div>
                    </div>
                    <p>Prior to ES2019, the <span className='background-grey'>sort()</span> method implementation in some engines (like V8 or older browsers) could produce <strong>unstable results</strong>. Equal elements might not retain their relative order.</p>
                    <p>Here, the order of <span className='background-grey'>&#123;value: 10, name: 'A' &#125;</span> and <span className='background-grey'>&#123;value: 10, name: 'B' &#125;</span> might <strong>change</strong>.</p>
                    <p>From <strong>ES2019 onwards</strong>, the <span className='background-grey'>sort()</span> method was revised to guarantee <strong>stability</strong> in all compliant JavaScript engines. Equal elements now retain their relative order.</p>
                </div>

                <div id="json-stringify">
                    <h5 className='mt-5 mb-3'>Revised JSON.stringify()</h5>
                    <p>ES2019 <strong>revised</strong> the JSON <span className='background-grey'>stringify()</span> method. Before 2019, JSON could not stringify character encoded with \.</p>
                    <p>Let's take an example:</p>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> obj = { <span class="color-pink">text</span>: <span class="color-green">'Line separator:&#92;u2028 Paragraph separator:&#92;u2029'</span> };</p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(JSON.<span class="color-red">stringify</span>(obj));</p>
                            <p class="color-grey">// Output (Before ES2019): SyntaxError in some parsers</p>

                            <p class="color-grey">// Output (After ES2019): {"text":"Line separator:&#92;u2028 Paragraph separator:&#92;u2029"}</p>
                            `
                        }}></div>
                    </div>
                </div>

                <div id="to-string">
                    <h5 className='mt-5 mb-3'>Revised Function toString()</h5>
                    <p>In <strong>ES2019 (ECMAScript 2019)</strong>, the behavior of the <span className='background-grey'>toString()</span> method for <strong>functions</strong> was updated to ensure <strong>consistent output</strong> across all JavaScript engines. Specifically, it standardized the handling of <strong>comments in functions</strong> when calling <span className='background-grey'>toString()</span>.</p>
                    <p>Let's take an example:</p>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">function</span> <span class="color-red">test</span>() {</p>
                            <p class="ml-30 color-grey">    // This is a comment</p>
                            <p class="ml-30">    <span class="color-blue">return</span> <span class="color-pink">42</span>;</p>
                            <p>}</p>
                            <br />  
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(test.<span class="color-red">toString</span>());</p>
                            <p class="color-grey">// Output (Before ES2019): "function test() { return 42; }"</p>
                            <p class="color-grey">// (Comments might be removed depending on the engine)</p>
                            <br />
                            <p class="color-grey">// Output (ES2019): "function test() {&#92;n  // This is a comment&#92;n  return 42;&#92;n}"</p>
                            <p class="color-grey">// Comments are preserved in the function's string representation</p>
                            `
                        }}></div>
                    </div>
                    <p>Now, <strong>comments</strong> in the function body are retained in the string returned by <span className='background-grey'>toString()</span>, ensuring consistency across JavaScript engines.</p>
                </div>


            </div>
        </section>
    )
}