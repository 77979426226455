import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/show-tables";

export default function MysqlShowTable() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Show Table | Aspirant's Home");
        const urls = {
            'previous': '/mysql/create-tables',
            'next': '/mysql/alter-tables'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Show Table</h3>
            <p>
                When working with MySQL, it is essential to interact with and manage the tables within your database effectively. One of the simplest yet powerful commands for this purpose is the <span className='background-grey'>SHOW TABLES</span> command. This article will guide you through the use of this command, its variations, and practical examples to help you manage your MySQL tables efficiently.
            </p>

            <h5 className='mt-5 mb-3'>What Does the SHOW TABLES Command Do?</h5>
            <p>
                The <span className='background-grey'>SHOW TABLES</span> command is used to list all the tables in a specific database. It is particularly useful for understanding the structure of a database or verifying the existence of a table before performing operations.
            </p>
            <h6 className='mt-3'>Syntax:</h6>
            <div className='codePalateBox mt-2 mb-4'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `
                        <p class="color-blue">SHOW TABLES;</p>
                    `
                }}></div>
            </div>
            <p>By default, this command shows all the tables in the currently selected database. If you want to check the tables in a specific database, you need to switch to that database first using the <span className='background-grey'>USE</span> statement:</p>
            <div className='codePalateBox mt-2 mb-4'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `
                    <p><span class="color-blue">USE</span> database_name;</p>
                    <p class="color-blue">SHOW TABLES;</p>
                    `
                }}></div>
            </div>

            <h5 className='mt-5 mb-3'>Using SHOW TABLES with Filters</h5>
            <p>MySQL allows filtering the output of SHOW TABLES using the <span className='background-grey'>LIKE</span> or <span className='background-grey'>WHERE</span> clauses to match specific patterns or conditions.</p>

            <h6 className='mt-3'>Using LIKE Clause</h6>
            <p>You can filter tables by name using the <span className='background-grey'>LIKE</span> clause:</p>
            <div className='codePalateBox mt-2 mb-4'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `
                        <p><span class="color-blue">SHOW TABLES</span> <span class="color-pink">LIKE</span> <span class="color-green">'prefix%'</span>;</p>
                    `
                }}></div>
            </div>
            <p>This will display only tables whose names start with prefix.</p>

            <h6 className='mt-3'>Using WHERE Clause</h6>
            <p>For more complex filtering, use the <span className='background-grey'>WHERE</span> clause (introduced in MySQL 8.0):</p>
            <div className='codePalateBox mt-2 mb-4'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `
                        <p><span class="color-blue">SHOW TABLES WHERE</span> Tables_in_database_name <span class="color-pink">LIKE</span> <span class="color-green">'suffix%'</span>;</p>
                    `
                }}></div>
            </div>
            <p className='mt-0 mb-0'>Here,</p>
            <ul style={{ listStyle: 'disc' }}>
                <li>Replace <span className='background-grey'>database_name</span> with your actual database name.</li>
                <li>This allows conditional filtering for tables.</li>
            </ul>


            <h5 className='mt-5 mb-3'>Examples of SHOW TABLES Command</h5>

            <h6 className='mt-3'>1. Listing All Tables in the Current Database</h6>
            <div className='codePalateBox mt-2 mb-4'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `
                    <p><span class="color-blue">USE</span> my_database;</p>
                    <p><span class="color-blue">SHOW TABLES</span>;</p>
                    `
                }}></div>
            </div>
            <h6>Output:</h6>
            <div className='codePalateBox mt-2 mb-4'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `
                    <p>+------------------+</p>
                    <p>| Tables_in_my_database |</p>
                    <p>+------------------+</p>
                    <p style="width:150px">| users            <span class="float-end">|</span></p>
                    <p style="width:150px">| user_profiles    <span class="float-end">|</span></p>
                    <p style="width:150px">| orders           <span class="float-end">|</span></p>
                    <p style="width:150px">| products         <span class="float-end">|</span></p>
                    <p style="width:150px">| error_log        <span class="float-end">|</span></p>
                    <p style="width:150px">| activity_log     <span class="float-end">|</span></p>
                    <p>+------------------+</p>
                    `
                }}></div>
            </div>


            <h6 className='mt-3'>2. Filtering Tables by Name</h6>
            <div className='codePalateBox mt-2 mb-4'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `
                    <p><span class="color-blue">SHOW TABLES</span> <span class="color-pink">LIKE</span> <span class="color-green">'user%'</span>;</p>
                    `
                }}></div>
            </div>
            <h6>Output:</h6>
            <div className='codePalateBox mt-2 mb-4'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `
                    <p>+------------------+</p>
                    <p>| Tables_in_my_database |</p>
                    <p>+------------------+</p>
                    <p style="width:150px">| users            <span class="float-end">|</span></p>
                    <p style="width:150px">| user_profiles    <span class="float-end">|</span></p>
                    <p>+------------------+</p>
                    `
                }}></div>
            </div>


            <h6 className='mt-3'>3. Filtering Tables Using WHERE Clause</h6>
            <div className='codePalateBox mt-2 mb-4'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `
                    <p><span class="color-blue">SHOW TABLES WHERE</span> Tables_in_my_database <span class="color-pink">LIKE</span> <span class="color-green">'%log'</span>;</p>
                    `
                }}></div>
            </div>
            <h6>Output:</h6>
            <div className='codePalateBox mt-2 mb-4'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `
                    <p>+------------------+</p>
                    <p>| Tables_in_my_database |</p>
                    <p>+------------------+</p>
                    <p style="width:150px">| error_log       <span class="float-end">|</span></p>
                    <p style="width:150px">| activity_log    <span class="float-end">|</span></p>
                    <p>+------------------+</p>
                    `
                }}></div>
            </div>

            <h5 className='mt-5 mb-4'>Common Use Cases for SHOW TABLES</h5>

            <h6 className='mt-3'>1. Checking Database Structure</h6>
            <p>When you’re handed a database, the <span className='background-grey'>SHOW TABLES</span> command is your first step to understanding its structure.</p>

            <h6 className='mt-3'>2. Debugging Issues</h6>
            <p>If a query fails, you can use <span className='background-grey'>SHOW TABLES</span> to verify whether the table you’re trying to access exists.</p>

            <h6 className='mt-3'>3. Filtering Specific Tables</h6>
            <p>For large databases with numerous tables, filtering using <span className='background-grey'>LIKE</span> or <span className='background-grey'>WHERE</span> can save time.</p>


            <h5 className='mt-5 mb-4'>FAQs About SHOW TABLES Command</h5>
            <h6 className='mt-3'>1. Can I see the type of each table using SHOW TABLES?</h6>
            <p>No, the <span className='background-grey'>SHOW TABLES</span> command only lists the table names. To see table types, use the <span className='background-grey'>SHOW FULL TABLES</span> command:</p>
            <div className='codePalateBox mt-2 mb-4'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `
                        <p class="color-blue">SHOW FULL TABLES;</p>
                    `
                }}></div>
            </div>
            <p>This will include a <span className='background-grey'>Table_type</span> column indicating whether a table is a <span className='background-grey'>BASE TABLE</span> or <span className='background-grey'>VIEW</span>.</p>

            <h6 className='mt-3'>2. How can I count the number of tables in a database?</h6>
            <p>You can count the number of tables using:</p>
            <div className='codePalateBox mt-2 mb-4'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `
                    <p><span class="color-blue">SELECT</span> <span class="color-red">COUNT</span>(<span class="color-pink">*</span>) <span class="color-blue">FROM</span> information_schema.tables <span class="color-blue">WHERE</span> table_schema = <span class="color-green">'database_name'</span>;</p>
                    `
                }}></div>
            </div>

            
            <h6 className='mt-3'>3. Why am I not seeing any tables listed?</h6>
            <p>Ensure you have selected the correct database using <span className='background-grey'>USE database_name;</span> and verify that the database contains tables.</p>

            <h6 className='mt-3'>4. Can I list tables across all databases?</h6>
            <p>No, <span className='background-grey'>SHOW TABLES</span> works for the selected database. To list tables across all databases, query the <span className='background-grey'>information_schema.tables</span> table:</p>
            <div className='codePalateBox mt-2 mb-4'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `
                        <p><span class="color-blue">SELECT</span> table_name, table_schema <span class="color-blue">FROM</span> information_schema.tables;</p>
                    `
                }}></div>
            </div>

            <h6 className='mt-3'>5. Is SHOW TABLES case-sensitive?</h6>
            <p>Table names in MySQL are case-sensitive on systems with case-sensitive file systems (e.g., Linux). On case-insensitive systems (e.g., Windows), table names are not case-sensitive.</p>


            <h5 className='mt-5 mb-4'>Conclusion</h5>

            <p>The <span className='background-grey'>SHOW TABLES</span> command is a fundamental tool for database management in MySQL. Its simplicity and flexibility make it invaluable for developers and database administrators alike. By leveraging filters like <span className='background-grey'>LIKE</span> and <span className='background-grey'>WHERE</span>, you can efficiently navigate even the largest databases. Whether you’re exploring a new schema or troubleshooting an issue, mastering the <span className='background-grey'>SHOW TABLES</span> command will enhance your productivity and control over your databases.</p>

        </section>
    )
}