import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/date-formats";

export default function DateFormats() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Date Formats in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/dates',
            'next': '/javascript/date-get-methods'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Date Formats</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In JavaScript, dates can be represented and formatted in various ways depending on how you want to display or work with the date. The Date object provides several built-in methods for formatting dates in readable or standard formats.
                </p>


                <h5 className='mt-5 mb-3'>Common Date Formats</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Default Date Format</strong>
                        <p>When a Date object is converted to a string, JavaScript uses the default date format for your browser or environment.</p>
                        <h6>Example</h6>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> today = <span class="color-blue">new</span> <span class="color-red">Date</span>();</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(today.<span class="color-red">toString</span>()); </p>
                                <p class="color-grey">// Output: "Fri Nov 29 2024 10:00:00 GMT+0000 (Coordinated Universal Time)"</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>ISO Format</strong> (<span className='background-grey'>toISOString()</span>)
                        <p>The ISO format is the international standard for representing dates and times. Format is <span className='background-grey'>YYYY-MM-DDTHH:mm:ss.sssZ</span></p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">let</span> today = <span class="color-blue">new</span> <span class="color-red">Date</span>();</p>
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(today.<span class="color-red">toISOString</span>()); </p>
                                    <p class="color-grey">// Output: "2024-11-29T10:00:00.000Z"</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Locale Date Format</strong> (<span className='background-grey'>toLocaleDateString()</span>)
                        <p>This method returns the date in a format specific to your locale (region or language).</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> today = <span class="color-blue">new</span> <span class="color-red">Date</span>();</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(today.<span class="color-red">toLocaleDateString</span>()); </p>
                                <p class="color-grey">// Output (US): "11/29/2024"</p>
                                <p class="color-grey">// Output (UK): "29/11/2024"</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>Date Formatting Methods</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <span className='background-grey'>toString()</span>
                        <p>It converts the date to a readable string.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> today = <span class="color-blue">new</span> <span class="color-red">Date</span>();</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(today.<span class="color-red">toString</span>()); </p>
                                <p class="color-grey">// Output: "Fri Nov 29 2024 10:00:00 GMT+0000 (Coordinated Universal Time)"</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>toDateString()</span>
                        <p>It returns only the date portion in a readable format.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">let</span> today = <span class="color-blue">new</span> <span class="color-red">Date</span>();</p>
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(today.<span class="color-red">toDateString</span>()); </p>
                                    <p class="color-grey">// Output: "Fri Nov 29 2024"</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>toTimeString()</span>
                        <p>It returns only the time portion.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">let</span> today = <span class="color-blue">new</span> <span class="color-red">Date</span>();</p>
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(today.<span class="color-red">toTimeString</span>()); </p>
                                    <p class="color-grey">// Output: "10:00:00 GMT+0000 (Coordinated Universal Time)"</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>toLocaleString()</span>
                        <p>It returns the date and time in a locale-specific format.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> today = <span class="color-blue">new</span> <span class="color-red">Date</span>();</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(today.<span class="color-red">toLocaleString</span>()); </p>
                                <p class="color-grey">// Output (US): "11/29/2024, 10:00:00 AM"</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>toUTCString()</span>
                        <p>It converts the date to a string in UTC (Universal Time Coordinated) format.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> today = <span class="color-blue">new</span> <span class="color-red">Date</span>();</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(today.<span class="color-red">toUTCString</span>()); </p>
                                <p class="color-grey">// Output: "Fri, 29 Nov 2024 10:00:00 GMT"</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

            </div>
        </section>
    )
}