import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/window-object";

export default function WindowObject() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Window Object in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/event-handling',
            'next': '/javascript/js-screen'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Window Object</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className='background-grey'>window</span> <strong>object</strong> is the global object in a browser environment. It represents the browser's window or tab where the web page is displayed. All global variables, functions, and browser-related functionalities are part of the <span className='background-grey'>window</span> object.
                </p>

                <h5 className='mt-5 mb-3'>Key Points About the Window Object:</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>It is automatically created by the browser.</li>
                    <li>It provides methods, properties, and events to interact with the browser.</li>
                    <li>Any global variable or function declared in JavaScript becomes a property or method of the <span className='background-grey'>window</span> object.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Examples:</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <p>Accessing a global variable:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">var</span> myName = <span class="color-green">"John"</span>;</p>
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(window.myName); <span class="color-grey">// Output: John</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <p>Using a method of the <span className='background-grey'>window</span> object:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>window.<span class="color-red">alert</span>(<span class="color-green">"Hello!"</span>); <span class="color-grey">// Displays an alert box</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
                <p>The <span className='background-grey'>window</span> object is essential for interacting with the browser and creating dynamic web applications.</p>


                <h5 className='mt-5 mb-3'>Window Size</h5>
                <p>
                    The <strong>window size</strong> refers to the dimensions of the browser window, which includes the visible area where the webpage content is displayed. JavaScript provides properties to get the size of the browser's window.
                </p>

                <h5 className='mt-5 mb-3'>Key Properties for Window Size</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <span className='background-grey'>window.innerWidth</span>
                        <p>It gives the width (in pixels) of the viewport (visible content area) including the scrollbar, if visible. For example:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Window Width: "</span> + window.innerWidth);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>window.innerHeight</span>
                        <p>It gives the height (in pixels) of the viewport (visible content area) including the scrollbar, if visible. For example:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Window Height: "</span> + window.innerHeight);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>window.outerWidth</span>
                        <p>It gives the width of the entire browser window, including the browser's borders and toolbars. For example:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Outer Window Width: "</span> + window.outerWidth);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>window.outerHeight</span>
                        <p>It gives the height of the entire browser window, including the browser's borders and toolbars. For example:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Outer Window Height: "</span> + window.outerHeight);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
                

            </div>
        </section>
    )
}