import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/timing";

export default function TimingObject() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Window Timing Object in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/js-navigator',
            'next': '/javascript/cookies'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Window Timing Object</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>Window Timing Object</strong> in JavaScript provides information about the performance of a webpage. It helps developers measure the time taken to load a page and optimize it for better performance. This is accessed using the <span className='background-grey'>window.performance</span> object.
                </p>

                <h5 className='mt-5 mb-3'>Key Concepts of the Window Timing Object</h5>
                <p>
                    The <span className='background-grey'>window.performance</span> object gives detailed information about page loading and navigation. Its most commonly used property is the <span className='background-grey'>performance.timing</span> object, which contains timestamps related to different stages of page loading.
                </p>

                <h5 className='mt-5 mb-5'>Key Properties of the Timing Object</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li className='operator mt-5'>
                        <h4><span className='background-grey'>performance.timing.navigationStart</span></h4>
                        <p className='mt-4 mb-0'>It returns the timestamp when the navigation to the document started. For example:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-pink">"Navigation Start: "</span> + performance.timing.<span class="color-pink">navigationStart</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h4><span className='background-grey'>performance.timing.domContentLoadedEventStart</span></h4>
                        <p className='mt-4 mb-0'>It returns the timestamp when the DOM content has been fully loaded. For example:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"DOM Content Loaded: "</span> + performance.timing.<span class="color-pink">domContentLoadedEventStart</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h4><span className='background-grey'>performance.timing.domComplete</span></h4>
                        <p className='mt-4 mb-0'>It returns the timestamp when the entire page, including subresources like images and scripts, is fully loaded. For example:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"DOM Complete: "</span> + performance.timing.<span class="color-pink">domComplete</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h4><span className='background-grey'>performance.timing.responseStart</span></h4>
                        <p className='mt-4 mb-0'>It returns the timestamp when the browser receives the first byte of the response from the server. For example:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Response Start: "</span> + performance.timing.<span class="color-pink">responseStart</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h4><span className='background-grey'>performance.timing.responseEnd</span></h4>
                        <p className='mt-4 mb-0'>It returns the timestamp when the browser has received the complete response from the server. For example:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Response End: "</span> + performance.timing.<span class="color-pink">responseEnd</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h4><span className='background-grey'>performance.timing.loadEventEnd</span></h4>
                        <p className='mt-4 mb-0'>It returns the timestamp when the <span className='background-grey'>load</span> event is completed, indicating the entire page is fully loaded. For example:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Load Event End: "</span> + performance.timing.<span class="color-pink">loadEventEnd</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>Calculating Page Load Time</h5>
                <p>You can use the timing properties to calculate how long it takes to load the page:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">const</span> pageLoadTime = performance.timing.<span class="color-pink">loadEventEnd</span> - performance.timing.<span class="color-pink">navigationStart</span>;</p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Page Load Time: "</span> + pageLoadTime + <span class="color-green">"ms"</span>);</p>
                        `
                    }}></div>
                </div>


            </div>
        </section>
    )
}