import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/cookies";

export default function CookiesObject() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Window Cookies Object in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/timing',
            'next': '/javascript/dialog-boxes'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Window Cookies Object</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Cookies are small pieces of data stored on the user's computer by the web browser. They are often used to save user preferences, session data, or other information between visits to a website. In JavaScript, cookies are managed using the <span className='background-grey'>document.cookie</span> property. 
                </p>

                <h5 className='mt-5 mb-3'>How Cookies Work in JavaScript</h5>
                <p>The <span className='background-grey'>document.cookie</span> property is used to create, read, and delete cookies. However, it works as a single string containing all cookies, separated by semicolons (<span className='background-grey'>;</span>).</p>


                <h5 className='mt-5 mb-3'>Create/Set a Cookie</h5>
                <p>
                    You can set a cookie by assigning a string to the <span className='background-grey'>document.cookie</span> property. A cookie consists of a key-value pair, and you can optionally specify other attributes like expiration date, path, and domain.
                </p>
                <p>
                    It has a basic syntax - <span className='background-grey'>document.cookie = "key=value"</span>;
                </p>
                <h6>Example:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>document.cookie = <span class="color-green">"theme=dark; expires=Fri, 31 Dec 2024 23:59:59 UTC"</span>;</p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Cookie set: "</span> + document.<span class="color-pink">cookie</span>);</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Read a Cookie</h5>
                <p>The <span className='background-grey'>document.cookie</span> property returns all cookies for the current document as a single string.</p>
                <strong>Example:</strong>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"All Cookies: "</span> + document.<span class="color-pink">cookie</span>);</p>
                        `
                    }}></div>
                </div>


                <strong>Extracting Specific Cookie Value:</strong>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">function</span> <span class="color-red">getCookieValue</span>(cookieName) {</p>
                        <p class="ml-30">    <span class="color-blue">const</span> cookies = document.cookie.<span class="color-red">split</span>(<span class="color-green">"; "</span>);</p>
                        <p class="ml-30">    <span class="color-blue">for</span> (<span class="color-blue">let</span> cookie <span class="color-pink">of</span> cookies) {</p>
                        <p class="ml-60">        <span class="color-blue">const</span> [key, value] = cookie.<span class="color-red">split</span>(<span class="color-green">"="</span>);</p>
                        <p class="ml-60">        <span class="color-blue">if</span> (key <span class="color-pink">===</span> cookieName) {</p>
                        <p class="ml-90">            <span class="color-pink">return</span> value;</p>
                        <p class="ml-60">        }</p>
                        <p class="ml-30">    }</p>
                        <p class="ml-30">    <span class="color-pink">return</span> <span class="color-blue">null</span>;</p>
                        <p>}</p>
                        <br />
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Username Cookie: "</span> + <span class="color-red">getCookieValue</span>(<span class="color-green">"username"</span>));</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Update a Cookie</h5>
                <p>
                    You can update a cookie by setting it again with the same name but with a new value or updated attributes. For Example:
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>document.<span class="color-pink">cookie</span> = <span class="color-green">"theme=light; expires=Fri, 31 Dec 2024 23:59:59 UTC"</span>;</p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Updated Cookie: "</span> + document.<span class="color-pink">cookie</span>);</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Delete a Cookie</h5>
                <p>To delete a cookie, set its expiration date to a past date.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>document.<span class="color-pink">cookie</span> = <span class="color-green">"username=; expires=Thu, 01 Jan 1970 00:00:00 UTC"</span>;</p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Cookie Deleted: "</span> + document.<span class="color-pink">cookie</span>);</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Important Cookie Attributes</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <span className='background-grey'>expires</span>
                        <p>It sets the expiration date for the cookie in UTC format. If not set, the cookie will be deleted when the browser is closed.</p>
                    </li>
                    <li>
                        <span className='background-grey'>max-age</span>
                        <p>It specifies the cookie's lifespan in seconds. For example, <span className='background-grey'>max-age=3600</span> sets the cookie to expire in 1 hour.</p>
                    </li>
                    <li>
                        <span className='background-grey'>path</span>
                        <p>It specifies the URL path where the cookie is valid. Defaults to the current page.</p>
                    </li>
                    <li>
                        <span className='background-grey'>domain</span>
                        <p>It specifies the domain where the cookie is valid.</p>
                    </li>
                    <li>
                        <span className='background-grey'>secure</span>
                        <p>It ensures the cookie is sent only over HTTPS.</p>
                    </li>
                    <li>
                        <span className='background-grey'>HttpOnly</span>
                        <p>It restricts access to the cookie from JavaScript (not accessible through <span className='background-grey'>document.cookie</span>). This is set on the server side.</p>
                    </li>
                </ul>


            </div>
        </section>
    )
}