import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/js-history";

export default function HistoryObject() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Window History Object in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/js-location',
            'next': '/javascript/js-navigator'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Window History Object</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className='background-grey'>history</span> object in JavaScript provides access to the browser's session history. It allows developers to navigate the user through the history of visited pages (both backward and forward) or perform specific tasks like jumping to a specific point in history.
                </p>

                <h5 className='mt-5 mb-3'>Key Methods of the <span className='background-grey'>history</span> Object</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li className='operator mt-5'>
                        <h4><span className='background-grey'>history.back()</span></h4>
                        <p className='mt-4 mb-0'>It navigates to the previous page in the browser history (equivalent to pressing the back button). For example:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>history.back();</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h4><span className='background-grey'>history.forward()</span></h4>
                        <p className='mt-4 mb-0'>It navigates to the next page in the browser history (equivalent to pressing the forward button). For example:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>history.forward();</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h4><span className='background-grey'>history.go(n)</span></h4>
                        <p className='mt-4 mb-0'>It navigates to a specific page in the history where, <span className='background-grey'>n</span> can be a positive number (to move forward) or a negative number (to move backward).</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>history.go(-1); // Go one page back</p>
                                <p>history.go(2);  // Go two pages forward</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h4><span className='background-grey'>history.pushState(state, title, url)</span></h4>
                        <p className='mt-4 mb-0'>It adds a new entry to the browser's history stack without reloading the page.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>history.pushState({ page: 1 }, "Page 1", "/page1");</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h4><span className='background-grey'>history.replaceState(state, title, url)</span></h4>
                        <p className='mt-4 mb-0'>It modifies the current history entry without creating a new one.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>history.replaceState({ page: 2 }, "Page 2", "/page2");</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>Key Properties of the <span className='background-grey'>history</span> Object</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li className='operator mt-5'>
                        <h4><span className='background-grey'>history.length</span></h4>
                        <p className='mt-4 mb-0'>It returns the number of entries in the session history, including the current page.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>console.log("History Length: " + history.length);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


                <h5 className='mt-5 mb-5'>Use Cases of the <span className='background-grey'>history</span> Object</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Single Page Applications (SPAs):</strong>
                        <p>Use <span className='background-grey'>pushState()</span> and <span className='background-grey'>replaceState()</span> for managing navigation without reloading the page.</p>
                    </li>
                    <li>
                        <strong>Custom Navigation:</strong>
                        <p>Provide users with buttons or links to navigate through the session history.</p>
                    </li>
                    <li>
                        <strong>URL Management:</strong>
                        <p>Dynamically update the URL for better user experience or to reflect the current app state.</p>
                    </li>
                </ul>



            </div>
        </section>
    )
}