import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/js-navigator";

export default function NavigatorObject() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Window Navigator Object in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/js-history',
            'next': '/javascript/timing'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Window Navigator Object</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className='background-grey'>navigator</span> object in JavaScript provides information about the web browser and the user's system. It is part of the <span className='background-grey'>window</span> object and allows you to access properties and methods to gather data such as the browser type, version, operating system, and device capabilities.
                </p>

                <h5 className='mt-5 mb-5'>Key Properties of the <span className='background-grey'>navigator</span> Object</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li className='operator mt-5'>
                        <h4><span className='background-grey'>navigator.userAgent</span></h4>
                        <p className='mt-4 mb-0'>It returns a string containing information about the browser and operating system. For example:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"User Agent: "</span> + navigator.<span class="color-pink">userAgent</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h4><span className='background-grey'>navigator.platform</span></h4>
                        <p className='mt-4 mb-0'>It returns the platform (operating system) on which the browser is running. For example,</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Platform: "</span> + navigator.<span class="color-pink">platform</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h4><span className='background-grey'>navigator.language</span></h4>
                        <p className='mt-4 mb-0'>It returns the preferred language of the user's browser.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Language: "</span> + navigator.<span class="color-pink">language</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h4><span className='background-grey'>navigator.cookieEnabled</span></h4>
                        <p className='mt-4 mb-0'>It indicates whether cookies are enabled in the browser (<span className='background-grey'>true</span> or <span className='background-grey'>false</span>).</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Cookies Enabled: "</span> + navigator.<span class="color-pink">cookieEnabled</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h4><span className='background-grey'>navigator.onLine</span></h4>
                        <p className='mt-4 mb-0'>It indicates whether the browser is online (<span className='background-grey'>true</span>) or offline (<span className='background-grey'>false</span>).</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Online Status: "</span> + navigator.<span class="color-pink">onLine</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h4><span className='background-grey'>navigator.geolocation</span></h4>
                        <p className='mt-4 mb-0'>It provides access to the device's geographical location (requires user permission).</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">if</span> (navigator.<span class="color-pink">geolocation</span>) {</p>
                                <p class="ml-30">    navigator.geolocation.<span class="color-red">getCurrentPosition</span>(position => {</p>
                                <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Latitude: "</span> + position.coords.<span class="color-pink">latitude</span>);</p>
                                <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Longitude: "</span> + position.coords.<span class="color-pink">longitude</span>);</p>
                                <p class="ml-30">    });</p>
                                <p>} else {</p>
                                <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Geolocation is not supported by this browser."</span>);</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h4><span className='background-grey'>navigator.hardwareConcurrency</span></h4>
                        <p className='mt-4 mb-0'>It returns the number of logical processor cores available.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Cores: "</span> + navigator.<span class="color-pink">hardwareConcurrency</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h4><span className='background-grey'>navigator.maxTouchPoints</span></h4>
                        <p className='mt-4 mb-0'>It returns the maximum number of touch points supported by the device.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Max Touch Points: "</span> + navigator.<span class="color-pink">maxTouchPoints</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


                <h5 className='mt-5 mb-5'>Key Methods of the <span className='background-grey'>navigator</span> Object</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li className='operator mt-5'>
                        <h4><span className='background-grey'>navigator.javaEnabled()</span></h4>
                        <p className='mt-4 mb-0'>It checks whether Java is enabled in the browser. For example:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Java Enabled: "</span> + navigator.<span class="color-red">javaEnabled</span>());</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h4><span className='background-grey'>navigator.clipboard</span></h4>
                        <p className='mt-4 mb-0'>It provides access to the clipboard (e.g., copy and paste functionality). For example:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>navigator.clipboard.<span class="color-red">writeText</span>(<span class="color-green">"Hello World"</span>).<span class="color-red">then</span>(() => {</p>
                                <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Text copied to clipboard!"</span>);</p>
                                <p>});</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


            </div>
        </section>
    )
}