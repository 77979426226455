import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/event-handling";

export default function EventHandling() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Event Handling in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/changing-content-and-styles',
            'next': '/javascript/window-object'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Event Handling</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In JavaScript, <strong>event handling</strong> refers to the process of capturing user interactions, such as clicks, keystrokes, or mouse movements, and then running specific code in response to those interactions.
                </p>
                <p>
                    Events make web pages interactive and dynamic. For example, when you click a button, an event occurs, and JavaScript can handle it to perform an action like showing a message.
                </p>


                <h5 className='mt-5 mb-3'>What is an Event?</h5>
                <p>An <strong>event</strong> is any interaction or action that occurs in the browser. Examples:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Clicking a button (<span className='background-grey'>click</span> <strong>event</strong>)</li>
                    <li>Moving the mouse (<span className='background-grey'>mousemove</span> <strong>event</strong>)</li>
                    <li>Pressing a key on the keyboard (<span className='background-grey'>keydown</span> <strong>event</strong>)</li>
                    <li>Submitting a form (<span className='background-grey'>submit</span> <strong>event</strong>)</li>
                </ul>


                <h5 className='mt-5 mb-3'>Steps for Event Handling</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>Select the element you want to attach the event to.</li>
                    <li>Add an event listener to the element.</li>
                    <li>Define the function to execute when the event occurs.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Event Handlers Using HTML Attributes</h5>
                <p>The simplest way to handle an event is by using an HTML attribute like <span className='background-grey'>onclick</span>.</p>
                <p>For Example:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;button <span class="color-pink">onclick</span>=<span class="color-green">"sayHello()"</span>&gt;Click Me&lt;/button&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">sayHello</span>() {</p>
                        <p class="ml-60">        <span class="color-red">alert</span>(<span class="color-green">"Hello, World!"</span>);</p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Event Handlers Using JavaScript (<span className='background-grey'>on</span> Properties)</h5>
                <p>You can also add event handlers directly using JavaScript. For Example:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;button <span class="color-pink">id</span>=<span class="color-green">"myButton"</span>&gt;Click Me&lt;/button&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">    <span class="color-blue">let</span> button = document.<span class="color-red">getElementById</span>(<span class="color-green">"myButton"</span>);</p>
                        <br />
                        <p class="ml-30 color-grey">    // Add a click event handler</p>
                        <p class="ml-30">    button.<span class="color-red">onclick</span> = <span class="color-red">function</span>() {</p>
                        <p class="ml-60">        <span class="color-red">alert</span>(<span class="color-green">"Button clicked!"</span>);</p>
                        <p class="ml-30">    };</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Event Listeners (Preferred Method)</h5>
                <p>Using the <span className='background-grey'>addEventListener()</span> method is the most flexible and modern way to handle events. For Example:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;button <span class="color-pink">id</span>=<span class="color-green">"myButton"</span>&gt;Click Me&lt;/button&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">    <span class="color-blue">let</span> button = document.<span class="color-red">getElementById</span>(<span class="color-green">"myButton"</span>);</p>
                        <br />
                        <p class="ml-30 color-grey">    // Add a click event listener<p>
                        <p class="ml-30">    button.<span class="color-red">addEventListener</span>(<span class="color-green">"click"</span>, <span class="color-red">function</span>() {</p>
                        <p class="ml-60">        <span class="color-red">alert</span>(<span class="color-green">"You clicked the button!"</span>);</p>
                        <p class="ml-30">    });</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>
                <strong>Why Use <span className='background-grey'>addEventListener()</span>?</strong>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Allows multiple event handlers on the same element.</li>
                    <li>Offers more control over event handling (e.g., removing listeners).</li>
                </ul>


                <h5 className='mt-5 mb-3'>Removing Event Listeners</h5>
                <p>To remove an event listener, use the <span className='background-grey'>removeEventListener()</span> method. For example:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;button <span class="color-pink">id</span>=<span class="color-green">"myButton"</span>&gt;Click Me&lt;/button&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">    <span class="color-blue">let</span> button = document.<span class="color-red">getElementById</span>(<span class="color-green">"myButton"</span>);</p>
                        <br />
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">handleClick</span>() {</p>
                        <p class="ml-60">        <span class="color-red">alert</span>(<span class="color-green">"Button clicked!"</span>);</p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30 color-grey">    // Add event listener</p>
                        <p class="ml-30">    button.<span class="color-red">addEventListener</span>(<span class="color-green">"click"</span>, handleClick);</p>
                        <br />
                        <p class="ml-30 color-grey">    // Remove event listener after 5 seconds</p>
                        <p class="ml-30">    <span class="color-red">setTimeout</span>(() => {</p>
                        <p class="ml-60">        button.<span class="color-red">removeEventListener</span>(<span class="color-green">"click"</span>, handleClick);</p>
                        <p class="ml-60">        <span class="color-red">alert</span>(<span class="color-green">"Event listener removed."</span>);</p>
                        <p class="ml-30">    }, <span class="color-pink">5000</span>);</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Some Example of Event Handling</h5>
                <h6 className='mt-3'>Example 1: Handling Mouse Events</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"hoverBox"</span> <span class="color-pink">style</span>=<span class="color-green">"width: 100px; height: 100px; background-color: red;"</span>&gt;&lt;/div&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">    <span class="color-blue">let</span> box = document.<span class="color-red">getElementById</span>(<span class="color-green">"hoverBox"</span>);</p>
                        <br />
                        <p class="ml-30 color-grey">    // Change color on mouse over</p>
                        <p class="ml-30">    box.<span class="color-red">addEventListener</span>(<span class="color-green">"mouseover"</span>, <span class="color-red">function</span>() {</p>
                        <p class="ml-60">        box.style.backgroundColor = <span class="color-green">"blue"</span>;</p>
                        <p class="ml-30">    });</p>
                        <br />
                        <p class="ml-30 color-grey">    // Change back color on mouse out</p>
                        <p class="ml-30">    box.<span class="color-red">addEventListener</span>(<span class="color-green">"mouseout"</span>, <span class="color-red">function</span>() {</p>
                        <p class="ml-60">        box.style.backgroundColor = <span class="color-green">"red"</span>;</p>
                        <p class="ml-30">    });</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>


                <h6 className='mt-3'>Example 2: Handling Keyboard Events</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">id</span>=<span class="color-green">"inputField"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Type something"</span>&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">    <span class="color-blue">let</span> input = document.<span class="color-red">getElementById</span>(<span class="color-green">"inputField"</span>);</p>
                        <br />
                        <p class="ml-30 color-grey">    // Log the key pressed</p>
                        <p class="ml-30">    input.<span class="color-red">addEventListener</span>(<span class="color-green">"keydown"</span>, <span class="color-red">function</span>(event) {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Key pressed:"</span>, event.key);</p>
                        <p class="ml-30">    });</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>


                <h6 className='mt-3'>Example 3: Handling Form Submission</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;form <span class="color-pink">id</span>=<span class="color-green">"myForm"</span>&gt;</p>
                        <p class="ml-30">    &lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">id</span>=<span class="color-green">"name"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Enter your name"</span> <span class="color-pink">required</span>&gt;</p>
                        <p class="ml-30">    &lt;button <span class="color-pink">type</span>=<span class="color-green">"submit"</span>&gt;Submit&lt;/button&gt;</p>
                        <p>&lt;/form&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">    <span class="color-blue">let</span> form = document.<span class="color-red">getElementById</span>(<span class="color-green">"myForm"</span>);</p>
                        <br />
                        <p class="ml-30 color-grey">    // Handle form submission</p>
                        <p class="ml-30">    form.<span class="color-red">addEventListener</span>(<span class="color-green">"submit"</span>, <span class="color-red">function</span>(event) {</p>
                        <p class="ml-60">        event.<span class="color-red">preventDefault</span>(); <span class="color-grey">// Prevent form from refreshing the page</span></p>
                        <p class="ml-60">        <span class="color-blue">let</span> name = document.<span class="color-red">getElementById</span>(<span class="color-green">"name"</span>).value;</p>
                        <p class="ml-60">        <span class="color-red">alert</span>(<span class="color-green">"Hello, "</span> + name + <span class="color-green">"!"</span>);</p>
                        <p class="ml-30">    });</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>



            </div>
        </section>
    )
}