import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/numbers";

export default function Numbers() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Numbers in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/string-templates',
            'next': '/javascript/number-methods'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Numbers</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In JavaScript, the <strong>Number</strong> data type is used to represent numerical values, including integers and floating-point numbers. Unlike some programming languages that differentiate between integers and floats, JavaScript uses a single <span className='background-grey'>number</span> type for all numerical values.
                </p>

                <h5 className='mt-5 mb-3'>Key Characteristics of Numbers in JavaScript</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <strong>Single Number Type</strong>: JavaScript does not have separate types for integers and floating-point numbers; both are represented by the <span className='background-grey'>number</span> type.
                    </li>
                    <li>
                        <strong>Double-Precision Format</strong>: Numbers are stored in a 64-bit double-precision format, following the IEEE 754 standard. 
                    </li>
                    <li>
                        <strong>Range</strong>: The <span className='background-grey'>number</span> type can represent values between approximately ±(2^53 - 1).
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>Creating Numbers</h5>
                <p>You can create numbers by assigning numerical values directly to variables:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> integerNumber = <span class="color-pink">42</span>;       <span class="color-grey"> // Integer</span></p>
                        <p><span class="color-blue">let</span> floatNumber = <span class="color-pink">3.14</span>;        <span class="color-grey">// Floating-point number</span></p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Special Numerical Values</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <strong>Infinity</strong>: Represents a value greater than any other number.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> positiveInfinity = <span class="color-blue">Infinity</span>;</p>
                                <p><span class="color-blue">let</span> negativeInfinity = <span class="color-blue">-Infinity</span>;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>NaN (Not-a-Number)</strong>: Indicates an invalid number, often resulting from undefined mathematical operations.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">let</span> notANumber = <span class="color-blue">NaN</span>;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>Type Conversion</h5>
                <p>JavaScript can convert other data types to numbers:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <strong>Using</strong> <span className='background-grey'>Number()</span>:
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> str = <span class="color-green">"123"</span>;</p>
                                <p><span class="color-blue">let</span> num = <span class="color-red">Number</span>(str); <span class="color-grey">// Converts string to number</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Using Unary Plus</strong> (<span className='background-grey'>+</span>):
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> str = <span class="color-green">"123"</span>;</p>
                                <p><span class="color-blue">let</span> num = <span class="color-pink">+</span>str; <span class="color-grey">// Converts string to number</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>



            </div>
        </section>
    )
}