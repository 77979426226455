import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/date-set-methods";

export default function DateSetMethods() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Date Set Methods in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/date-get-methods',
            'next': '/javascript/strings'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Date Set Methods</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>Date</strong> <span className='background-grey'>set</span> <strong>methods</strong> in JavaScript allow you to change or modify parts of a date, such as the year, month, day, hours, minutes, seconds, or milliseconds. These methods are useful when you need to update a date or time programmatically.
                </p>

                <h5 className='mt-5 mb-3'>Examples</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Set the Year</strong> (<span className='background-grey'>setFullYear()</span>)
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> date = <span class="color-blue">new</span> <span class="color-red">Date</span>();</p>
                                <p>date.<span class="color-red">setFullYear</span>(<span class="color-pink">2025</span>);</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(date); </p>
                                <p class="color-grey">// Output: Date with the year updated to 2025</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Set the Month</strong> (<span className='background-grey'>setMonth()</span>)
                        <p>Here, Month is 0-based (January = 0, February = 1, ..., December = 11).</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> date = <span class="color-blue">new</span> <span class="color-red">Date</span>();</p>
                                <p>date.<span class="color-red">setMonth</span>(<span class="color-pink">10</span>); <span class="color-grey">// Sets to November</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(date); </p>
                                <p class="color-grey">// Output: Date with the month updated to November</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Set the Day of the Month</strong> (<span className='background-grey'>setDate()</span>)
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> date = <span class="color-blue">new</span> <span class="color-red">Date</span>();</p>
                                <p>date.<span class="color-red">setDate</span>(<span class="color-pink">15</span>); <span class="color-grey">// Sets the day to the 15th</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</pan>(date); </p>
                                <p class="color-grey">// Output: Date with the day updated to 15</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Set the Time (Hours, Minutes, Seconds)</strong>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> date = <span class="color-blue">new</span> <span class="color-red">Date</span>();</p>
                                <p>date.<span class="color-red">setHours</span>(<span class="color-pink">22</span>);    <span class="color-grey">// Sets the time to 10 PM</span></p>
                                <p>date.<span class="color-red">setMinutes</span>(<span class="color-pink">45</span>);  <span class="color-grey">// Sets minutes to 45</span></p>
                                <p>date.<span class="color-red">setSeconds</span>(<span class="color-pink">30</span>);  <span class="color-grey">// Sets seconds to 30</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(date); </p>
                                <p class="color-grey">// Output: Date with time updated to 22:45:30</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Set Milliseconds Since Epoch</strong> (<span className='background-grey'>setTime()</span>)
                        <p>It sets the entire date based on the number of milliseconds since <strong>January 1, 1970 (UTC)</strong>.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> date = <span class="color-blue">new</span> <span class="color-red">Date</span>();</p>
                                <p>date.<span class="color-red">setTime</span>(<span class="color-pink">1734970200000</span>); </p>
                                <p>console.log(date); </p>
                                <p class="color-grey">// Output: "2024-11-29T10:30:00.000Z" (specific date based on the milliseconds)</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Set UTC Values</strong>
                        <p>You can use <span className='background-grey'>setUTC...</span> methods to update the date in <strong>Universal Coordinated Time (UTC)</strong>.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> date = <span class="color-blue">new</span> <span class="color-red">Date</span>();</p>
                                <p>date.<span class="color-red">setUTCFullYear</span>(<span class="color-pink">2025</span>);</p>
                                <p>date.<span class="color-red">setUTCMonth</span>(<span class="color-pink">10</span>);  <span class="color-grey">// November</span></p>
                                <p>date.<span class="color-red">setUTCDate</span>(<span class="color-pink">15</span>);</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(date.<span class="color-red">toUTCString</span>()); </p>
                                <p class="color-grey">// Output: "Sat, 15 Nov 2025 00:00:00 GMT"</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>



            </div>
        </section>
    )
}