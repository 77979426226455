import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/js-2015";

export default function Js2015() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("JavaScript 2015 (ES6) | Aspirant's Home");
        const urls = {
            'previous': '/javascript/js-2009',
            'next': '/javascript/js-2016'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>JavaScript 2015 (ES6)</h3>
            <div className='mt-4 mb-5'>
                <p>
                    <strong>ES6</strong>, also known as <strong>ECMAScript 2015</strong>, is one of the most significant updates to JavaScript, introducing several new features and improvements that modernized the language. Released in <strong>2015</strong>, ES6 improved JavaScript's syntax, readability, and developer productivity.
                </p>

                <h5 className='mt-5 mb-3'>ES6 Features</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li><a href="#let-keyword">The let keyword</a></li>
                    <li><a href="#const-keyword">The const keyword</a></li>
                    <li><a href="#arrow-function">Arrow Functions</a></li>
                    <li><a href="#object-destructuring">The &#123;a,b&#125; = Operator</a></li>
                    <li><a href="#array-destructuring">The [a,b] = Operator</a></li>
                    <li><a href="#spread-operator">The ... Operator</a></li>
                    <li><a href="#for-of">For/of</a></li>
                    <li><a href="#map-objects">Map Objects</a></li>
                    <li><a href="#set-objects">Set Objects</a></li>
                    <li><a href="#classes">Classes</a></li>
                    <li><a href="#promises">Promises</a></li>
                    <li><a href="#symbol">Symbol</a></li>
                    <li><a href="#default-parameters">Default Parameters</a></li>
                    <li><a href="#function-rest-parameter">Function Rest Parameter</a></li>
                    <li><a href="#string-includes">String.includes()</a></li>
                    <li><a href="#string-startswith">String.startsWith()</a></li>
                    <li><a href="#string-endswith">String.endsWith()</a></li>
                    <li><a href="#array-entries">Array entries()</a></li>
                    <li><a href="#array-from">Array.from()</a></li>
                    <li><a href="#keys">Array keys()</a></li>
                    <li><a href="#find">Array find()</a></li>
                    <li><a href="#find-index">Array findIndex()</a></li>
                    <li><a href="#trunc">Math.trunc</a></li>
                    <li><a href="#sign">Math.sign</a></li>
                    <li><a href="#cbrt">Math.cbrt</a></li>
                    <li><a href="#log2">Math.log2</a></li>
                    <li><a href="#log10">Math.log10</a></li>
                    <li><a href="#epsilon">Number.EPSILON</a></li>
                    <li><a href="#min-safe-integer">Number.MIN_SAFE_INTEGER</a></li>
                    <li><a href="#max-safe-integer">Number.MAX_SAFE_INTEGER</a></li>
                    <li><a href="#is-integer">Number.isInteger()</a></li>
                    <li><a href="#is-safe-integer">Number.isSafeInteger()</a></li>
                    <li><a href="#new-global-methods">New Global Methods</a></li>
                    <li><a href="#javascript-modules">JavaScript Modules</a></li>
                </ul>


                <div id="let-keyword">
                    <h5 className='mt-5 mb-3'>JavaScript let keyword</h5>
                    <p>The <span className='background-grey'>let</span> keyword in JavaScript is used to declare variables that are <strong>block-scoped</strong>, meaning they are only accessible within the block, statement, or expression where they are defined.</p>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p>{</p>
                            <p class="ml-30">    <span class="color-blue">let</span> x = <span class="color-pink">10</span>;</p>
                            <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(x); <span class="color-grey">// Output: 10</span></p>
                            <p>}</p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(x); <span class="color-grey">// Error: x is not defined</span></p>
                            `
                        }}></div>
                    </div>
                    <p>Here, variables declared with let are only accessible within the enclosing {} block.</p>
                </div>

                <div id="const-keyword">
                    <h5 className='mt-5 mb-3'>JavaScript const keyword</h5>
                    <p>The <span className='background-grey'>const</span> keyword in JavaScript is used to declare <strong>variables with constant values</strong>. Once a variable is declared using <span className='background-grey'>const</span>, its value <strong>cannot be reassigned</strong>. </p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> num = <span class="color-pink">42</span>;</p>
                            <p>num = <span class="color-pink">100</span>; <span class="color-grey">// Error: Assignment to constant variable</span></p>
                            `
                        }}></div>
                    </div>
                    <p>However, if the value is an object or array, its properties or elements <strong>can still be modified</strong>.</p>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> person = { <span class="color-pink">name</span>: <span class="color-green">"Alice"</span> };</p>
                            <p>person.<span class="color-pink">name</span> = <span class="color-green">"Bob"</span>; <span class="color-grey">// Allowed</span></p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(person.<span class="color-pink">name</span>); <span class="color-grey">// Output: Bob</span></p>
                            <br />
                            <p><span class="color-blue">const</span> numbers = [<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>];</p>
                            <p>numbers.<span class="color-red">push</span>(<span class="color-pink">4</span>); <span class="color-grey">// Allowed</span></p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(numbers); <span class="color-grey">// Output: [1, 2, 3, 4]</span></p>
                            `
                        }}></div>
                    </div>
                </div>

                <div id="arrow-function">
                    <h5 className='mt-5 mb-3'>Arrow Functions</h5>
                    <p>Arrow functions, introduced in <strong>ES6</strong>, provide a concise syntax for writing functions. They are written using the <span className='background-grey'>=&gt;</span> (arrow) operator and are especially useful for simplifying callbacks and functional programming tasks.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p class="color-grey">// Traditional function</p>
                            <p><span class="color-blue">const</span> add = <span class="color-red">function</span> (a, b) {</p>
                            <p class="ml-30">  <span class="color-pink">return</span> a + b;</p>
                            <p>};</p>
                            <br />
                            <p class="color-grey">// Arrow function</p>
                            <p><span class="color-blue">const</span> add = (a, b) <span class="color-blue">=></span> a + b;</p>
                            `
                        }}></div>
                    </div>
                </div>

                <div id="object-destructuring">
                    <h5 className='mt-5 mb-3'>Object Destructuring</h5>
                    <p>Object destructuring is a feature in JavaScript (introduced in <strong>ES6</strong>) that allows you to extract properties from objects and assign them to variables in a clean and concise way.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> person = { <span class="color-pink">name</span>: <span class="color-green">"Alice"</span>, <span class="color-pink">age</span>: <span class="color-pink">25</span>, <span class="color-pink">city</span>: <span class="color-green">"New York"</span> };</p>
                            <br/>
                            <p><span class="color-blue">const</span> { name, age } = person;</p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(name); <span class="color-grey">// Output: Alice</span></p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(age);  <span class="color-grey">// Output: 25</span></p>
                            `
                        }}></div>
                    </div>
                    <p>Destructuring allows you to extract specific properties from an object and assign them to variables <strong>with the same name as the property</strong>.</p>
                    <p>In this example:</p>
                    <ul style={{ listStyle: 'disc' }}>
                        <li>The <span className='background-grey'>name</span> property in the <span className='background-grey'>person</span> object corresponds to the <span className='background-grey'>name</span> variable.</li>
                        <li>The <span className='background-grey'>age</span> property corresponds to the <span className='background-grey'>age</span> variable.</li>
                        <li>The <span className='background-grey'>city</span> property is not destructured, so it is ignored.</li>
                    </ul>
                </div>


                <div id="array-destructuring">
                    <h5 className='mt-5 mb-3'>Array Destructuring</h5>
                    <p>Array destructuring is a feature in JavaScript (introduced in <strong>ES6</strong>) that allows you to unpack values from arrays and assign them to variables in a concise way.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> colors = [<span class="color-green">"red"</span>, <span class="color-green">"green"</span>, <span class="color-green">"blue"</span>];</p>
                            <br />
                            <p><span class="color-blue">const</span> [first, second] = colors;</p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(first); <span class="color-grey">// Output: red</span></p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(second); <span class="color-grey">// Output: green</span></p>
                            `
                        }}></div>
                    </div>

                </div>


                <div id="spread-operator">
                    <h5 className='mt-5 mb-3'>The Spread (...) Operator</h5>
                    <p>The <strong>spread operator (...)</strong> is a powerful feature in JavaScript (introduced in <strong>ES6</strong>) that allows you to expand or "spread" elements of an array, object, or iterable into individual elements or properties. It is useful for copying, merging, or combining data structures.</p>
                    <h6 className='mt-3'>Example: Copying an Array</h6>
                    <p>It creates a shallow copy of an array.</p>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> numbers = [<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>];</p>
                            <p><span class="color-blue">const</span> copy = [<span class="color-pink">...numbers</span>];</p>
                            <br />
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(copy); <span class="color-grey">// Output: [1, 2, 3]</span></p>
                            `
                        }}></div>
                    </div>
                </div>


                <div id="for-of">
                    <h5 className='mt-5 mb-3'>The For/Of Loop</h5>
                    <p>The <span className='background-grey'>for...of</span> <strong>loop</strong> is a modern JavaScript feature (introduced in <strong>ES6</strong>) that allows you to iterate over <strong>iterable objects</strong> like arrays, strings, Maps, Sets, and more. It provides a simpler and more readable way to access the elements of an iterable.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> numbers = [<span class="color-pink">10</span>, <span class="color-pink">20</span>, <span class="color-pink">30</span>, <span class="color-pink">40</span>];</p>
                            <br />
                            <p><span class="color-blue">for</span> (<span class="color-blue">const</span> number <span class="color-pink">of</span> numbers) {</p>
                            <p class="ml-30">  <span class="color-pink">console</span>.<span class="color-red">log</span>(number);</p>
                            <p>}</p>
                            <p class="color-grey">// Output:</p>
                            <p class="color-grey">// 10</p>
                            <p class="color-grey">// 20</p>
                            <p class="color-grey">// 30</p>
                            <p class="color-grey">// 40</p>
                            `
                        }}></div>
                    </div>
                </div>

                <div id="map-objects">
                    <h5 className='mt-5 mb-3'>JavaScript Maps</h5>
                    <p>A <span className='background-grey'>Map</span> in JavaScript is a collection of <strong>key-value pairs</strong> where keys can be of any type (objects, functions, or primitives). It was introduced in <strong>ES6</strong> and is an improved alternative to plain JavaScript objects when working with key-value pairs.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> map = <span class="color-blue">new</span> <span class="color-red">Map</span>([</p>
                            <p class="ml-30">    [<span class="color-green">'name'</span>, <span class="color-green">'Alice'</span>],</p>
                            <p class="ml-30">    [<span class="color-green">'age'</span>, <span class="color-pink">25</span>],</p>
                            <p>]);</p>
                            <br />  
                            <p><span class="color-blue">for</span> (<span class="color-blue">const</span> [key, value] <span class="color-pink">of</span> map) {</p>
                            <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">&#96;$&#123;key&#125;: $&#123;value&#125;&#96;</span>);</p>
                            <p>}</p>
                            <p class="color-grey">// Output:</p>
                            <p class="color-grey">// name: Alice</p>
                            <p class="color-grey">// age: 25</p>
                            `
                        }}></div>
                    </div>
                </div>


                <div id="set-objects">
                    <h5 className='mt-5 mb-3'>JavaScript Sets</h5>
                    <p>A <span className='background-grey'>Set</span> in JavaScript is a collection of <strong>unique values</strong>. It was introduced in <strong>ES6</strong> and allows you to store any type of value, whether primitive or object references, with the key feature being that <strong>duplicate values are not allowed</strong>.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p class="color-grey">// Create a Set</p>
                            <p><span class="color-blue">const</span> letters = <span class="color-blue">new</span> <span class="color-red">Set</span>();</p>
                            <br />
                            <p class="color-grey">// Add some values to the Set</p>
                            <p>letters.<span class="color-red">add</span>(<span class="color-green">"a"</span>);</p>
                            <p>letters.<span class="color-red">add</span>(<span class="color-green">"b"</span>);</p>
                            <p>letters.<span class="color-red">add</span>(<span class="color-green">"c"</span>);</p>
                            `
                        }}></div>
                    </div>
                </div>


                <div id="classes">
                    <h5 className='mt-5 mb-3'>JavaScript Classes</h5>
                    <p>Classes in JavaScript are a blueprint for creating objects. They were introduced in <strong>ES6</strong> to provide a cleaner and more structured way to create and manage objects and their behavior compared to using traditional constructor functions.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p>class Person {</p>
                            <p class="ml-30">    constructor(name, age) {</p>
                            <p class="ml-60">          this.name = name;</p>
                            <p class="ml-60">          this.age = age;</p>
                            <p class="ml-30">    }</p>
                            <br />  
                            <p class="ml-30">    greet() {</p>
                            <p class="ml-60">         console.log(&#96;Hi, I'm $&#123;this.name&#125; and I'm $&#123;this.age&#125; years old.&#96;);</p>
                            <p class="ml-30">    }</p>
                            <p>}</p>
                            <br />  
                            <p>const person1 = new Person('Alice', 25);</p>
                            <p>person1.greet(); // Output: Hi, I'm Alice and I'm 25 years old.</p>
                            `
                        }}></div>
                    </div>
                </div>


            </div>
        </section>
    )
}