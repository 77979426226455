import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/js-screen";

export default function ScreenObject() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Window Screen Object in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/window-object',
            'next': '/javascript/js-location'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Window Screen Object</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className='background-grey'>screen</span> object in JavaScript provides information about the user's screen. It is a property of the <span className='background-grey'>window</span> object and contains details such as the screen's width, height, available area, and color depth. This object is useful for determining the display capabilities of the user's device.
                </p>

                <h5 className='mt-5 mb-3'>Key Properties of the <span className='background-grey'>screen</span> Object</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <span className='background-grey'>screen.width</span>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>The width (in pixels) of the user's screen.</li>
                            <li>
                                <p>Example:</p>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Screen Width: "</span> + screen.width);</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span className='background-grey'>screen.height</span>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>The height (in pixels) of the user's screen.</li>
                            <li>
                                <p>Example:</p>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Screen Height: "</span> + screen.height);</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span className='background-grey'>screen.availWidth</span>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>The width (in pixels) of the screen's available area, excluding space taken by operating system features like the taskbar.</li>
                            <li>
                                <p>Example:</p>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Available Screen Width: "</span> + screen.availWidth);</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span className='background-grey'>screen.availHeight</span>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>The height (in pixels) of the screen's available area, excluding space taken by operating system features.</li>
                            <li>
                                <p>Example:</p>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Available Screen Height: "</span> + screen.availHeight);</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span className='background-grey'>screen.colorDepth</span>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>The number of bits used to display a single color on the user's screen (e.g., 24 for "True Color").</li>
                            <li>
                                <p>Example:</p>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Color Depth: "</span> + screen.colorDepth + <span class="color-green">" bits"</span>);</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span className='background-grey'>screen.pixelDepth</span>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>The number of bits used to represent the color of a single pixel (similar to <span className='background-grey'>colorDepth</span>).</li>
                            <li>
                                <p>Example:</p>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Pixel Depth: "</span> + screen.pixelDepth + <span class="color-green">" bits"</span>);</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>


            </div>
        </section>
    )
}