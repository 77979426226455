import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/changing-content-and-styles";

export default function ChangingContent() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Changing Content and Styles in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/selecting-elements',
            'next': '/javascript/event-handling'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Changing Content and Styles</h3>
            <div className='mt-4 mb-5'>
                <p>
                    JavaScript allows you to change the content and styles of HTML elements dynamically. This is useful for creating interactive and responsive web pages.
                </p>

                <h5 className='mt-5 mb-3'>To Changing Content</h5>
                <p>To change the content of an HTML element, we use the following properties:</p>

                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <span className='background-grey'>textContent</span>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>It updates or gets the text inside an element.</li>
                            <li>It does <strong>not</strong> include HTML tags.</li>
                        </ul>
                        <h6>Example:</h6>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;p <span class="color-pink">id</span>=<span class="color-green">"example"</span>&gt;Hello, World!&lt;/p&gt;</p>
                                <br />
                                <p>&lt;script&gt;</p>
                                <p class="ml-30">    <span class="color-blue">let</span> element = document.<span class="color-blue">getElementById</span>(<span class="color-green">"example"</span>);</p>
                                <p class="ml-30">    element.textContent = <span class="color-green">"Hello, JavaScript!"</span>;</p>
                                <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(element.textContent); <span class="color-grey">// Output: Hello, JavaScript!</span></p>
                                <p>&lt;/script&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>innerHTML</span>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>It updates or gets the <strong>HTML content</strong> inside an element.</li>
                            <li>It can include HTML tags.</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"container"</span>&gt;Old content&lt;/div&gt;</p>
                                <br />
                                <p>&lt;script&gt;</p>
                                <p class="ml-30">    <span class="color-blue">let</span> element = document.<span class="color-red">getElementById</span>(<span class="color-green">"container"</span>);</p>
                                <p class="ml-30">    element.innerHTML = <span class="color-green">"&lt;strong&gt;New content with bold text!&lt;/strong&gt;"</span>;</p>
                                <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(element.innerHTML); <span class="color-grey">// Output: <strong>New content with bold text!</strong></span></p>
                                <p>&lt;/script&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>innerText</span>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Similar to <span className='background-grey'>textContent</span> but respects the styling of the element (e.g., hidden text won’t be retrieved).</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;p <span class="color-pink">id</span>=<span class="color-green">"hidden"</span> <span class="color-pink">style</span>=<span class="color-green">"display: none;"</span>&gt;This is hidden.&lt;/p&gt;</p>
                                <br />
                                <p>&lt;script&gt;</p>
                                <p class="ml-30">    <span class="color-blue">let</span> element = document.<span class="color-red">getElementById</span>(<span class="color-green">"hidden"</span>);</p>
                                <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(element.textContent); <span class="color-grey">// Output: This is hidden</span>.</p>
                                <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(element.innerText);   <span class="color-grey">// Output: (Empty string because it's hidden)</span></p>
                                <p>&lt;/script&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>To Changing Styles</h5>
                <p>You can dynamically apply CSS styles to HTML elements using JavaScript. This is done using the <span className='background-grey'>style</span> property.</p>


                <h6 className='mt-5'>Example 1: Changing a Single Style Property</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;p <span class="color-pink">id</span>=<span class="color-green">"styleExample"</span>&gt;Change my color!&lt;/p&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">    <span class="color-blue">let</span> element = document.<span class="color-red">getElementById</span>(<span class="color-green">"styleExample"</span>);</p>
                        <p class="ml-30">    element.style.color = <span class="color-green">"blue"</span>; <span class="color-grey">// Changes text color to blue</span></p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>

                <h6 className='mt-5'>Example 2: Changing Multiple Styles</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"box"</span> <span class="color-pink">style</span>=<span class="color-green">"width: 100px; height: 100px; background-color: red;"</span>&gt;&lt;/div&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">    <span class="color-blue">let</span> box = document.<span class="color-red">getElementById</span>(<span class="color-green">"box"</span>);</p>
                        <p class="ml-30">    box.style.width = <span class="color-green">"200px"</span>;          <span class="color-grey">// Changes the width</span></p>
                        <p class="ml-30">    box.style.height = <span class="color-green">"200px"</span>;         <span class="color-grey">// Changes the height</span></p>
                        <p class="ml-30">    box.style.backgroundColor = <span class="color-green">"blue"</span>; <span class="color-grey">// Changes the background color</span></p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>

                <h6 className='mt-5'>Example 3: Adding and Removing CSS Classes</h6>
                <p>Instead of changing individual styles, you can use <strong>CSS classes</strong> to apply multiple styles at once.</p>

                <strong>HTML:</strong>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;p <span class="color-pink">id</span>=<span class="color-green">"myText"</span> <span class="color-pink">class</span>=<span class="color-green">"original"</span>&gt;Hello, Style!&lt;/p&gt;</p>
                        <br />
                        <p>&lt;style&gt;</p>
                        <p class="ml-30">    .original { <span class="color-pink">color</span>: green; <span class="color-pink">font-size</span>: <span class="color-pink">16px</span>; }</p>
                        <p class="ml-30">    .newStyle { <span class="color-pink">color</span>: red; <span class="color-pink">font-size</span>: <span class="color-pink">24px</span>; <span class="color-pink">font-weight</span>: bold; }</p>
                        <p>&lt;/style&gt;</p>
                        `
                    }}></div>
                </div>
                <strong>JavaScript:</strong>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> text = document.<span class="color-red">getElementById</span>(<span class="color-green">"myText"</span>);</p>
                        <br />
                        <p class="color-grey">// Add a new class</p>
                        <p>text.classList.<span class="color-red">add</span>(<span class="color-green">"newStyle"</span>);</p>
                        <br />
                        <p class="color-grey">// Remove the old class</p>
                        <p>text.classList.<span class="color-red">remove</span>(<span class="color-green">"original"</span>);</p>
                        <br />
                        <p class="color-grey">// Toggle a class (adds if not present, removes if present)</p>
                        <p>text.classList.<span class="color-red">toggle</span>(<span class="color-green">"highlight"</span>);</p>
                        <br />
                        <p class="color-grey">// Check if an element has a class</p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(text.classList.<span class="color-red">contains</span>(<span class="color-green">"newStyle"</span>)); <span class="color-grey">// Output: true</span></p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>To Changing Attributes</h5>
                <p>You can also change attributes like <span className='background-grey'>src</span>, <span className='background-grey'>href</span>, or <span className='background-grey'>alt</span> for elements like images or links.</p>

                <h6 className='mt-5'>Example 1: Changing Image Source</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;img <span class="color-pink">id</span>=<span class="color-green">"myImage"</span> <span class="color-pink">src</span>=<span class="color-green">"image1.jpg"</span> <span class="color-pink">alt</span>=<span class="color-green">"Image 1"</span> <span class="color-pink">width</span>=<span class="color-green">"200"</span>&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">    <span class="color-blue">let</span> image = document.<span class="color-red">getElementById</span>(<span class="color-green">"myImage"</span>);</p>
                        <p class="ml-30">    image.src = <span class="color-green">"image2.jpg"</span>; <span class="color-grey">// Changes the image</span></p>
                        <p class="ml-30">    image.alt = <span class="color-green">"Image 2"</span>;    <span class="color-grey">// Updates the alt text</span></p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>

                <h6 className='mt-5'>Example 2: Changing Link <span className='background-grey'>href</span></h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;a <span class="color-pink">id</span>=<span class="color-green">"myLink"</span> <span class="color-pink">href</span>=<span class="color-green">"https://example.com"</span> <span class="color-pink">target</span>=<span class="color-green">"_blank"</span>&gt;Visit Example&lt;/a&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">    <span class="color-blue">let</span> link = document.<span class="color-red">getElementById</span>(<span class="color-green">"myLink"</span>);</p>
                        <p class="ml-30">    link.href = <span class="color-green">"https://google.com"</span>;  <span class="color-grey">// Changes the link destination</span></p>
                        <p class="ml-30">    link.textContent = <span class="color-green">"Visit Google"</span>; <span class="color-grey">// Changes the link text</span></p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>

            </div>
        </section>
    )
}