import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/dialog-boxes";

export default function DialogBoxes() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Window Dialog Boxes in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/cookies',
            'next': '/javascript/form-validation'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Dialog Boxes</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Dialog boxes in JavaScript are pop-up messages that appear on the screen to communicate with the user. These are built-in browser features that allow developers to show messages, ask for user input, or display warnings.
                </p>

                <h5 className='mt-5 mb-3'>Types of Dialog Boxes</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Alert Box</strong> (<span className='background-grey'>alert</span>)</li>
                    <li><strong>Confirm Box</strong> (<span className='background-grey'>confirm</span>)</li>
                    <li><strong>Prompt Box</strong> (<span className='background-grey'>prompt</span>)</li>
                </ul>


                <h5 className='mt-5 mb-3'>Alert Box</h5>
                <p>An alert box is used to display a simple message to the user. It requires the user to click the "OK" button to proceed. It has a basic syntax - <span className='background-grey'>alert(message)</span>;</p>
                <p>For example:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">alert</span>(<span class="color-green">"Welcome to the website!"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>It displays a message in a pop-up and stops other JavaScript code from running until the user clicks "OK".</p>


                <h5 className='mt-5 mb-3'>Confirm Box</h5>
                <p>
                    A confirm box asks the user to make a choice (OK or Cancel). It returns a boolean value:
                </p>
                <ul style={{listStyle: 'disc'}}>
                    <li><span className='background-grey'>true</span> if the user clicks "OK".</li>
                    <li><span className='background-grey'>false</span> if the user clicks "Cancel".</li>
                </ul>
                <p>It has a simple syntax - <span className='background-grey'>confirm(message)</span></p>
                <p>For example:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">const</span> userResponse = <span class="color-red">confirm</span>(<span class="color-green">"Do you want to continue?"</span>);</p>
                        <p><span class="color-blue">if</span> (userResponse) {</p>
                        <p class="ml-30">    <span class="color-red">alert</span>(<span class="color-green">"You clicked OK!"</span>);</p>
                        <p>} <span class="color-blue">else</span> {</p>
                        <p class="ml-30">    <span class="color-red">alert</span>(<span class="color-green">"You clicked Cancel!"</span>);</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <p>It displays a message with "OK" and "Cancel" buttons.It is useful for confirming actions, like deleting a file or leaving a page.</p>


                <h5 className='mt-5 mb-3'>Prompt Box</h5>
                <p>A prompt box allows the user to input a value. It returns the entered value as a string or null if the user clicks "Cancel". It has a basic syntax - <span className='background-grey'>prompt(message, defaultValue)</span>; Where</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>message</span>: The message displayed in the dialog.</li>
                    <li><span className='background-grey'>defaultValue</span> (optional): A default value shown in the input field.</li>
                </ul>
                <p>For example:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">const</span> userName = <span class="color-red">prompt</span>(<span class="color-green">"What is your name?"</span>, <span class="color-green">"Guest"</span>);</p>
                        <p><span class="color-blue">if</span> (userName) {</p>
                        <p class="ml-30">    <span class="color-red">alert</span>(<span class="color-green">"Hello, "</span> + userName + <span class="color-green">"!"</span>);</p>
                        <p>} <span class="color-blue">else</span> {</p>
                        <p class="ml-30">    <span class="color-red">alert</span>(<span class="color-green">"No name entered!"</span>);</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <p>It displays a message with an input field and allows users to enter text and return it to the program.</p>


            </div>
        </section>
    )
}