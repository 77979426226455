import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/callbacks";

export default function Callbacks() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Callbacks in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/number-properties',
            'next': '/javascript/promises'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Callbacks</h3>
            <div className='mt-4 mb-5'>
                <p>
                    A <strong>callback</strong> in JavaScript is a function that is <strong>passed as an argument</strong> to another function and is <strong>executed later</strong>, usually after some operation or event is completed.
                </p>
                <p>
                    Callbacks are commonly used in JavaScript for <strong>asynchronous programming</strong>, such as handling events, making network requests, or working with timers.
                </p>

                <h5 className='mt-5 mb-3'>Key Points About Callbacks</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Definition:</strong>
                        <ul style={{listStyle: 'disc'}}>
                            <li>
                                A callback is a function that is called <strong>back</strong> at a later time after the execution of the containing function.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>How it Works:</strong>
                        <ul style={{listStyle: 'disc'}}>
                            <li>One function takes another function as its input (the callback).</li>
                            <li>The callback function is executed inside the first function when needed.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Purpose:</strong>
                        <ul style={{listStyle: 'disc'}}>
                            <li>
                                To allow <strong>non-blocking operations</strong>, meaning the program can continue running while waiting for a task to complete.
                            </li>
                        </ul>
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>Simple Example of a Callback</h5>
                <h6 className='mt-3'>Example 1: Using a Callback to Log a Message</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">function</span> <span class="color-red">sayHello</span>(name, callback) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Hello, "</span> + name);</p>
                        <p class="ml-30">    <span class="color-red">callback</span>(); <span class="color-grey">// Calling the callback function</span></p>
                        <p>}</p>
                        <br />
                        <p><span class="color-blue">function</span> <span class="color-red">sayGoodbye</span>() {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Goodbye!"</span>);</p>
                        <p>}</p>
                        <br />
                        <p><span class="color-red">sayHello</span>(<span class="color-green">"John"</span>, sayGoodbye);</p>
                        `
                    }}></div>
                </div>
                <h6>Output</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>Hello, John</p>
                        <p>Goodbye!</p>
                        `
                    }}></div>
                </div>
                <p>Here,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>sayHello</span> is the main function.</li>
                    <li><span className='background-grey'>sayGoodbye</span> is passed as a <strong>callback</strong> to <span className='background-grey'>sayHello</span> and is executed after "Hello, John" is printed.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Callback in Asynchronous Programming</h5>
                <h6 className='mt-3'>Example 2: Using a Callback with <span className='background-grey'>setTimeout</span></h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">function</span> <span class="color-red">greet</span>() {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"This message is delayed!"</span>);</p>
                        <p>}</p>
                        <br />
                        <p><span class="color-red">setTimeout</span>(greet, <span class="color-pink">2000</span>); <span class="color-grey">// Calls the 'greet' function after 2 seconds</span></p>
                        `
                    }}></div>
                </div>
                <h6>Output (after 2 seconds):</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>This message is delayed!</p>
                        `
                    }}></div>
                </div>
                <p>Here,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span className='background-grey'>setTimeout</span> function takes <span className='background-grey'>greet</span> as a <strong>callback</strong> and executes it after 2000 milliseconds (2 seconds).</li>
                </ul>


                <h5 className='mt-5 mb-3'>Callback Example with Parameters</h5>
                <h6 className='mt-3'>Example 3: Passing Parameters to a Callback</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">function</span> <span class="color-red">calculate</span>(a, b, callback) {</p>
                        <p class="ml-30">    <span class="color-blue">let</span> result = a + b;</p>
                        <p class="ml-30">    <span class="color-red">callback</span>(result); <span class="color-grey">// Passing result to the callback function</span></p>
                        <p>}</p>
                        <br />
                        <p><span class="color-blue">function</span> <span class="color-red">displayResult</span>(result) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"The result is: "</span> + result);</p>
                        <p>}</p>
                        <br />
                        <p><span class="color-red">calculate</span>(<span class="color-pink">5</span>, <span class="color-pink">10</span>, <span class="color-red">displayResult</span>);</p>
                        `
                    }}></div>
                </div>
                <h6>Output</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>The result is: <span class="color-pink">15</span></p>
                        `
                    }}></div>
                </div>
                <p>Here,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>calculate</span> adds two numbers and passes the result to the <span className='background-grey'>displayResult</span> callback function.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Why Use Callbacks?</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>Handle Asynchronous Operations</strong>: Perform tasks like fetching data from a server without blocking the rest of the code.</li>
                    <li><strong>Custom Behavior</strong>: Allow flexibility by executing different callback functions depending on the requirement.</li>
                    <li><strong>Event Handling</strong>: React to user actions like button clicks.</li>
                </ul>


            </div>
        </section>
    )
}