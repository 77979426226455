import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/js-2018";

export default function Js2018() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("ECMAScript 2018 | Aspirant's Home");
        const urls = {
            'previous': '/javascript/js-2017',
            'next': '/javascript/js-2019'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>New Features in ECMAScript 2018</h3>
            <div className='mt-4 mb-5'>
                <p>These are the new features in ECMAScript 2018:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><a href="#asynchronous-iteration">Asynchronous Iteration</a></li>
                    <li><a href="#promise-finally">Promise Finally</a></li>
                    <li><a href="#rest-properties">Object Rest Properties</a></li>
                    <li><a href="#regexp-features">New RegExp Features</a></li>
                    <li><a href="#shared-memory">JavaScript Shared Memory</a></li>
                </ul>


                <div id="asynchronous-iteration">
                    <h5 className='mt-5 mb-3'>JavaScript Asynchronous Iteration</h5>
                    <p>Asynchronous iteration allows you to iterate over asynchronous data sources, such as Promises or APIs that return data over time. It was introduced in <strong>ES2018 (ES9)</strong> with the addition of the <span className='background-grey'>for await...of</span> loop and <strong>async iterators</strong>.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-pink">async</span> <span class="color-blue">function</span> <span class="color-red">processItems</span>() {</p>
                            <p class="ml-30">    <span class="color-blue">const</span> asyncArray = [</p>
                            <p class="ml-60">        <span class="color-blue">Promise</span>.<span class="color-red">resolve</span>(<span class="color-green">'Item 1'</span>),</p>
                            <p class="ml-60">        <span class="color-blue">Promise</span>.<span class="color-red">resolve</span>(<span class="color-green">'Item 2'</span>),</p>
                            <p class="ml-60">        <span class="color-blue">Promise</span>.<span class="color-red">resolve</span>(<span class="color-green">'Item 3'</span>),</p>
                            <p class="ml-30">    ];</p>
                            <br />  
                            <p class="ml-30">    <span class="color-pink">for await</span> (<span class="color-blue">let</span> item <span class="color-pink">of</span> asyncArray) {</p>
                            <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(item);</p>
                            <p class="ml-30">    }</p>
                            <p>}</p>
                            <br />  
                            <p><span class="color-red">processItems</span>();</p>
                            <p class="color-grey">// Output:</p>
                            <p class="color-grey">// Item 1</p>
                            <p class="color-grey">// Item 2</p>
                            <p class="color-grey">// Item 3</p>
                            `
                        }}></div>
                    </div>
                </div>

                <div id="promise-finally">
                    <h5 className='mt-5 mb-3'>JavaScript Promise.finally</h5>
                    <p>The <span className='background-grey'>Promise.finally()</span> method allows you to execute code once a Promise is settled, regardless of whether it was fulfilled or rejected. This is useful for cleanup actions, like closing resources or stopping loading indicators.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> myPromise = <span class="color-blue">new</span> <span class="color-red">Promise</span>((resolve, reject) => {</p>
                            <p class="ml-30">    <span class="color-red">setTimeout</span>(() => <span class="color-red">resolve</span>(<span class="color-green">'Success!'</span>), <span class="color-pink">1000</span>);</p>
                            <p>});</p>
                            <br />  
                            <p>myPromise</p>
                            <p class="ml-30">    .<span class="color-red">then</span>(result => <span class="color-pink">console</span>.<span class="color-red">log</span>(result)) <span class="color-grey">// 'Success!'</span></p>
                            <p class="ml-30">    .<span class="color-red">catch</span>(error => <span class="color-pink">console</span>.<span class="color-red">error</span>(error))</p>
                            <p class="ml-30">    .<span class="color-red">finally</span>(() => <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Promise settled.'</span>));</p>
                            <p class="color-grey">// Output after 1 second:</p>
                            <p class="color-grey">// Success!</p>
                            <p class="color-grey">// Promise settled.</p>
                            `
                        }}></div>
                    </div>
                    <p><span className='background-grey'>.finally()</span> executes after the promise is settled, regardless of whether it was fulfilled or rejected.</p>
                </div>

                <div id="rest-properties">
                    <h5 className='mt-5 mb-3'>JavaScript Object Rest Properties</h5>
                    <p><strong>Object Rest Properties</strong> allow you to extract remaining properties of an object into a new object. It’s a part of <strong>ES2018 (ES9)</strong> and complements destructuring.</p>
                    <p>It has a simple syntax -</p>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">const</span> { key1, key2, <span class="color-pink">...rest</span> } = object;</p>
                            `
                        }}></div>
                    </div>
                    <p>The rest operator (<span className='background-grey'>...</span>) collects the remaining properties of an object after specific ones have been extracted.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> user = { <span class="color-pink">name</span>: <span class="color-green">'Alice'</span>, <span class="color-pink">age</span>: <span class="color-pink">25</span>, <span class="color-pink">city</span>: <span class="color-green">'New York'</span>, <span class="color-pink">country</span>: <span class="color-green">'USA'</span> };</p>
                            <br />
                            <p><span class="color-blue">const</span> { name, <span class="color-pink">...details</span> } = user;</p>
                            <br />
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(name);     <span class="color-grey">// Output: Alice</span></p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(details);  <span class="color-grey">// Output: { age: 25, city: 'New York', country: 'USA' }</span></p>
                            `
                        }}></div>
                    </div>
                    <p>Here,</p>
                    <ul style={{ listStyle: 'disc' }}>
                        <li>The <span className='background-grey'>name</span> property is extracted.</li>
                        <li>The <span className='background-grey'>details</span> object contains the remaining properties: <span className='background-grey'>age</span>, <span className='background-grey'>city</span>, and <span className='background-grey'>country</span>.</li>
                    </ul>
                </div>

                <div id="regexp-features">
                    <h5 className='mt-5 mb-3'>New JavaScript RegExp Features</h5>
                    <p>ECMAScript 2018 added 4 new RegExp features:</p>
                    <ul style={{ listStyle: 'disc' }}>
                        <li>Unicode Property Escapes (\p&#123;...&#125;)</li>
                        <li>Lookbehind Assertions (?&lt;= ) and (?&lt;! )</li>
                        <li>Named Capture Groups</li>
                        <li>s (dotAll) Flag</li>
                    </ul>
                </div>

                <div id="shared-memory">
                    <h5 className='mt-5 mb-3'>JavaScript Shared Memory</h5>
                    <p><strong>Shared memory</strong> in JavaScript enables multiple threads (such as web workers) to access and manipulate the same block of memory. This is made possible through <span className='background-grey'>SharedArrayBuffer</span>, which provides a way to share a fixed-length block of raw binary data between threads.</p>
                    <p>A <strong>SharedArrayBuffer</strong> object represents a fixed-length raw binary data buffer similar to the ArrayBuffer object.</p>
                </div>


            </div>
        </section>
    )
}