import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/promises";

export default function Promises() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Promises in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/callbacks',
            'next': '/javascript/async-await'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Promises</h3>
            <div className='mt-4 mb-5'>
                <p>
                    A <strong>Promise</strong> in JavaScript is an object that represents the eventual completion (or failure) of an asynchronous operation and its resulting value. It helps handle <strong>asynchronous code</strong> in a more readable and structured way, compared to callbacks.
                </p>

                <h5 className='mt-5 mb-3'>Key Points About Promises</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li className='mt-3'>
                        <strong>Purpose:</strong>
                        <ul style={{listStyle: 'disc'}}>
                            <li>To handle tasks like fetching data from a server, where the result is not immediately available.</li>
                            <li>To avoid <strong>callback hell</strong> (a situation where nested callbacks make the code messy and hard to read).</li>
                        </ul>
                    </li>
                    <li className='mt-3'>
                        <strong>States of a Promise:</strong>
                        <ul style={{listStyle: 'disc'}}>
                            <li><strong>Pending</strong>: The promise is still in progress.</li>
                            <li><strong>Fulfilled</strong>: The promise completed successfully.</li>
                            <li><strong>Rejected</strong>: The promise failed.</li>
                        </ul>
                    </li>
                    <li className='mt-3'>
                        <strong>Flow of a Promise:</strong>
                        <ul style={{listStyle: 'disc'}}>
                            <li>When a promise is <strong>fulfilled</strong>, it returns a result.</li>
                            <li>When a promise is <strong>rejected</strong>, it returns an error.</li>
                        </ul>
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>How to Create a Promise</h5>
                <p>
                    A promise is created using the <span className='background-grey'>Promise</span> constructor, which takes a function as an argument. This function has two parameters:
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>resolve</span>: Called when the operation is successful.</li>
                    <li><span className='background-grey'>reject</span>: Called when the operation fails.</li>
                </ul>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> myPromise = <span class="color-blue">new</span> <span class="color-red">Promise</span>((resolve, reject) => {</p>
                        <p class="ml-30 color-grey">    // Simulate an asynchronous task</p>
                        <p class="ml-30">    <span class="color-blue">let</span> success = <span class="color-blue">true</span>;</p>
                        <br />
                        <p class="ml-30">    <span class="color-blue">if</span> (success) {</p>
                        <p class="ml-60">        <span class="color-red">resolve</span>(<span class="color-green">"Task completed successfully!"</span>);</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-red">reject</span>(<span class="color-green">"Task failed!"</span>);</p>
                        <p class="ml-30">    }</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                

                <h5 className='mt-5 mb-3'>Using Promises</h5>
                <p>You use the <span className='background-grey'>.then()</span> and <span className='background-grey'>.catch()</span> methods to handle the result of a promise:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><span className='background-grey'>.then()</span>: Runs when the promise is resolved (successful).</li>
                    <li><span className='background-grey'>.catch()</span>: Runs when the promise is rejected (failed).</li>
                </ul>
                <h6>Example</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> myPromise = <span class="color-blue">new</span> <span class="color-red">Promise</span>((resolve, reject) => {</p>
                        <p class="ml-30">    <span class="color-blue">let</span> isSuccessful = <span class="color-blue">true</span>;</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">setTimeout</span>(() => {</p>
                        <p class="ml-60">        <span class="color-blue">if</span> (isSuccessful) {</p>
                        <p class="ml-90">            <span class="color-red">resolve</span>(<span class="color-green">"Promise resolved!"</span>);</p>
                        <p class="ml-60">        } <span class="color-blue">else</span> {</p>
                        <p class="ml-90">            <span class="color-red">reject</span>(<span class="color-green">"Promise rejected!"</span>);</p>
                        <p class="ml-60">        }</p>
                        <p class="ml-30">    }, <span class="color-pink">2000</span>); <span class="color-grey">// Simulate a 2-second delay</span></p>
                        <p>});</p>
                        <br />
                        <p>myPromise</p>
                        <p class="ml-30">    .<span class="color-red">then</span>(result => <span class="color-pink">console</span>.<span class="color-red">log</span>(result)) <span class="color-grey">// Output if resolved: "Promise resolved!"</span></p>
                        <p class="ml-30">    .<span class="color-red">catch</span>(error => <span class="color-pink">console</span>.<span class="color-red">log</span>(error)); <span class="color-grey">// Output if rejected: "Promise rejected!"</span></p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Promise Chaining</h5>
                <p>You can chain multiple <span className='background-grey'>.then()</span> methods to perform a sequence of tasks.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> myPromise = <span class="color-blue">new</span> <span class="color-red">Promise</span>((resolve, reject) => {</p>
                        <p class="ml-30">    <span class="color-red">setTimeout</span>(() => <span class="color-red">resolve</span>(<span class="color-green">"Step 1 completed"</span>), <span class="color-pink">1000</span>);</p>
                        <p>});</p>
                        
                        <p>myPromise</p>
                        <p class="ml-30">    .<span class="color-red">then</span>(result => {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(result); <span class="color-grey">// Output: Step 1 completed</span></p>
                        <p class="ml-60">        <span class="color-pink">return</span> <span class="color-green">"Step 2 completed"</span>;</p>
                        <p class="ml-30">    })</p>
                        <p class="ml-30">    .<span class="color-red">then</span>(result => {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(result); <span class="color-grey">// Output: Step 2 completed</span></p>
                        <p class="ml-60">        <span class="color-pink">return</span> <span class="color-green">"Step 3 completed"</span>;</p>
                        <p class="ml-30">    })</p>
                        <p class="ml-30">    .<span class="color-red">then</span>(result => {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(result); <span class="color-grey">// Output: Step 3 completed</span></p>
                        <p class="ml-30">    })</p>
                        <p class="ml-30">    .<span class="color-red">catch</span>(error => <span class="color-pink">console</span>.<span class="color-red">log</span>(error));</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Example: Fetching Data with a Promise</h5>
                <p>Simulate fetching data using a promise:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">function</span> <span class="color-red">fetchData</span>() {</p>
                        <p class="ml-30">    <span class="color-pink">return</span> <span class="color-blue">new</span> <span class="color-red">Promise</span>((resolve, reject) => {</p>
                        <p class="ml-60">        <span class="color-blue">let</span> dataAvailable = <span class="color-blue">true</span>;</p>
                        <br />
                        <p class="ml-60">        <span class="color-blue">setTimeout</span>(() => {</p>
                        <p class="ml-90">            <span class="color-blue">if</span> (dataAvailable) {</p>
                        <p class="ml-120">                <span class="color-red">resolve</span>(<span class="color-green">"Data fetched successfully!"</span>);</p>
                        <p class="ml-90">            } <span class="color-blue">else</span> {</p>
                        <p class="ml-120">                <span class="color-red">reject</span>(<span class="color-green">"Failed to fetch data!"</span>);</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        }, <span class="color-pink">2000</span>);</p>
                        <p class="ml-30">    });</p>
                        <p>}</p>
                        <br />
                        <p>fetchData()</p>
                        <p class="ml-30">    .<span class="color-red">then</span>(result => <span class="color-pink">console</span>.<span class="color-red">log</span>(result)) <span class="color-grey">// Output: Data fetched successfully!</span></p>
                        <p class="ml-30">    .<span class="color-red">catch</span>(error => <span class="color-pink">console</span>.<span class="color-red">log</span>(error)); <span class="color-grey">// Output: Failed to fetch data!</span></p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Promise.all()</h5>
                <p><span className='background-grey'>Promise.all()</span> is used to run multiple promises in parallel. It waits for all promises to resolve and returns their results as an array. If any promise is rejected, it returns the error immediately.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> promise1 = Promise.<span class="color-red">resolve</span>(<span class="color-green">"Task 1"</span>);</p>
                        <p><span class="color-blue">let</span> promise2 = Promise.<span class="color-red">resolve</span>(<span class="color-green">"Task 2"</span>);</p>
                        <p><span class="color-blue">let</span> promise3 = Promise.<span class="color-red">resolve</span>(<span class="color-green">"Task 3"</span>);</p>
                        <br />
                        <p>Promise.<span class="color-red">all</span>([promise1, promise2, promise3])</p>
                        <p class="ml-30">    .<span class="color-red">then</span>(results => <span class="color-pink">console</span>.<span class="color-red">log</span>(results)) <span class="color-grey">// Output: ["Task 1", "Task 2", "Task 3"]</span></p>
                        <p class="ml-30">    .<span class="color-red">catch</span>(error => <span class="color-pink">console</span>.<span class="color-red">log</span>(error));</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Promise.race()</h5>
                <p><span className='background-grey'>Promise.race()</span> returns the result of the first promise that resolves or rejects.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> promise1 = <span class="color-blue">new</span> <span class="color-red">Promise</span>(resolve => <span class="color-red">setTimeout</span>(() => <span class="color-red">resolve</span>(<span class="color-green">"First!"</span>), <span class="color-pink">1000</span>));</p>
                        <p><span class="color-blue">let</span> promise2 = <span class="color-blue">new</span> <span class="color-red">Promise</span>(resolve => <span class="color-red">setTimeout</span>(() => <span class="color-red">resolve</span>(<span class="color-green">"Second!"</span>), <span class="color-pink">2000</span>));</p>
                        <br />
                        <p>Promise.<span class="color-red">race</span>([promise1, promise2])</p>
                        <p class="ml-30">    .<span class="color-red">then</span>(result => <span class="color-pink">console</span>.<span class="color-red">log</span>(result)) <span class="color-grey">// Output: First!</span></p>
                        <p class="ml-30">    .<span class="color-red">catch</span>(error => <span class="color-pink">console</span>.<span class="color-red">log</span>(error));</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Advantages of Promises</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Better Readability</strong>: Cleaner and easier to understand than nested callbacks.</li>
                    <li><strong>Error Handling</strong>: Errors can be caught easily using <span className='background-grey'>.catch()</span>.</li>
                    <li><strong>Chaining</strong>: Supports multiple asynchronous operations in sequence.</li>
                </ul>


            </div>
        </section>
    )
}