import React, { useEffect, useState, useRef } from 'react'
import { Outlet, Link } from "react-router-dom";
import TopHeader from './TopHeader';
import Footer from './Footer';
import { MenuContext, TitleContext, PathContext } from "../Context";
import { useContext } from "react";
import PreviousNext from './PreviousNext';
import ExcerciseMenu from './ExcerciseMenu';

const Excercises = () => {

    const addspaceElement = useRef();
    const menuElement = useRef([]);
    const menu = useContext(MenuContext);
    const pathName = useContext(PathContext);
    const pathname = pathName.pathName;
    const title = useContext(TitleContext);

    const setPathName = (path) => {
        if(path)
            pathName.setPathName(path);
    }

    useEffect(() => {
        setPathName(window.location.pathname)
        title.setPageTitle( "Assignments | Aspirant's Home");
    }, [pathname])

    return (
        <>
            <TopHeader />
            <div className='container-fluid'>
                <div className='row' style={{ background: '#ddd' }}>
                    <div ref={menuElement} className={menu.isMenu == true ? 'col-md-3 col-lg-2 menuColumnMobile menuColumn p-0' : ' menuColumn p-0'} >
                        <ExcerciseMenu pathname={pathname} setPathName={setPathName} />
                    </div>
                    <div className='col-12 middlePart'>
                        <div className='headerAdSpace' ref={addspaceElement}>
                            <img className='img-fluid' src='https://aspirantshome.com/slide1.png' />
                        </div>
                        <div className='contentPart row sticky-top'>
                            <div className='col-12 col-md-12 contentSection'>
                                <div className='container mb-5'>
                                    <h3>Assignments</h3>
                                    <p>Assignments are a vital part of the learning process, and at <strong>AspirantsHome.com</strong>, we make them engaging and effective.</p>

                                    <h5 className='mt-5 mb-3'>📝 Why Our Assignments Are Perfect for You:</h5>
                                    <ul style={{listStyle: 'disc'}}>
                                        <li>Designed to test and strengthen your understanding of key concepts.</li>
                                        <li>Cover topics in depth, ensuring comprehensive learning.</li>
                                        <li>Real-world problem-solving tasks to build practical skills.</li>
                                        <li>Suitable for all levels—beginners to advanced learners.</li>
                                    </ul>
                                    <p>At <strong>AspirantsHome.com</strong>, we believe in learning by doing. Dive into our well-structured assignments and take the first step towards mastering your skills!</p>

                                    <div className='row'>
                                        <div className='col-6 eachSubject left' style={{background:'#a2bccf'}}>
                                            <h5>NodeJS</h5>
                                            <p>25 Questions covering the basics</p>
                                            <a href='/excercise/node-js' className='btn btn-primary'>Start the Assignment</a>
                                        </div>
                                        <div className='col-6 eachSubject right' style={{background:'rgb(158 204 183)'}}>
                                            <h5>MongoDB</h5>
                                            <p>25 Questions covering the basics</p>
                                            <a href='/excercise/mongo-db' className='btn btn-primary'>Start the Assignment</a>
                                        </div>
                                    </div>
                                    <div className='row mt-5'>
                                        <div className='col-6 eachSubject left' style={{background:'rgb(203 204 158)'}}>
                                            <h5>Angular</h5>
                                            <p>25 Questions covering the basics</p>
                                            <a href='/excercise/angular' className='btn btn-primary'>Start the Assignment</a>
                                        </div>
                                        <div className='col-6 eachSubject right' style={{background:'rgb(169 158 204)'}}>
                                            <h5>ReactJS</h5>
                                            <p>25 Questions covering the basics</p>
                                            <a href='/excercise/react-js' className='btn btn-primary'>Start the Assignment</a>
                                        </div>
                                    </div>
                                    <div className='row mt-5'>
                                        <div className='col-6 eachSubject left' style={{background:'rgb(204 158 183)'}}>
                                            <h5>JavaScript</h5>
                                            <p>25 Questions covering the basics</p>
                                            <a href='/excercise/javascript' className='btn btn-primary'>Start the Assignment</a>
                                        </div>
                                        <div className='col-6 eachSubject right' style={{background:'rgb(155 135 135)'}}>
                                            <h5>HTML</h5>
                                            <p>25 Questions covering the basics</p>
                                            <a href='/excercise/html' className='btn btn-primary'>Start the Assignment</a>
                                        </div>
                                    </div>
                                    <div className='row mt-5'>
                                        <div className='col-6 eachSubject left' style={{background:'rgb(89 141 218)'}}>
                                            <h5>CSS</h5>
                                            <p>25 Questions covering the basics</p>
                                            <a href='/excercise/css' className='btn btn-primary'>Start the Assignment</a>
                                        </div>
                                        <div className='col-6 eachSubject right' style={{background:'rgb(204 158 158)'}}>
                                            <h5>Mysql</h5>
                                            <p>25 Questions covering the basics</p>
                                            <a href='/excercise/mysql' className='btn btn-primary'>Start the Assignment</a>
                                        </div>
                                    </div>
                                    <div className='row mt-5 mb-5'>
                                        <div className='col-6 eachSubject left' style={{background:'rgb(147 153 92)'}}>
                                            <h5>PHP</h5>
                                            <p>25 Questions covering the basics</p>
                                            <a href='/excercise/php' className='btn btn-primary'>Start the Assignment</a>
                                        </div>
                                        <div className='col-6 eachSubject right' style={{background:'rgb(179 99 99)'}}>
                                            <h5>jQuery</h5>
                                            <p>25 Questions covering the basics</p>
                                            <a href='/excercise/jquery' className='btn btn-primary'>Start the Assignment</a>
                                        </div>
                                    </div>

                                    <div className='footerAdSpace'>
                                        <img className='img-fluid' src='https://aspirantshome.com/slide4.png' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12  rightPart'>
                        <img className='img-fluid' src='https://aspirantshome.com/slide2.png' />
                        <img className='mt-5 img-fluid' src='https://aspirantshome.com/slide3.png' />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='container-fluid footerPart'>
                            <Footer />
                        </div>
                    </div>
                </div>
                
            </div>
        </>
    );
};

export default Excercises;
