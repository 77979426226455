import React, { useEffect, useState, useRef } from 'react'
import { Outlet, Link } from "react-router-dom";

const QuizMenu = ({pathname, setPathName}) => {

    return (
        <>
            <div className='d-flex align-items-center headerMenu headerMainMenu sticky-top mb-4' style={{ 'height': '80px' }}>
                <div>
                    <h2>Quiz</h2>
                </div>
            </div>
            <nav>
                <ul>
                    <li className={pathname == '/quiz/node-js' ? 'activeMenu' : ''}>
                        <Link to="/quiz/node-js" onClick={() => { setPathName('/quiz/node-js'); } }>NodeJs</Link>
                    </li>
                    <li className={pathname == '/quiz/mongo-db' ? 'activeMenu' : ''}>
                        <Link to="/quiz/mongo-db" onClick={() => { setPathName('/quiz/mongo-db'); } }>MongoDB</Link>
                    </li>
                    <li className={pathname == '/quiz/angular' ? 'activeMenu' : ''}>
                        <Link to="/quiz/angular" onClick={() => { setPathName('/quiz/angular'); } }>Angular</Link>
                    </li>
                    <li className={pathname == '/quiz/react-js' ? 'activeMenu' : ''}>
                        <Link to="/quiz/react-js" onClick={() => { setPathName('/quiz/react-js'); } }>ReactJS</Link>
                    </li>
                    <li className={pathname == '/quiz/javascript' ? 'activeMenu' : ''}>
                        <Link to="/quiz/javascript" onClick={() => { setPathName('/quiz/javascript'); } }>Javascript</Link>
                    </li>
                    <li className={pathname == '/quiz/html' ? 'activeMenu' : ''}>
                        <Link to="/quiz/html" onClick={() => { setPathName('/quiz/html'); } }>HTML</Link>
                    </li>
                    <li className={pathname == '/quiz/css' ? 'activeMenu' : ''}>
                        <Link to="/quiz/css" onClick={() => { setPathName('/quiz/css'); } }>CSS</Link>
                    </li>
                    <li className={pathname == '/quiz/mysql' ? 'activeMenu' : ''}>
                        <Link to="/quiz/mysql" onClick={() => { setPathName('/quiz/mysql'); } }>Mysql</Link>
                    </li>
                    <li className={pathname == '/quiz/php' ? 'activeMenu' : ''}>
                        <Link to="/quiz/php" onClick={() => { setPathName('/quiz/php'); } }>PHP</Link>
                    </li>
                    <li className={pathname == '/quiz/jquery' ? 'activeMenu' : ''}>
                        <Link to="/quiz/jquery" onClick={() => { setPathName('/quiz/jquery'); } }>JQuery</Link>
                    </li>
                    {/* <li className={pathname == '/quiz/next-js' ? 'activeMenu' : ''}>
                        <Link to="/quiz/next-js" onClick={() => { setPathName('/quiz/next-js'); } }>Next Js</Link>
                    </li> */}
                        
                </ul>
            </nav>
        </>
    );
};

export default QuizMenu;