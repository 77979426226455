import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/number-properties";

export default function NumberProperties() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Number Properties in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/number-methods',
            'next': '/javascript/callbacks'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Number Properties</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In JavaScript, <strong>Number</strong> properties are built-in constants associated with the <span className='background-grey'>Number</span> object. These properties provide important information about numerical limits and special values.
                </p>

                <h5 className='mt-5 mb-3'>List of Number Properties</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li className='operator'>
                        <span className='background-grey'>Number.MAX_VALUE</span>
                        <p className='mt-3'>It represents the largest positive number that JavaScript can handle. It's approximate value is <span className='background-grey'>1.7976931348623157e+308</span></p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-blue">Number.MAX_VALUE</span>); <span class="color-grey">// Output: 1.7976931348623157e+308</span></p>
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-blue">Number.MAX_VALUE</span> + <span class="color-pink">1</span>); <span class="color-grey">// Output: 1.7976931348623157e+308 (unchanged)</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator'>
                        <span className='background-grey'>Number.MIN_VALUE</span>
                        <p className='mt-3'>It represents the smallest positive number greater than 0 that JavaScript can handle. It's approximate value is <span className='background-grey'>5e-324</span></p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-blue">Number.MIN_VALUE</span>); <span class="color-grey">// Output: 5e-324</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-blue">Number.MIN_VALUE</span> / <span class="color-pink">10</span>); <span class="color-grey">// Output: 0 (too small for JavaScript to represent)</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator'>
                        <span className='background-grey'>Number.POSITIVE_INFINITY</span>
                        <p>It represents positive infinity. It occurs when a number exceeds <span className='background-grey'>Number.MAX_VALUE</span>.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-blue">Number.POSITIVE_INFINITY</span>); <span class="color-grey">// Output: Infinity</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-pink">1</span> / <span class="color-pink">0</span>); <span class="color-grey">// Output: Infinity</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator'>
                        <span className='background-grey'>Number.NEGATIVE_INFINITY</span>
                        <p>It represents negative infinity. It occurs when a number is smaller than <span className='background-grey'>-Number.MAX_VALUE</span>.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-blue">Number.NEGATIVE_INFINITY</span>); <span class="color-grey">// Output: -Infinity</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-pink">-1</span> / <span class="color-pink">0</span>); <span class="color-grey">// Output: -Infinity</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator'>
                        <span className='background-grey'>Number.NaN</span>
                        <p>It represents a special "Not-a-Number" value. It occurs when a mathematical operation cannot produce a valid number.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-blue">Number.NaN</span>); <span class="color-grey">// Output: NaN</span></p>
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-pink">0</span> / <span class="color-green">"abc"</span>); <span class="color-grey">// Output: NaN</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator'>
                        <span className='background-grey'>Number.EPSILON</span>
                        <p>It represents the smallest interval between two representable numbers. It approximate value is <span className='background-grey'>2.220446049250313e-16</span>. It is used to handle floating-point precision issues.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-blue">Number.EPSILON</span>); <span class="color-grey">// Output: 2.220446049250313e-16</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-pink">0.1</span> + <span class="color-pink">0.2</span> <span class="color-pink">===</span> <span class="color-pink">0.3</span>); <span class="color-grey">// Output: false (floating-point issue)</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(Math.<span class="color-red">abs</span>((<span class="color-pink">0.1</span> + <span class="color-pink">0.2</span>) - <span class="color-pink">0.3</span>) < <span class="color-blue">Number.EPSILON</span>); <span class="color-grey">// Output: true</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator'>
                        <span className='background-grey'>Number.MAX_SAFE_INTEGER</span>
                        <p>It represents the largest integer that can be safely represented in JavaScript without precision loss. It's value is <span className='background-grey'>9007199254740991</span></p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-blue">Number.MAX_SAFE_INTEGER</span>); <span class="color-grey">// Output: 9007199254740991</span></p>
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-blue">Number.MAX_SAFE_INTEGER</span> + <span class="color-pink">1</span> <span class="color-pink">===</span> <span class="color-blue">Number.MAX_SAFE_INTEGER</span> + <span class="color-pink">2</span>); <span class="color-grey">// Output: true (precision loss)</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator'>
                        <span className='background-grey'>Number.MIN_SAFE_INTEGER</span>
                        <p>It represents the smallest integer that can be safely represented in JavaScript without precision loss. It's value is <span className='background-grey'>-9007199254740991</span></p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-blue">Number.MIN_SAFE_INTEGER</span>); <span class="color-grey">// Output: -9007199254740991</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-blue">Number.MIN_SAFE_INTEGER</span> - <span class="color-pink">1</span> <span class="color-pink">===</span> <span class="color-blue">Number.MIN_SAFE_INTEGER</span> - <span class="color-pink">2</span>); <span class="color-grey">// Output: true (precision loss)</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

            </div>
        </section>
    )
}