import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/js-2016";

export default function Js2016() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("ECMAScript 2016 | Aspirant's Home");
        const urls = {
            'previous': '/javascript/js-2015',
            'next': '/javascript/js-2017'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>New Features in ECMAScript 2016</h3>
            <div className='mt-4 mb-5'>

                <p>These are the new features in ECMAScript 2016:</p>
                <ul style={{listStyle: 'disc'}}>
                    <li><a href="#exponentiation">JavaScript Exponentiation (<strong>**</strong>)</a></li>
                    <li><a href="#exponentiation-assignment">JavaScript Exponentiation assignment (<strong>**=</strong>)</a></li>
                    <li><a href="#array-includes">JavaScript Array <strong>includes()</strong></a></li>
                </ul>

                <div id="exponentiation">
                    <h5 className='mt-5 mb-3'>Exponentiation Operator (<span className='background-grey'>**</span>)</h5>
                    <p>The <strong>exponentiation operator</strong> (<span className='background-grey'>**</span>) in JavaScript allows you to perform power operations, raising a number to the power of an exponent. This operator is a concise and readable alternative to using the <span className='background-grey'>Math.pow()</span> function.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-pink">2</span> <span class="color-blue">**</span> <span class="color-pink">3</span>); <span class="color-grey">// 8 (2 raised to the power of 3)</span></p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-pink">5</span> <span class="color-blue">**</span> <span class="color-pink">2</span>); <span class="color-grey">// 25 (5 squared)</span></p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-pink">10</span> <span class="color-blue">**</span> <span class="color-pink">0</span>); <span class="color-grey">// 1 (any number to the power of 0 is 1)</span></p>
                            `
                        }}></div>
                    </div>
                </div>

                <div id="exponentiation-assignment">
                    <h5 className='mt-5 mb-3'>Exponentiation Assignment (<span className='background-grey'>**=</span>)</h5>
                    <p>The <strong>exponentiation assignment operator</strong> (<span className='background-grey'>**=</span>) is a shorthand way to raise a variable to a power and reassign the result to the same variable. This operator combines the exponentiation operator (<span className='background-grey'>**</span>) with the assignment operator (<span className='background-grey'>=</span>), making it more concise for operations involving exponents.</p>
                    <h6 className='mt-3'>Example</h6>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">let</span> num = <span class="color-pink">2</span>;</p>
                            <p>num <span class="color-blue">**=</span> <span class="color-pink">3</span>; <span class="color-grey">// Equivalent to num = num ** 3</span></p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(num); <span class="color-grey">// 8 (2 raised to the power of 3)</span></p>
                            `
                        }}></div>
                    </div>
                </div>

                <div id="array-includes">
                    <h5 className='mt-5 mb-3'>JavaScript Array includes()</h5>
                    <p>The <span className='background-grey'>includes()</span> method checks whether an array contains a certain value and returns a boolean (<span className='background-grey'>true</span> or <span className='background-grey'>false</span>). It provides an intuitive way to determine if an array contains a specific element.</p>
                    <h6 className='mt-3'>Example</h6>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> fruits = [<span class="color-green">'apple'</span>, <span class="color-green">'banana'</span>, <span class="color-green">'cherry'</span>];</p>
                            <br />
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(fruits.<span class="color-red">includes</span>(<span class="color-green">'banana'</span>)); <span class="color-grey">// true</span></p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(fruits.<span class="color-red">includes</span>(<span class="color-green">'grape'</span>));  <span class="color-grey">// false</span></p>
                            `
                        }}></div>
                    </div>
                </div>

            </div>
        </section>
    )
}