import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/submitting-forms";

export default function SubmittingForm() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Submitting Forms in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/form-validation',
            'next': '/javascript/making-http-requests'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Submitting Forms</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In JavaScript, submitting forms involves interacting with HTML form elements, capturing user input, and optionally validating or processing the data before it is sent to the server.
                </p>

                <p>
                    When you click a <strong>submit button</strong> in a form, it automatically sends the form data to the server. This is the default behavior of HTML forms.
                </p>
                <p>For Example:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;form <span class="color-pink">action</span>=<span class="color-green">"/submit"</span> <span class="color-pink">method</span>=<span class="color-green">"POST"</span>&gt;</p>
                        <p class="ml-30">    &lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">name</span>=<span class="color-green">"username"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Enter username"</span>&gt;</p>
                        <p class="ml-30">    &lt;button <span class="color-pink">type</span>=<span class="color-green">"submit"</span>&gt;Submit&lt;/button&gt;</p>
                        <p>&lt;/form&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>Action</span>: It specifies where the form data will be sent.</li>
                    <li><span className='background-grey'>Method</span>: It specifies how the data will be sent (<span className='background-grey'>GET</span> or <span className='background-grey'>POST</span>).</li>
                </ul>


                <h5 className='mt-5 mb-3'>Submitting Forms Using JavaScript</h5>
                <p>You can use JavaScript to:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>It prevent the default form submission behavior.</li>
                    <li>It validate or modify the form data.</li>
                    <li>Now you can programmatically submit the form.</li>
                </ul>

                <h6 className='mt-4'>Example: Submitting a Form with JavaScript</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;form <span class="color-pink">id</span>=<span class="color-green">"myForm"</span>&gt;></p>
                        <p class="ml-30">    &lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">id</span>=<span class="color-green">"username"</span> <span class="color-pink">name</span>=<span class="color-green">"username"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Enter username"</span>&gt;</p>
                        <p class="ml-30">    &lt;input <span class="color-pink">type</span>=<span class="color-green">"email"</span> <span class="color-pink">id</span>=<span class="color-green">"email"</span> <span class="color-pink">name</span>=<span class="color-green">"email"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Enter email"</span>&gt;</p>
                        <p class="ml-30">    &lt;button <span class="color-pink">type</span>=<span class="color-green">"submit"</span>&gt;Submit&lt;/button&gt;</p>
                        <p>&lt;/form&gt;</p>
                        <br />
                        <p>&lt;p <span class="color-pink">id</span>=<span class="color-green">"message"</span> <span class="color-pink">style</span>=<span class="color-green">"color: green;"</span>&gt;&lt;/p&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30 color-grey">    // Get the form element</p>
                        <p class="ml-30">    <span class="color-blue">const</span> form = document.<span class="color-red">getElementById</span>(<span class="color-green">"myForm"</span>);</p>
                        <br />
                        <p class="ml-30 color-grey">    // Add a 'submit' event listener</p>
                        <p class="ml-30">    form.<span class="color-red">addEventListener</span>(<span class="color-green">"submit"</span>, <span class="color-red">function</span> (event) {</p>
                        <p class="ml-60 color-grey">        // Prevent the default form submission</p>
                        <p class="ml-60">        event.<span class="color-red">preventDefault</span>();</p>
                        <br />
                        <p class="ml-60 color-grey">        // Get form values</p>
                        <p class="ml-60">        <span class="color-blue">const</span> username = document.<span class="color-red">getElementById</span>(<span class="color-green">"username"</span>).value.<span class="color-red">trim</span>();</p>
                        <p class="ml-60">        <span class="color-blue">const</span> email = document.<span class="color-red">getElementById</span>(<span class="color-green">"email"</span>).value.<span class="color-red">trim</span>();</p>
                        <br />
                        <p class="ml-60 color-grey">        // Validate form data</p>
                        <p class="ml-60">        <span class="color-blue">if</span> (<span class="color-pink">!</span>username || <span class="color-pink">!</span>email) {</p>
                        <p class="ml-90">            <span class="color-red">alert</span>(<span class="color-green">"All fields are required!"</span>);</p>
                        <p class="ml-90">            <span class="color-pink">return</span>;</p>
                        <p class="ml-60">        }</p>
                        <br />
                        <p class="ml-60 color-grey">        // Simulate form submission (you can replace this with an actual server call)</p>
                        <p class="ml-60">        document.<span class="color-red">getElementById</span>(<span class="color-green">"message"</span>).textContent = <span class="color-green">&#96;Form submitted! Username: $&#123;username&#125;, Email: $&#123;email&#125;&#96;</span>;</p>
                        <p class="ml-30">    });</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>

                <p>You can submit the form manually using JavaScript's <span className='background-grey'>submit()</span> method.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;form <span class="color-pink">id</span>=<span class="color-green">"programmaticForm"</span> <span class="color-pink">action</span>=<span class="color-green">"/submit"</span> <span class="color-pink">method</span>=<span class="color-green">"POST"</span>&gt;</p>
                        <p class="ml-30">    &lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">name</span>=<span class="color-green">"username"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Enter username"</span>&gt;</p>
                        <p>&lt;/form&gt;</p>
                        <br />
                        <p>&lt;button <span class="color-pink">id</span>=<span class="color-green">"submitButton"</span>&gt;Submit Form&lt;/button&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">    <span class="color-blue">const</span> form = document.<span class="color-red">getElementById</span>(<span class="color-green">"programmaticForm"</span>);</p>
                        <p class="ml-30">    <span class="color-blue">const</span> submitButton = document.<span class="color-red">getElementById</span>(<span class="color-green">"submitButton"</span>);</p>
                        <br />
                        <p class="ml-30 color-grey">    // Trigger form submission programmatically</p>
                        <p class="ml-30">    submitButton.<span class="color-red">addEventListener</span>(<span class="color-green">"click"</span>, <span class="color-red">function</span> () {</p>
                        <p class="ml-60">        form.<span class="color-red">submit</span>();</p>
                        <p class="ml-30">    });</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>




            </div>
        </section>
    )
}