import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/date-get-methods";

export default function DateGetMethods() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Date Get Methods in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/date-formats',
            'next': '/javascript/date-set-methods'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Date Get Methods</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>Date</strong> <span className='background-grey'>get</span> <strong>methods</strong> in JavaScript are used to retrieve specific parts of a date, such as the year, month, day, hours, minutes, etc., from a <span className='background-grey'>Date</span> object. These methods allow you to extract detailed information about a date.
                </p>

                <h5 className='mt-5 mb-3'>Examples</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Get Full Year</strong> (<span className='background-grey'>getFullYear()</span>)
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> today = <span class="color-blue">new</span> <span class="color-red">Date</span>();</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(today.<span class="color-red">getFullYear</span>()); <span class="color-grey">// Output: 2024</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Get Month</strong> (<span className='background-grey'>getMonth()</span>)
                        <p>Here, Month is 0-based (January = 0, February = 1, ...).</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> today = <span class="color-blue">new</span> <span class="color-red">Date</span>();</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(today.<span class="color-red">getMonth</span>()); <span class="color-grey">// Output: 10 (November)</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Get Day of the Month</strong> (<span className='background-grey'>getDate()</span>)
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> today = <span class="color-blue">new</span> <span class="color-red">Date</span>();</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(today.<span class="color-red">getDate</span>()); <span class="color-grey">// Output: 29</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Get Day of the Week</strong> (<span className='background-grey'>getDay()</span>)
                        <p>It returns a number where Sunday = 0, Monday = 1, ..., Saturday = 6.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> today = <span class="color-blue">new</span> <span class="color-red">Date</span>();</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(today.<span class="color-red">getDay</span>()); <span class="color-grey">// Output: 5 (Friday)</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Get Time in Milliseconds Since Epoch</strong> (<span className='background-grey'>getTime()</span>)
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> today = <span class="color-blue">new</span> <span class="color-red">Date</span>();</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(today.<span class="color-red">getTime</span>()); <span class="color-grey">// Example Output: 1734970200000</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Get Hours, Minutes, and Seconds</strong>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> now = <span class="color-blue">new</span> <span class="color-red">Date</span>();</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(now.<span class="color-red">getHours</span>());   <span class="color-grey">// Output: 10 (e.g., 10 AM)</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(now.<span class="color-red">getMinutes</span>()); <span class="color-grey">// Output: 30</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(now.<span class="color-red">getSeconds</span>()); <span class="color-grey">// Output: 45</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Get UTC Components</strong>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> now = <span class="color-blue">new</span> <span class="color-red">Date</span>();</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(now.<span class="color-red">getUTCFullYear</span>());  <span class="color-grey">// Output: 2024</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(now.<span class="color-red">getUTCMonth</span>());     <span class="color-grey">// Output: 10 (November)</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(now.<span class="color-red">getUTCDate</span>());      <span class="color-grey">// Output: 29</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(now.<span class="color-red">getUTCHours</span>());     <span class="color-grey">// Output: 10</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Get Timezone Offset</strong> (<span className='background-grey'>getTimezoneOffset()</span>)
                        <p>It returns the difference in minutes between local time and UTC.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> now = <span class="color-blue">new</span> <span class="color-red">Date</span>();</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(now.<span class="color-red">getTimezoneOffset</span>()); <span class="color-grey">// Example Output: -60 (for UTC+1)</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

            </div>
        </section>
    )
}