import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/js-2009";

export default function Js2009() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("JavaScript 2009 (ES5) | Aspirant's Home");
        const urls = {
            'previous': '/javascript/working-with-json',
            'next': '/javascript/js-2015'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>JavaScript 2009 (ES5)</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In 2009, the <strong>fifth edition of ECMAScript</strong> (commonly known as <strong>ES5</strong>) was introduced. It marked a major milestone in the development of JavaScript by standardizing several features and making JavaScript more reliable, consistent, and feature-rich. ES5 is widely supported in all modern and older browsers, making it a cornerstone of web development.
                </p>

                <h5 className='mt-5 mb-3'>ES5 Features</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li><a href="#strict">"use strict"</a></li>
                    <li><a href="#string-number">String[number] access</a></li>
                    <li><a href="#multiline-strings">Multiline strings</a></li>
                    <li><a href="#string-trim">String.trim()</a></li>
                    <li><a href="#array-isarray">Array.isArray()</a></li>
                    <li><a href="#array-foreach">Array forEach()</a></li>
                    <li><a href="#array-map">Array map()</a></li>
                    <li><a href="#array-filter">Array filter()</a></li>
                    <li><a href="#array-reduce">Array reduce()</a></li>
                    <li><a href="#array-reduce-right">Array reduceRight()</a></li>
                    <li><a href="#array-every">Array every()</a></li>
                    <li><a href="#array-some">Array some()</a></li>
                    <li><a href="#array-indexof">Array indexOf()</a></li>
                    <li><a href="#array-last-indexof">Array lastIndexOf()</a></li>
                    <li><a href="#json-parse">JSON.parse()</a></li>
                    <li><a href="#json-stringify">JSON.stringify()</a></li>
                    <li><a href="#date-now">Date.now()</a></li>
                    <li><a href="#date-toiso-string">Date toISOString()</a></li>
                    <li><a href="#date-tojson">Date toJSON()</a></li>
                    <li><a href="#getter-setter">Property getters and setters</a></li>
                    <li><a href="#word-property">Reserved words as property names</a></li>
                    <li><a href="#object-create">Object.create()</a></li>
                    <li><a href="#object-keys">Object.keys()</a></li>
                    <li><a href="#object-management">Object management</a></li>
                    <li><a href="#object-protection">Object protection</a></li>
                    <li><a href="#function-bind">Function bind()</a></li>
                    <li><a href="#trailing-commas">Trailing commas</a></li>
                </ul>


                <div id="strict">
                    <h5 className='mt-5 mb-3'>The "use strict" Directive</h5>
                    <p>
                        Strict Mode is a mode that helps catch common coding errors and enforces safer JavaScript practices. To enable strict mode add <span className='background-grey'>"use strict";</span> at the beginning of a script or function.
                    </p>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p class="color-green">"use strict";</p>
                            <p>x = <span class="color-pink">10</span>; <span class="color-grey">// Error: 'x' is not defined</span></p>
                            `
                        }}></div>
                    </div>
                </div>

                <div id="string-number">
                    <h5 className='mt-5 mb-3'>Property Access on Strings</h5>
                    <p>You can access individual characters in a string using <strong>bracket notation</strong> <span className='background-grey'>[index]</span>. The index starts at <span className='background-grey'>0</span> for the first character.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> str = <span class="color-green">"Hello, World!"</span>;</p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(str[<span class="color-pink">0</span>]); <span class="color-grey">// Output: "H" (First character)</span></p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(str[<span class="color-pink">7</span>]); <span class="color-grey">// Output: "W" (Character at index 7)</span></p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(str[str.length - <span class="color-pink">1</span>]); <span class="color-grey">// Output: "!" (Last character)</span></p>
                            `
                        }}></div>
                    </div>
                    <p>You can also access characters using the <span className='background-grey'>charAt(index)</span> method.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> str = <span class="color-green">"JavaScript"</span>;</p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(str.<span class="color-red">charAt</span>(<span class="color-pink">0</span>)); <span class="color-grey">// Output: "J" (First character)</span></p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(str.<span class="color-red">charAt</span>(<span class="color-pink">4</span>)); <span class="color-grey">// Output: "S" (Character at index 4)</span></p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(str.<span class="color-red">charAt</span>(str.length - <span class="color-pink">1</span>)); <span class="color-grey">// Output: "t" (Last character)</span></p>
                            `
                        }}></div>
                    </div>
                </div>


                <div id="multiline-strings">
                    <h5 className='mt-5 mb-3'>Strings Over Multiple Lines</h5>
                    <p>In JavaScript, you may encounter situations where you need to write a string that spans multiple lines. There are two primary ways to achieve this:</p>
                    <strong>1. Using Template Literals (Preferred)</strong>
                    <p>Template literals allow you to create multi-line strings easily without requiring special characters. They use backticks <span className='background-grey'>`</span> instead of single or double quotes.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> multiLineString =  <span class="color-green">&#96;This is a string</span></p>
                            <p class="color-green">that spans multiple</p>
                            <p class="color-green">lines.&#96;;</p>
                            <br />
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(multiLineString);</p>
                            <p class="color-grey">// Output:</p>
                            <p class="color-grey">// This is a string</p>
                            <p class="color-grey">// that spans multiple</p>
                            <p class="color-grey">// lines.</p>
                            `
                        }}></div>
                    </div>
                    <strong>2. Using Backslash (\) for Line Continuation</strong>
                    <p>In older versions of JavaScript or if not using template literals, you can use the backslash (<span className='background-grey'>\</span>) at the end of a line to indicate that the string continues on the next line.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> multiLineString = <span class="color-green">"This is a string &#92;</span></p>
                            <p class="color-green">that spans multiple &#92;</p>
                            <p class="color-green">lines.";</p>
                            <br />
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(multiLineString);</p>
                            <p class="color-grey">// Output: This is a string that spans multiple lines.</p>
                            `
                        }}></div>
                    </div>

                </div>


                <div id="string-trim">
                    <h5 className='mt-5 mb-3'>String trim()</h5>
                    <p>The <span className='background-grey'>trim()</span> method in JavaScript is used to remove whitespace from both the beginning and the end of a string. Whitespace includes spaces, tabs, and line breaks.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> str = <span class="color-green">"   Hello, World!   "</span>;</p>
                            <p><span class="color-blue">const</span> trimmedStr = str.<span class="color-red">trim</span>();</p>
                            <br />
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Original String:"</span>, <span class="color-green">&#96;"$&#123;str&#125;"&#96;</span>); <span class="color-grey">// Output: "   Hello, World!   "</span></p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Trimmed String:"</span>, <span class="color-green">&#96;"$&#96;&#123;trimmedStr&#125;"&#96;</span>); <span class="color-grey">// Output: "Hello, World!"</span></p>
                            `
                        }}></div>
                    </div>
                </div>

                <div id="array-isarray">
                    <h5 className='mt-5 mb-3'>Array.isArray()</h5>
                    <p>The <span className='background-grey'>Array.isArray()</span> method is used to check whether a given value is an array. It returns <span className='background-grey'>true</span> if the value is an array and <span className='background-grey'>false</span> otherwise.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(Array.<span class="color-red">isArray</span>([<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>])); <span class="color-grey">// Output: true</span></p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(Array.<span class="color-red">isArray</span>(<span class="color-green">"Hello"</span>));  <span class="color-grey">// Output: false</span></p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(Array.<span class="color-red">isArray</span>({}));       <span class="color-grey">// Output: false</span></p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(Array.<span class="color-red">isArray</span>(<span class="color-blue">null</span>));     <span class="color-grey">// Output: false</span></p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(Array.<span class="color-red">isArray</span>(undefined)); <span class="color-grey">// Output: false</span></p>
                            `
                        }}></div>
                    </div>
                </div>


                <div id="array-foreach">
                    <h5 className='mt-5 mb-3'>Array forEach()</h5>
                    <p>The <span className='background-grey'>forEach()</span> method in JavaScript is used to execute a provided function once for each element in an array. It's a simple way to iterate over an array without needing a traditional for loop.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> numbers = [<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>, <span class="color-pink">4</span>, <span class="color-pink">5</span>];</p>
                            <p>numbers.<span class="color-red">forEach</span>(num => {</p>
                            <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(num); <span class="color-grey">// Output: 1, 2, 3, 4, 5 (one at a time)</span></p>
                            <p>});</p>
                            `
                        }}></div>
                    </div>
                </div>


                <div id="array-map">
                    <h5 className='mt-5 mb-3'>Array map()</h5>
                    <p>The <span className='background-grey'>map()</span> method in JavaScript is used to create a new array by applying a given function to each element of the original array. It is one of the most commonly used higher-order functions in JavaScript for transforming data.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> numbers = [<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>, <span class="color-pink">4</span>, <span class="color-pink">5</span>];</p>
                            <p><span class="color-blue">const</span> doubled = numbers.<span class="color-red">map</span>(num => num * <span class="color-pink">2</span>);</p>
                            
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(doubled); <span class="color-grey">// Output: [2, 4, 6, 8, 10]</span></p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(numbers); <span class="color-grey">// Output: [1, 2, 3, 4, 5] (unchanged)</span></p>
                            `
                        }}></div>
                    </div>
                </div>


                <div id="array-filter">
                    <h5 className='mt-5 mb-3'>Array filter()</h5>
                    <p>The <span className='background-grey'>filter()</span> method in JavaScript is used to create a new array containing only the elements of the original array that pass a specific condition (i.e., the callback function returns <span className='background-grey'>true</span> for those elements).</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> numbers = [<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>, <span class="color-pink">4</span>, <span class="color-pink">5</span>, <span class="color-pink">6</span>];</p>
                            <p><span class="color-blue">const</span> evenNumbers = numbers.<span class="color-red">filter</span>(num => num % <span class="color-pink">2</span> === <span class="color-pink">0</span>);</p>
                            
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(evenNumbers); <span class="color-grey">// Output: [2, 4, 6]</span></p>
                            `
                        }}></div>
                    </div>
                </div>


                <div id="array-reduce">
                    <h5 className='mt-5 mb-3'>Array reduce()</h5>
                    <p>The <span className='background-grey'>reduce()</span> method in JavaScript is used to <strong>reduce an array</strong> to a single value by applying a callback function to each element in the array, one at a time.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> numbers = [<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>, <span class="color-pink">4</span>];</p>
                            <p><span class="color-blue">const</span> sum = numbers.<span class="color-red">reduce</span>((acc, curr) => acc + curr, <span class="color-pink">0</span>);</p>
                            
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(sum); <span class="color-grey">// Output: 10</span></p>
                            `
                        }}></div>
                    </div>
                </div>


                <div id="array-reduce-right">
                    <h5 className='mt-5 mb-3'>Array reduceRight()</h5>
                    <p>The <span className='background-grey'>reduceRight()</span> method in JavaScript works similarly to the <span className='background-grey'>reduce()</span> method, but it processes the array elements from <strong>right to left</strong> (starting from the last element and moving to the first).</p>
                    <h6 className='mt-3'>Example : Reversing a String</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> letters = [<span class="color-green">"H"</span>, <span class="color-green">"e"</span>, <span class="color-green">"l"</span>, <span class="color-green">"l"</span>, <span class="color-green">"o"</span>];</p>
                            <p><span class="color-blue">const</span> reversed = letters.<span class="color-red">reduceRight</span>((acc, curr) => acc + curr, <span class="color-green">""</span>);</p>
                            
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(reversed); <span class="color-grey">// Output: "olleH"</span></p>
                            `
                        }}></div>
                    </div>
                </div>


                <div id="array-every">
                    <h5 className='mt-5 mb-3'>Array every()</h5>
                    <p>The <span className='background-grey'>every()</span> method in JavaScript is used to test whether all elements in an array pass a given condition (callback function). If all elements satisfy the condition, it returns <span className='background-grey'>true</span>; otherwise, it returns <span className='background-grey'>false</span>.</p>
                    <h6 className='mt-3'>Example : Checking if All Numbers Are Positive</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> numbers = [<span class="color-pink">10</span>, <span class="color-pink">20</span>, <span class="color-pink">30</span>, <span class="color-pink">40</span>];</p>
                            <p><span class="color-blue">const</span> allPositive = numbers.<span class="color-red">every</span>(num => num > <span class="color-pink">0</span>);</p>
                            
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(allPositive); <span class="color-grey">// Output: true</span></p>
                            `
                        }}></div>
                    </div>
                </div>

                <div id="array-some">
                    <h5 className='mt-5 mb-3'>Array some()</h5>
                    <p>The <span className='background-grey'>some()</span> method in JavaScript checks whether at least one element in an array passes a given test (provided by a callback function). If at least one element satisfies the condition, it returns <span className='background-grey'>true</span>; otherwise, it returns <span className='background-grey'>false</span>.</p>
                    <h6 className='mt-3'>Example : Checking for a String in an Array</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> fruits = [<span class="color-green">"apple"</span>, <span class="color-green">"banana"</span>, <span class="color-green">"cherry"</span>];</p>
                            <p><span class="color-blue">const</span> hasBanana = fruits.<span class="color-red">some</span>(fruit => fruit === <span class="color-green">"banana"</span>);</p>
                            
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(hasBanana); <span class="color-grey">// Output: true</span></p>
                            `
                        }}></div>
                    </div>
                </div>


                <div id="array-indexof">
                    <h5 className='mt-5 mb-3'>Array indexOf()</h5>
                    <p>The <span className='background-grey'>indexOf()</span> method in JavaScript is used to search for the first occurrence of a specified element in an array. It returns the <strong>index</strong> of the element if found, or <span className='background-grey'>-1</span> if the element is not present in the array.</p>
                    <h6 className='mt-3'>Example : Finding an Element in an Array</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> fruits = [<span class="color-green">"apple"</span>, <span class="color-green">"banana"</span>, <span class="color-green">"cherry"</span>, <span class="color-green">"apple"</span>];</p>
                            <p><span class="color-blue">const</span> index = fruits.<span class="color-red">indexOf</span>(<span class="color-green">"apple"</span>);</p>
                            
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(index); <span class="color-grey">// Output: 0 (first occurrence of "apple")</span></p>
                            `
                        }}></div>
                    </div>
                </div>


                <div id="array-last-indexof">
                    <h5 className='mt-5 mb-3'>Array lastIndexOf()</h5>
                    <p>The <span className='background-grey'>lastIndexOf()</span> method is used to find the last occurrence of a specified element in an array. It searches the array backward, starting from the end (or a given <span className='background-grey'>fromIndex</span>), and returns the <strong>index</strong> of the element. If the element is not found, it returns <span className='background-grey'>-1</span>.</p>
                    <h6 className='mt-3'>Example : Finding the Last Occurrencey</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> fruits = [<span class="color-green">"apple"</span>, <span class="color-green">"banana"</span>, <span class="color-green">"cherry"</span>, <span class="color-green">"apple"</span>];</p>
                            <p><span class="color-blue">const</span> index = fruits.<span class="color-red">lastIndexOf</span>(<span class="color-green">"apple"</span>);</p>
                            
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(index); <span class="color-grey">// Output: 3 (last occurrence of "apple")</span></p>
                            `
                        }}></div>
                    </div>
                </div>


                <div id="json-parse">
                    <h5 className='mt-5 mb-3'>JSON.parse()</h5>
                    <p>The <span className='background-grey'>JSON.parse()</span> method in JavaScript is used to convert a <strong>JSON string</strong> into a <strong>JavaScript object</strong>. JSON (JavaScript Object Notation) is commonly used for data interchange between servers and clients because it is lightweight and easy to read.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> jsonString = <span class="color-green">'{"name": "John", "age": 30, "isStudent": false}'</span>;</p>
                            <p><span class="color-blue">const</span> obj = JSON.<span class="color-red">parse</span>(jsonString);</p>
                            
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(obj);</p>
                            <p class="color-grey">// Output: { name: "John", age: 30, isStudent: false }</p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(obj.<span class="color-pink">name</span>); <span class="color-grey">// Output: John</span></p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(obj.<span class="color-pink">age</span>);  <span class="color-grey">// Output: 30</span></p>
                            `
                        }}></div>
                    </div>
                </div>


                <div id="json-stringify">
                    <h5 className='mt-5 mb-3'>JSON.stringify()</h5>
                    <p>The <span className='background-grey'>JSON.stringify()</span> method in JavaScript is used to convert a <strong>JavaScript object or array</strong> into a <strong>JSON string</strong>. This string can be sent over the network, stored in files, or used in other contexts where JSON format is required.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> obj = { <span class="color-pink">name</span>: <span class="color-green">"John"</span>, <span class="color-pink">age</span>: <span class="color-pink">30</span>, <span class="color-pink">isStudent</span>: <span class="color-blue">false</span> };</p>
                            <p><span class="color-blue">const</span> jsonString = JSON.<span class="color-red">stringify</span>(obj);</p>
                            
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(jsonString);</p>
                            <p class="color-grey">// Output: '{"name":"John","age":30,"isStudent":false}'</p>
                            `
                        }}></div>
                    </div>
                </div>


                <div id="date-now">
                    <h5 className='mt-5 mb-3'>Date.now()</h5>
                    <p>The <span className='background-grey'>Date.now()</span> method in JavaScript is a static method of the <span className='background-grey'>Date</span> object that returns the <strong>number of milliseconds</strong> elapsed since <strong>January 1, 1970, 00:00:00 UTC</strong> (also known as the Unix Epoch).</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> timestamp = Date.<span class="color-red">now</span>();</p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(timestamp);</p>
                            <p class="color-grey">// Example Output: 1702292395543</p>
                            `
                        }}></div>
                    </div>
                </div>


                <div id="date-toiso-string">
                    <h5 className='mt-5 mb-3'>Date toISOString()</h5>
                    <p>The <span className='background-grey'>toISOString()</span> method is used to convert a <span className='background-grey'>Date</span> object into a string, formatted in <strong>ISO 8601</strong> standard (a globally accepted format for date and time). This method is useful when you need to represent dates in a standardized way, especially when working with APIs, databases, or logging systems.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> now = <span class="color-blue">new</span> <span class="color-red">Date</span>();</p>
                            <p><span class="color-blue">const</span> isoString = now.<span class="color-red">toISOString</span>();</p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(isoString);</p>
                            <p class="color-grey">// Example Output: "2024-12-11T11:15:30.123Z"</p>
                            `
                        }}></div>
                    </div>
                </div>


                <div id="date-tojson">
                    <h5 className='mt-5 mb-3'>Date toJSON()</h5> 
                    <p>The <span className='background-grey'>toJSON()</span> method in JavaScript is used to convert a <span className='background-grey'>Date</span> object into a JSON-compatible string. Internally, it uses the <span className='background-grey'>toISOString()</span> method to produce the string in the <strong>ISO 8601</strong> format.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> now = <span class="color-blue">new</span> <span class="color-red">Date</span>();</p>
                            <p><span class="color-blue">const</span> jsonDate = now.<span class="color-red">toJSON</span>();</p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(jsonDate);</p>
                            <p class="color-grey">// Example Output: "2024-12-11T12:00:30.123Z"</p>
                            `
                        }}></div>
                    </div>
                </div>

                <div id="getter-setter">
                    <h5 className='mt-5 mb-3'>Property Getters and Setters</h5>
                    <p>In JavaScript, property getters and setters allow you to define custom behavior when accessing or modifying the values of an object's properties. These can be used to control how a property is read or written.</p>
                    <p>A getter is a method that gets the value of a property. You can define it using the <span className='background-grey'>get</span> keyword.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> person = {</p>
                            <p class="ml-30">    <span class="color-pink">firstName</span>: <span class="color-green">"John"</span>,</p>
                            <p class="ml-30">    <span class="color-pink">lastName</span>: <span class="color-green">"Doe"</span>,</p>
                            <p class="ml-30 color-grey">    // Getter for full name</p>
                            <p class="ml-30">    <span class="color-blue">get</span> <span class="color-red">fullName</span>() {</p>
                            <p class="ml-60">        <span class="color-pink">return</span> <span class="color-green">&#96;$&#123;this.firstName&#125; $&#123;this.lastName&#125;&#96;</span>;</p>
                            <p class="ml-30">    }</p>
                            <p>};</p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(person.<span class="color-pink">fullName</span>); <span class="color-grey">// Outputs: John Doe</span></p>
                            `
                        }}></div>
                    </div>

                    <p>A setter is a method that sets the value of a property. You can define it using the <span className='background-grey'>set</span> keyword.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> person = {</p>
                            <p class="ml-30">    <span class="color-pink">firstName</span>: <span class="color-green">"John"</span>,</p>
                            <p class="ml-30">    <span class="color-pink">lastName</span>: <span class="color-green">"Doe"</span>,</p>
                            <p class="ml-30 color-grey">    // Setter for full name</p>
                            <p class="ml-30">    <span class="color-blue">set</span> <span class="color-red">fullName</span>(name) {</p>
                            <p class="ml-60">        <span class="color-blue">const</span> parts = name.<span class="color-red">split</span>(<span class="color-green">" "</span>);</p>
                            <p class="ml-60">        <span class="color-blue">this</span>.firstName = parts[<span class="color-pink">0</span>];</p>
                            <p class="ml-60">        <span class="color-blue">this</span>.lastName = parts[<span class="color-pink">1</span>] || <span class="color-green">""</span>;</p>
                            <p class="ml-30">    }</p>
                            <p>};</p>
                              
                            <p>person.<span class="color-pink">fullName</span> = <span class="color-green">"Jane Smith"</span>;</p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(person.<span class="color-pink">firstName</span>); <span class="color-grey">// Outputs: Jane</span></p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(person.<span class="color-pink">lastName</span>);  <span class="color-grey">// Outputs: Smith</span></p>
                            `
                        }}></div>
                    </div>
                </div>


                <div id="word-property">
                    <h5 className='mt-5 mb-3'>Object.defineProperty()</h5>
                    <p><span className='background-grey'>Object.defineProperty()</span> is a method in JavaScript that allows you to define or modify a property on an object with fine-grained control over its behavior. It provides more options than directly adding or modifying properties.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> person = {};</p>

                            <p>Object.<span class="color-red">defineProperty</span>(person, <span class="color-green">'name'</span>, {</p>
                            <p class="ml-30">    <span class="color-pink">value</span>: <span class="color-green">'John'</span>,</p>
                            <p class="ml-30">    <span class="color-pink">writable</span>: <span class="color-blue">false</span>,       <span class="color-grey">// Cannot change the value</span></p>
                            <p class="ml-30">    <span class="color-pink">configurable</span>: <span class="color-blue">false</span>,   <span class="color-grey">// Cannot delete or modify the property</p>
                            <p class="ml-30">    <span class="color-pink">enumerable</span>: <span class="color-blue">true</span>       <span class="color-grey">// Shows up in enumeration</span></p>
                            <p>});</p>
                            
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(person.<span class="color-pink">name</span>); <span class="color-grey">// Outputs: John</span></p>
                            <p>person.<span class="color-pink">name</span> = <span class="color-green">'Jane'</span>;      <span class="color-grey">// No effect because writable is false</span></p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(person.<span class="color-pink">name</span>); <span class="color-grey">// Outputs: John</span></p>
                            `
                        }}></div>
                    </div>
                </div>


                <div id="object-create">
                    <h5 className='mt-5 mb-3'>Object.create()</h5>
                    <p><span className='background-grey'>Object.create()</span> is a JavaScript method that creates a new object, setting its prototype to an existing object. It allows for fine-grained control over an object's structure and behavior.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> animal = {</p>
                            <p class="ml-30">    <span class="color-red">speak</span>() {</p>
                            <p class="ml-60">      <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">&#96;$&#123;this.name&#125; makes a sound.&#96;</span>);</p>
                            <p class="ml-30">    }</p>
                            <p>};</p>
                              
                            <p><span class="color-blue">const</span> dog = Object.<span class="color-red">create</span>(animal);</p>
                            <p>dog.<span class="color-pink">name</span> = <span class="color-green">"Rex"</span>;</p>
                            <p>dog.<span class="color-red">speak</span>(); <span class="color-grey">// Outputs: Rex makes a sound.</span></p>
                            `
                        }}></div>
                    </div>
                </div>


                <div id="object-keys">
                    <h5 className='mt-5 mb-3'>Object.keys()</h5>
                    <p><span className='background-grey'>Object.keys()</span> is a built-in JavaScript method that retrieves the keys (or property names) of an object's <strong>enumerable properties</strong>. It returns these keys as an array of strings.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> person = {</p>
                            <p class="ml-30">    <span class="color-pink">name</span>: <span class="color-green">"Alice"</span>,</p>
                            <p class="ml-30">    <span class="color-pink">age</span>: <span class="color-pink">25</span>,</p>
                            <p class="ml-30">    <span class="color-pink">city</span>: <span class="color-green">"New York"</span></p>
                            <p>};</p>
                              
                            <p><span class="color-blue">const</span> keys = Object.<span class="color-red">keys</span>(person);</p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(keys); <span class="color-grey">// Outputs: [ 'name', 'age', 'city' ]</span></p>
                            `
                        }}></div>
                    </div>
                </div>


                <div id="object-management">
                    <h5 className='mt-5 mb-3'>Object Management</h5>
                    <p>ES5 added new Object management methods to JavaScript:</p>
                    <h6 className='mt-3'>Some Method Syntax -</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p class="color-grey">// Adding or changing an object property</p>
                            <p>Object.<span class="color-red">defineProperty</span>(object, property, descriptor)</p>
                            <br />
                            <p class="color-grey">// Adding or changing object properties</p>
                            <p>Object.<span class="color-red">defineProperties</span>(object, descriptors)</p>
                            <br />
                            <p class="color-grey">// Accessing a Property</p>
                            <p>Object.<span class="color-red">getOwnPropertyDescriptor</span>(object, property)</p>
                            <br />
                            <p class="color-grey">// Accessing Properties</p>
                            <p>Object.<span class="color-red">getOwnPropertyDescriptors</span>(object)</p>
                            <br />
                            <p class="color-grey">// Returns all properties as an array</p>
                            <p>Object.<span class="color-red">getOwnPropertyNames</span>(object)</p>
                            <br />
                            <p class="color-grey">// Accessing the prototype</p>
                            <p>Object.<span class="color-red">getPrototypeOf</span>(object)</p>
                            `
                        }}></div>
                    </div>
                </div>

                <div id="object-protection">
                    <h5 className='mt-5 mb-3'>Object Protection</h5>
                    <p>ES5 added Object protection methods to JavaScript:</p>
                    <h6 className='mt-3'>Some Method Syntax -</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p class="color-grey">// Prevents adding properties to an object</p>
                            <p>Object.<span class="color-red">preventExtensions</span>(object)</p>
                            <br />
                            <p class="color-grey">// Returns true if properties can be added to an object</p>
                            <p>Object.<span class="color-red">isExtensible</span>(object)</p>
                            <br />
                            <p class="color-grey">// Prevents changes of object properties (not values)</p>
                            <p>Object.<span class="color-red">seal</span>(object)</p>
                            <br />
                            <p class="color-grey">// Returns true if object is sealed</p>
                            <p>Object.<span class="color-red">isSealed</span>(object)</p>
                            <br />
                            <p class="color-grey">// Prevents any changes to an object</p>
                            <p>Object.<span class="color-red">freeze</span>(object)</p>
                            <br />
                            <p class="color-grey">// Returns true if object is frozen</p>
                            <p>Object.<span class="color-red">isFrozen</span>(object)</p>
                            `
                        }}></div>
                    </div>
                </div>

                <div id="function-bind">
                    <h5 className='mt-5 mb-3'>Function bind()</h5>
                    <p>The <span className='background-grey'>bind()</span> method in JavaScript is used to create a new function with a specific <span className='background-grey'>this</span> value and, optionally, predefined arguments. It allows you to control the context (<span className='background-grey'>this</span>) and arguments of a function when it's called.</p>
                    <h6 className='mt-3'>Example</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> person = {</p>
                            <p class="ml-30">    <span class="color-pink">name</span>: <span class="color-green">'Alice'</span>,</p>
                            <p class="ml-30">    <span class="color-pink">greet</span>: <span class="color-red">function</span>() {</p>
                            <p class="ml-60">          <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">&#96;Hello, my name is $&#123;this.name&#125;&#96;</span>);</p>
                            <p class="ml-30">    }</p>
                            <p>};</p>
                            <br />  
                            <p><span class="color-blue">const</span> anotherPerson = { <span class="color-pink">name</span>: <span class="color-green">'Bob'</span> };</p>
                            <br />  
                            <p><span class="color-blue">const</span> greetBob = person.greet.<span class="color-red">bind</span>(anotherPerson);</p>
                            <p><span class="color-red">greetBob</span>(); <span class="color-grey">// Outputs: Hello, my name is Bob</span></p>
                            `
                        }}></div>
                    </div>
                </div>

                <div id="trailing-commas">
                    <h5 className='mt-5 mb-3'>Trailing Commas</h5>
                    <p>In <strong>Arrays</strong>, You can include a trailing comma after the last element of an array. The trailing comma is ignored by the JavaScript engine.</p>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> numbers = [<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>,];</p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(numbers); <span class="color-grey">// Outputs: [1, 2, 3]</span></p>
                            `
                        }}></div>
                    </div>
                    <p>In <strong>Objects</strong>, Trailing commas can also be used in object literals. Similar to arrays, the trailing comma is ignored.</p>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">const</span> person = {</p>
                            <p class="ml-30">    <span class="color-pink">name</span>: <span class="color-green">'Alice'</span>,</p>
                            <p class="ml-30">    <span class="color-pink">age</span>: <span class="color-pink">30</span>,</p>
                            <p>};</p>
                            <p><span class="color-pink">console</span>.<span class="color-red">log</span>(person);</p>
                            <p class="color-grey">// Outputs: { name: 'Alice', age: 30 }</p>
                            `
                        }}></div>
                    </div>
                    <p>But <strong>JSON</strong> does not allow trailing commas.</p>
                    <h6>JSON Objects</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p class="color-grey">// Allowed:</p>
                            <p><span class="color-blue">var</span> person = <span class="color-green">'{"firstName":"John", "lastName":"Doe", "age":46}'</span></p>
                            <p>JSON.<span class="color-red">parse</span>(person)</p>
                            <br />
                            <p class="color-grey">// Not allowed:</p>
                            <p><span class="color-blue">var</span> person = <span class="color-green">'{"firstName":"John", "lastName":"Doe", "age":46,}'</span></p>
                            <p>JSON.<span class="color-red">parse</span>(person)</p>
                            `
                        }}></div>
                    </div>
                    <h6>JSON Arrays</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p class="color-grey">// Allowed:</p>
                            <p>points = [<span class="color-pink">40</span>, <span class="color-pink">100</span>, <span class="color-pink">1</span>, <span class="color-pink">5</span>, <span class="color-pink">25</span>, <span class="color-pink">10</span>]</p>
                            <br />
                            <p class="color-grey">// Not allowed:</p>
                            <p>points = [<span class="color-pink">40</span>, <span class="color-pink">100</span>, <span class="color-pink">1</span>, <span class="color-pink">5</span>, <span class="color-pink">25</span>, <span class="color-pink">10</span>,]</p>
                            `
                        }}></div>
                    </div>
                </div>




            </div>
        </section>
    )
}