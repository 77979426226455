import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/js-2020";

export default function Js2020() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("ECMAScript 2020 | Aspirant's Home");
        const urls = {
            'previous': '/javascript/js-2019',
            'next': '/javascript/js-2021'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>New Features in ECMAScript 2020</h3>
            <div className='mt-4 mb-5'>
                <p>
                    ECMAScript 2020, also known as ES11, introduced several powerful features and improvements to JavaScript, making the language more versatile and developer-friendly. Here’s a breakdown of the key features included in ES2020:
                </p>

                <h5 className='mt-5 mb-3'>1. Optional Chaining (<span className='background-grey'>?.</span>)</h5>
                <p>Optional chaining provides a safe way to access deeply nested object properties without having to manually check for null or undefined at each level.</p>
                <h6>Example</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">const</span> user = {</p>
                        <p class="ml-30">    <span class="color-pink">name</span>: <span class="color-green">"Alice"</span>,</p>
                        <p class="ml-30">    <span class="color-pink">address</span>: {</p>
                        <p class="ml-60">        <span class="color-pink">city</span>: <span class="color-green">"Wonderland"</span>,</p>
                        <p class="ml-30">    },</p>
                        <p>};</p>
                        <br />  
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(user<span class="color-pink">?.</span>address<span class="color-pink">?.</span>city); <span class="color-grey">// Output: Wonderland</span></p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(user<span class="color-pink">?.</span>contact<span class="color-pink">?.</span>phone); <span class="color-grey">// Output: undefined (No error)</span></p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>2. Nullish Coalescing Operator (<span className='background-grey'>??</span>)</h5>
                <p>The nullish coalescing operator returns the right-hand operand if the left-hand operand is <span className='background-grey'>null</span> or <span className='background-grey'>undefined</span>. It’s useful for providing default values without misinterpreting <span className='background-grey'>false</span>, <span className='background-grey'>0</span>, or <span className='background-grey'>""</span> as <span className='background-grey'>null</span> or <span className='background-grey'>undefined</span>.</p>
                <h6>Example</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">const</span> value = <span class="color-blue">null</span> <span class="color-pink">??</span> <span class="color-green">"Default Value"</span>;</p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(value); <span class="color-grey">// Output: Default Value</span></p>
                        <br />
                        <p><span class="color-blue">const</span> num = <span class="color-pink">0</span> ?? <span class="color-pink">42</span>;</p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(num); <span class="color-grey">// Output: 0</span></p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>3. Dynamic import()</h5>
                <p>The <span className='background-grey'>import()</span> function allows modules to be loaded dynamically. This is particularly useful for code splitting and lazy loading, which can improve application performance.</p>
                <h6>Example</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">import</span>(<span class="color-green">'./module.js'</span>)</p>
                        <p>.<span class="color-red">then</span>((module) => {</p>
                        <p class="ml-30">    module.<span class="color-red">doSomething</span>();</p>
                        <p>})</p>
                        <p>.<span class="color-red">catch</span>((error) => {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">error</span>(<span class="color-green">"Error loading module:"</span>, error);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>4. BigInt</h5>
                <p>BigInt is a new primitive type that allows you to work with very large integers beyond the safe limit of <span className='background-grey'>Number.MAX_SAFE_INTEGER</span>. You can make BigInt number in two ways -</p>
                <h6>1. Appending <span className='background-grey'>n</span></h6>
                <p>Append the letter <span className='background-grey'>n</span> to the end of an integer to create a BigInt.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">const</span> bigNumber = <span class="color-pink">1234567890123456789012345678901234567890n</span>;</p>
                        `
                    }}></div>
                </div>
                <h6>Using <span className='background-grey'>BigInt()</span> Constructor</h6>
                <p>Use the <span className='background-grey'>BigInt()</span> function to convert a number or string to a BigInt.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">const</span> bigNumber = <span class="color-red">BigInt</span>(<span class="color-green">"1234567890123456789012345678901234567890"</span>);</p>
                        <p><span class="color-blue">const</span> anotherBigInt = <span class="color-red">BigInt</span>(<span class="color-pink">42</span>); <span class="color-grey">// From a regular number</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>5. Promise.allSettled()</h5>
                <p><span className='background-grey'>Promise.allSettled()</span> returns a promise that resolves when all the promises have settled (either resolved or rejected). It provides the outcome of each promise in an array.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">const</span> promises = [</p>
                        <p class="ml-30">    Promise.<span class="color-red">resolve</span>(<span class="color-pink">10</span>),</p>
                        <p class="ml-30">    Promise.<span class="color-red">reject</span>(<span class="color-green">"Error"</span>),</p>
                        <p class="ml-30">    Promise.<span class="color-red">resolve</span>(<span class="color-pink">20</span>),</p>
                        <p>];</p>
                        <br />  
                        <p>Promise.<span class="color-red">allSettled</span>(promises).<span class="color-red">then</span>((results) =></p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(results)</p>
                        <p>);</p>
                        <p class="color-grey">// Output:</p>
                        <p class="color-grey">// [</p>
                        <p class="color-grey">//     <span class="ml-30">{ status: 'fulfilled', value: 10 },</span></p>
                        <p class="color-grey">//     <span class="ml-30">{ status: 'rejected', reason: 'Error' }</span>,</p>
                        <p class="color-grey">//     <span class="ml-30">{ status: 'fulfilled', value: 20 }</span></p>
                        <p class="color-grey">// ]</p>
                        `
                    }}></div>
                </div>




            </div>
        </section>
    )
}