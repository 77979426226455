import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/string-templates";

export default function StringTemplates() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("String Templates in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/string-search',
            'next': '/javascript/numbers'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>String Templates</h3>
            <div className='mt-4 mb-5'>
                <p>
                    String templates, also known as <strong>template literals</strong>, are a modern way to create strings in JavaScript. They were introduced in <strong>ES6 (ECMAScript 2015)</strong> and provide a more powerful and flexible way to work with strings compared to traditional string methods.
                </p>

                <h5 className='mt-5 mb-3'>Key Features of String Templates</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Backticks</strong> ( <span className='background-grey'>&#96;</span>):
                        <p>String templates are enclosed in backticks, not quotes (<span className='background-grey'>'</span> or <span className='background-grey'>"</span>).</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">let</span> text = <span class="color-green">&#96;This is a string template!&#96;</span>;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Variable Interpolation:</strong>
                        <p>It allows embedding variables and expressions directly into strings using <span className='background-grey'>$&#123; &#125;</span>.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> name = <span class="color-green">"Alice"</span>;</p>
                                <p><span class="color-blue">let</span> greeting = <span class="color-green">&#96;Hello, $&#123;name&#125;!&#96;</span>;</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(greeting); <span class="color-grey">// Output: Hello, Alice!</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Multiline Strings:</strong>
                        <p>It easily create strings spanning multiple lines without using escape characters.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> multiline = <span class="color-green">&#96;This is a string</span></p>
                                <p class="color-green">that spans across</p>
                                <p class="color-green">multiple lines.&#96;;</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(multiline);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Expressions in Templates:</strong>
                        <p>You can include any JavaScript expression inside <span className='background-grey'>$&#123; &#125;</span>.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> a = <span class="color-pink">10</span>, b = <span class="color-pink">20</span>;</p>
                                <p><span class="color-blue">let</span> result = <span class="color-green">&#96;The sum of $&#123;a&#125; and $&#123;b&#125; is $&#123;a + b&#125;.&#96;</span>;</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(result); <span class="color-grey">// Output: The sum of 10 and 20 is 30.</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>Why Use String Templates?</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Readability</strong>
                        <p>It makes strings easier to read and write, especially when combining variables or using multiline strings.</p>
                    </li>
                    <li>
                        <strong>No Concatenation:</strong>
                        <p>We can avoid using <span className='background-grey'>+</span> for concatenating strings.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p class="color-grey">// Using traditional concatenation</p>
                                <p><span class="color-blue">let</span> name = <span class="color-green">"Alice"</span>;</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Hello, "</span> + name + <span class="color-green">"!"</span>);</p>
                                <br />
                                <p class="color-grey">// Using template literals</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">&#96;Hello, $&#123;name&#125;!&#96;</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Dynamic Content</strong>
                        <p>It embeds dynamic content like variables, calculations, or function calls directly.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> today = <span class="color-blue">new</span> <span class="color-red">Date</span>();</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">&#96;Today's date is $&#123;today.toDateString()&#125;.&#96;</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


            </div>
        </section>
    )
}