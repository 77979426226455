import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/string-functions/replace";

export default function REPLACE() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - String Functions - REPLACE()");
        path.setPreviousNext({});
        title.setKeyWords("mysql replace");
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/string-functions' onClick={() => path.setPathName('/mysql/string-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to String Functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - REPLACE() Function</h3>
                <div className='mt-3 mb-5'>
                    <p>
                        The <strong>`REPLACE()`</strong> function in MySQL is used to replace all occurrences of a specified substring within a string with another substring. This function is useful for modifying string values by replacing parts of them with new values.
                    </p>
                    <h5 className='mt-5 mb-3'>Syntax</h5>
                    <div className='codePalateBox mt-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-red">REPLACE</span>(string, search_string, replace_string)</p>
                            `
                        }}></div>
                    </div>
                    <h5 className='mt-5 mb-3'>Parameters:</h5>
                    <ul style={{listStyle: 'disc'}}>
                        <li><span className='background-grey'>string</span>: The original string where you want to perform the replacement.</li>
                        <li><span className='background-grey'>search_string</span>: The substring to search for within the string.</li>
                        <li><span className='background-grey'>replace_string</span>: The substring to replace the search_string with.</li>
                    </ul>
                    <h5 className='mt-5 mb-3'>How It Works:</h5>
                    <ul style={{listStyle: 'disc'}}>
                        <li>If <span className='background-grey'>search_string</span> is found within the <span className='background-grey'>string</span>, it is replaced with <span className='background-grey'>replace_string</span>.</li>
                        <li>If <span className='background-grey'>search_string</span> is not found, the <span className='background-grey'>string</span> remains unchanged.</li>
                        <li>This function is case-sensitive.</li>
                    </ul>
                </div>
                <div className='mt-5 mb-5'>
                    <h5 className='mt-5 mb-3'>Example 1: Basic Usage</h5>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>SELECT <span class="color-red">REPLACE</span>(<span class="color-green">'Hello World'</span>, <span class="color-green">'World'</span>, <span class="color-green">'MySQL'</span>);</p>
                            `
                        }}></div>
                    </div>
                    <h6>Output:</h6>
                    <p><span className='background-grey'>Hello MySQL</span></p>



                    <h5 className='mt-5 mb-3'>Example 2: Replacing Part of a Column Value</h5>
                    <div className='mt-3 mb-2'>
                        <p>
                            Let's consider an example using the <strong>`REPLACE()`</strong> function with an <strong>`employees`</strong> table. The <strong>`REPLACE()`</strong> function replaces all occurrences of a specified string with another string.
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>first_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>last_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>position</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>John</td>
                                    <td style={{border: '1px dashed #ccc'}}>Doe</td>
                                    <td style={{border: '1px dashed #ccc'}}>Software Engineer</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Jane</td>
                                    <td style={{border: '1px dashed #ccc'}}>Smith</td>
                                    <td style={{border: '1px dashed #ccc'}}>Project Manager</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Emily</td>
                                    <td style={{border: '1px dashed #ccc'}}>Johnson</td>
                                    <td style={{border: '1px dashed #ccc'}}>Business Analyst</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>Michael</td>
                                    <td style={{border: '1px dashed #ccc'}}>Brown</td>
                                    <td style={{border: '1px dashed #ccc'}}>Software Engineer</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>5</td>
                                    <td style={{border: '1px dashed #ccc'}}>Jessica</td>
                                    <td style={{border: '1px dashed #ccc'}}>Williams</td>
                                    <td style={{border: '1px dashed #ccc'}}>Product Manager</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            Now, if we want to replace the term "Manager" with "Lead" in the <strong>`position`</strong> column.
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> id, first_name, last_name, position, REPLACE(position, 'Manager', 'Lead') <span class="color-blue">AS</span> new_position <span class="color-blue">FROM</span> employees;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>first_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>last_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>position</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>new_position</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>John</td>
                                    <td style={{border: '1px dashed #ccc'}}>Doe</td>
                                    <td style={{border: '1px dashed #ccc'}}>Software Engineer</td>
                                    <td style={{border: '1px dashed #ccc'}}>Software Engineer</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Jane</td>
                                    <td style={{border: '1px dashed #ccc'}}>Smith</td>
                                    <td style={{border: '1px dashed #ccc'}}>Project Manager</td>
                                    <td style={{border: '1px dashed #ccc'}}>Project Lead</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Emily</td>
                                    <td style={{border: '1px dashed #ccc'}}>Johnson</td>
                                    <td style={{border: '1px dashed #ccc'}}>Business Analyst</td>
                                    <td style={{border: '1px dashed #ccc'}}>Business Analyst</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>Michael</td>
                                    <td style={{border: '1px dashed #ccc'}}>Brown</td>
                                    <td style={{border: '1px dashed #ccc'}}>Software Engineer</td>
                                    <td style={{border: '1px dashed #ccc'}}>Software Engineer</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>5</td>
                                    <td style={{border: '1px dashed #ccc'}}>Jessica</td>
                                    <td style={{border: '1px dashed #ccc'}}>Williams</td>
                                    <td style={{border: '1px dashed #ccc'}}>Product Manager</td>
                                    <td style={{border: '1px dashed #ccc'}}>Product Lead</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            The <strong>`REPLACE()`</strong> function replaces "Manager" with "Lead" in the <strong>`position`</strong> column.
                        </p>
                    </div>
                </div>


                <div className='mt-5 mb-5'>
                    <h5 className='mt-5 mb-3'>FAQs</h5>
                    <ul style={{ listStyle: 'disc' }} className='noDecor'>
                        <li><Link to="/mysql/string-functions/can-replace-be-used-with-numeric-data">Can REPLACE be used with numeric data?</Link></li>
                        <li><Link to="/mysql/string-functions/how-can-i-replace-substrings-in-multiple-columns-at-once-in-a-single-query">How can I replace substrings in multiple columns at once in a single query?</Link></li>
                        <li><Link to="/mysql/string-functions/are-there-any-performance-benchmarks-for-the-replace-function-in-mysql">Are there any performance benchmarks for the REPLACE Function in MySQL?</Link></li>
                        <li><Link to="/mysql/string-functions/what-should-i-do-if-i-encounter-an-error-while-using-replace">What should I do if I encounter an error while using REPLACE?</Link></li>
                    </ul>
                </div>



            </section>
        </>
    )
}